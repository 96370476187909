import isEmpty from 'lodash.isempty';

import type { AddressesTypes } from '@/features/profile/';

export const sortAddresses = (
  addresses?: AddressesTypes.ListAddresses | null
): AddressesTypes.ListAddresses | null => {
  if (!addresses) {
    return null;
  }

  const { addresses: addressList, shipping } = addresses;

  if (!shipping || isEmpty(addressList?.[shipping])) {
    return addresses;
  }

  const newList: AddressesTypes.ListAddresses = {
    addresses: {
      [shipping]: addressList[shipping],
    },
    shipping,
  };

  return Object.keys(addressList).reduce((item, addressId) => {
    if (addressId !== shipping) {
      item.addresses[addressId] = addressList[addressId];
    }
    return item;
  }, newList);
};
