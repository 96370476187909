import { isAfter, isEqual, isValid, parse } from 'date-fns';

import { DateFormats } from '@/config/language';

import { parseDate } from '../utils';

import { FilterErrors } from './errors';

import type { TFilters } from '../filters';

export const validateDate = (date?: string) => {
  const parsedDate = parseDate(date);
  const currentDate = new Date();

  if (!!parsedDate && isAfter(parsedDate, currentDate)) {
    return FilterErrors.DATE_AFTER_TODAY;
  }

  return !date || isValid(parsedDate) || FilterErrors.DATE_VALID;
};

export const validateDateRange = (_: string | undefined, values: TFilters) => {
  const fromValue = values.dateFrom ?? '';
  const toValue = values.dateTo ?? '';

  if (fromValue === '' || toValue === '') {
    return true;
  }

  const dateFrom = parse(fromValue, DateFormats.DAY_MONTH_YEAR, new Date());
  const dateTo = parse(toValue, DateFormats.DAY_MONTH_YEAR, new Date());

  const isValid = isEqual(dateFrom, dateTo) || isAfter(dateTo, dateFrom);
  return isValid || FilterErrors.DATE_RANGE;
};
