import { useQueryClient } from '@tanstack/react-query';

import { hasConsentedToTerms } from '../utils';

import { profileQueryKeys } from './profile-query-keys';

import type { ProfileTypes } from '../types/profile';

export const useHasUserConsentedTerms = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<ProfileTypes.TUser>(
    profileQueryKeys.profile
  );

  return hasConsentedToTerms(user);
};
