import styled, { css, keyframes } from 'styled-components';

import { BreakpointRems, TOP_BAR_HEIGHT } from '@/config/breakpoints';

const openBackgroundAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
`;

const closeBackgroundAnimation = keyframes`
  from {
   opacity: 0.3;
  }
  to {
    opacity: 0;
  }
`;

const Container = styled.div`
  background: var(--colors-surfaceLow);
  display: flex;
  flex-direction: column;
`;

const TopNavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  overflow-x: hidden;
  padding: var(--space-l);

  @media (min-width: ${BreakpointRems.md}) {
    padding: var(--space-2xl) 4rem;
  }
`;

const SideNavContainer = styled.div`
  display: none;
  height: calc(100dvh - ${TOP_BAR_HEIGHT});
  position: sticky;
  top: ${TOP_BAR_HEIGHT};

  @media (min-width: ${BreakpointRems.sm}) {
    display: block;
  }
`;

const DimOverlay = styled.div<{
  $isDim: boolean;
  $isOpen: boolean;
  height?: number;
}>`
  animation: ${({ $isOpen }) =>
      $isOpen ? openBackgroundAnimation : closeBackgroundAnimation}
    0.3s ease-in-out;
  animation-fill-mode: forwards;
  background: ${(props) => (props.$isDim ? 'rgb(0, 0, 0)' : '')};
  display: ${(props) => (props.$isDim ? 'block' : 'none')};
  height: calc(100dvh - ${TOP_BAR_HEIGHT});
  left: 0;
  opacity: ${(props) => (props.$isDim ? 0.5 : 0)};
  position: fixed;
  top: ${TOP_BAR_HEIGHT};
  touch-action: none;
  transition: all 0.25s ease-in;
  width: 100vw;
`;

const MobileNavContainer = styled.div<{
  $isMobile: boolean;
  $navOpen: boolean;
  height?: number;
}>`
  display: none;
  height: calc(100dvh - ${TOP_BAR_HEIGHT});
  left: ${(props) => (props.$navOpen ? 0 : '-200vw')};
  position: fixed;
  transition: all 0.5s ease-out;
  width: 100vw;
  visibility: hidden;
  z-index: 999999;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: flex;
    `}
  ${({ $navOpen }) =>
    $navOpen &&
    css`
      visibility: visible;
    `}
`;

export const Styled = {
  Container,
  Content,
  DimOverlay,
  MobileNavContainer,
  SideNavContainer,
  TopNavWrapper,
  Wrapper,
};
