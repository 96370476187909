import { styled } from 'styled-components';

const SkipButton = styled.a`
  background: var(--colors-brand1Lowest);
  border-radius: var(--radii-2xl);
  color: var(--colors-onBrand1Low);
  display: flex;
  line-height: var(--lineHeights-normal);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  left: 50%;
  padding: var(--space-xs) var(--space-s);
  position: absolute;
  text-decoration: none;
  top: var(--space-m);
  transform: translateY(-1000%);

  &:focus {
    outline: 2px solid var(--colors-focusLight);
    transform: translateY(0) translateX(-50%);
  }
`;

export const Styled = { SkipButton };
