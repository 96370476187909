export const toNationalPhoneNumber = (phoneNumber: string): string => {
  const internatioalCode = '+61';
  const nationalCode = '0';
  const formattedPhoneNumber = phoneNumber.startsWith(internatioalCode)
    ? phoneNumber.replace(internatioalCode, nationalCode)
    : phoneNumber;

  return formattedPhoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
};

export const cleanMobileNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace(/^04/, '+614')
    .replaceAll(/[^\d+]/g, '')
    .trim();
};
