import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodQueryKeys,
  paymentMethodTransactionKeys,
} from './query-keys';

import type { PaymentMethodTypes, TBaasAddPaymentMethodErrors } from '../types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

type TUpdateCard3dsResponse = {
  route: '3ds' | '3ds2';
  three_d_secure: {
    id: string;
  };
  type: number;
};

export function useUpdateCard3ds(
  cardId: string,
  options: UseMutationOptions<
    TUpdateCard3dsResponse,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    PaymentMethodTypes.TUpdateCard3dsRequest,
    unknown
  >
) {
  const auth = useAuth();

  const UpdateCard3dsRequest = async (
    payload: PaymentMethodTypes.TUpdateCard3dsRequest
  ) => {
    return await request<TUpdateCard3dsResponse>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: payload,
        method: RequestMethod.PUT,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_UPDATE_3DS,
        transaction: paymentMethodTransactionKeys.bankCardUpdate3DS,
      }
    );
  };

  return useMutation({
    mutationFn: UpdateCard3dsRequest,
    mutationKey: paymentMethodQueryKeys.bankCardUpdate,
    ...options,
  });
}
