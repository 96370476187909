import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

interface IMobileInitiateChangeRequest {
  mobile_number: string;
}

export function useMobileInitiateChange(
  options?: UseMutationOptions<
    unknown,
    TBaasError,
    IMobileInitiateChangeRequest,
    unknown
  >
) {
  const auth = useAuth();

  const mobileInitiateChangRequest = async (
    payload: IMobileInitiateChangeRequest
  ) =>
    await request(
      endpoints.profile.PROFILE_MOBILE_INITIATE_CHANGE,
      auth,
      {
        data: payload,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: ProfileEndpointKeys.MOBILE_INITIATE_CHANGE,
        transaction: profileTransactionKeys.mobileInitiateChange,
      }
    );

  return useMutation<
    unknown,
    TBaasError,
    IMobileInitiateChangeRequest,
    unknown
  >({
    mutationFn: mobileInitiateChangRequest,
    mutationKey: profileQueryKeys.mobileInitiateChange,
    ...options,
  });
}
