import { ProfileAvatar } from '../avatar/avatar';

import { Styled } from './styles';

import type { CSSObject } from 'styled-components';

export type ProfileDetailsSizes = 'lg' | 'md' | 'sm';

export interface IProfileDetailsProps {
  active?: boolean;
  avatarAlt?: string;
  avatarLeft?: boolean;
  avatarStyle?: CSSObject;
  details?: string;
  detailsColor?: string;
  headerColor?: string;
  isAvatarOnly?: boolean;
  name?: string;
  onClick?: () => void;
  size?: ProfileDetailsSizes;
  src?: string;
}

export const ProfileDetails = ({
  active,
  avatarAlt,
  avatarLeft,
  avatarStyle,
  details,
  detailsColor,
  headerColor,
  isAvatarOnly = false,
  name,
  size,
  src,
}: IProfileDetailsProps) => {
  // if there is a name, use it as the heading text, otherwise use the details text
  const headingText = name ?? details;
  // if there is no name, there is no details text as details is displayed in heading. Otherwise use the details text.
  const detailsText = !!name ? details : undefined;

  return (
    <Styled.ProfileDetailsWrapper $avatarLeft={avatarLeft}>
      {!isAvatarOnly && (
        <Styled.DetailsTextWrapper $avatarLeft={avatarLeft}>
          {!!headingText && size === 'lg' ? (
            <Styled.DetailsTextWrapperHeading $color={headerColor}>
              {headingText}
            </Styled.DetailsTextWrapperHeading>
          ) : (
            <Styled.DetailsTextWrapperText $color={headerColor}>
              {headingText}
            </Styled.DetailsTextWrapperText>
          )}
          {!!detailsText && (
            <Styled.DetailsTextWrapperText $color={detailsColor}>
              {detailsText}
            </Styled.DetailsTextWrapperText>
          )}
        </Styled.DetailsTextWrapper>
      )}
      <ProfileAvatar
        active={active}
        alt={avatarAlt}
        avatarCSS={avatarStyle}
        name={name}
        size={size}
        src={src}
      />
    </Styled.ProfileDetailsWrapper>
  );
};
