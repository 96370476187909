export function isValidLuhnValue(identifier: string) {
  let sum = 0;
  let alt = false;
  let index = identifier.length - 1;
  let num;

  while (index >= 0) {
    num = Number.parseInt(identifier.charAt(index), 10);

    if (alt) {
      num *= 2;

      if (num > 9) {
        num = (num % 10) + 1;
      }
    }

    alt = !alt;
    sum += num;
    index--;
  }

  return sum % 10 === 0;
}
