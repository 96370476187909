const CalendarIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.75257 2.96387C7.05148 2.96387 7.2938 3.20619 7.2938 3.5051V4.81956H12.7062V3.5051C12.7062 3.20619 12.9485 2.96387 13.2474 2.96387C13.5463 2.96387 13.7887 3.20619 13.7887 3.5051V4.81956H15.567C16.0797 4.81956 16.5713 5.02321 16.9338 5.38572C17.2963 5.74822 17.5 6.23988 17.5 6.75255V15.1031C17.5 15.6157 17.2963 16.1074 16.9338 16.4699C16.5713 16.8324 16.0797 17.036 15.567 17.036H4.43299C3.92033 17.036 3.42867 16.8324 3.06616 16.4699C2.70365 16.1074 2.5 15.6157 2.5 15.1031V6.75255C2.5 6.23988 2.70365 5.74822 3.06616 5.38572C3.42867 5.02321 3.92033 4.81956 4.43299 4.81956H6.21133V3.5051C6.21133 3.20619 6.45365 2.96387 6.75257 2.96387ZM16.4175 7.60304V6.75255C16.4175 6.52697 16.3279 6.31064 16.1684 6.15114C16.0089 5.99164 15.7926 5.90203 15.567 5.90203H4.43299C4.20742 5.90203 3.99109 5.99164 3.83158 6.15114C3.67208 6.31064 3.58247 6.52697 3.58247 6.75255V7.60304H16.4175ZM3.58247 8.68552H16.4175V15.1031C16.4175 15.3286 16.3279 15.545 16.1684 15.7045C16.0089 15.864 15.7926 15.9536 15.567 15.9536H4.43299C4.20742 15.9536 3.99109 15.864 3.83158 15.7045C3.67208 15.545 3.58247 15.3286 3.58247 15.1031V8.68552Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
);

export { CalendarIcon };
