import { CustomerService } from '@/components';

export const cardManagementContent = {
  SECURITY_ASSURANCE: (
    <>Learn more about secure encryption and real-time fraud monitoring</>
  ),
  WHY_NOT_DELETE_MESSAGE: () => (
    <>
      It looks like this card has not been properly added to your account. If
      you wish to delete this card, <CustomerService />.
    </>
  ),
  WHY_NOT_DELETE_TITLE: <>Why can’t I delete this card?</>,
};
