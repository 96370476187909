import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const PanelWrapper = styled.div`
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  flex: 1;
  padding: var(--space-xl) var(--space-l);
  width: 100%;
`;

const PanelTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

const PanelTitle = styled.h2`
  color: var(--onSurfaceA);
  font-size: 22px;
  font-weight: var(--fontWeights-bold);
  line-height: 1.4;
  margin: 0 0 var(--space-l);
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 24px;
  align-items: center;

  @media (min-width: ${BreakpointRems.sm}) {
    font-size: var(--fontSizes-16);
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Styled = {
  ButtonWrapper,
  PanelTitle,
  PanelTitleWrapper,
  PanelWrapper,
  SpinnerWrapper,
};
