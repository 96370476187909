import {
  MAX_CHARS_ADDRESS_NICKNAME,
  MAX_CHARS_CARD_NICKNAME,
  MIN_CHARS_DEFAULT,
} from '@/features/profile';

export enum AddressValidationErrors {
  ADDRESS_MAX_MIN_LINE_1 = 'Address line 1 must be between 5 and 60 characters',
  ADDRESS_NAME_MAX_CHAR = 'must not exceed 60 characters',
  NICKNAME_LENGTH = `Address nickname must be between ${MIN_CHARS_DEFAULT} and ${MAX_CHARS_ADDRESS_NICKNAME} characters`,
  POSTCODE_MIN = 'Postcode must be at least 4 characters',
  POSTCODE_VALID = 'Postcode must be a valid Australian postcode',
  SUBURB_MAX_MIN = 'City/Suburb must be between 3 and 60 characters',
  SUBURB_VALID = 'City/Suburb should not start with a number',
}

export enum CardValidationErrors {
  ALIAS_LENGTH = `Nickname must be between ${MIN_CHARS_DEFAULT} and ${MAX_CHARS_CARD_NICKNAME} characters`,
  CVV_INVALID = 'Invalid card security code',
  EXPIRY_INVALID = 'Invalid card expiry date',
  NUMBER_INVALID = 'Our system doesn’t accept this type of card. Try a different card.',
  NUMBER_GENERIC = `Enter a valid card number`,
}

export enum GenericValidationErrors {
  EMPTY = `cannot be empty`,
  NUMBER = `must be a numeric value`,
  REQUIRED = `is a required field`,
  TEXT = `must be a text value`,
  NAME = 'It must not contain numbers or special characters.',
}

export enum OtherValidationErrors {
  EMAIL_INVALID = `That doesn't look like an email`,
}
