import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--colors-errorLowest);
  border: 1px solid var(--colors-onErrorLow);
  border-radius: var(--radii-md);
  display: flex;
  margin-bottom: var(--space-l);
  padding: var(--space-m) var(--space-m) var(--space-m) var(--space-xs);
`;

const IconColumn = styled.div`
  flex-basis: 1.5rem;
`;

const TextColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-xs);
`;

const Text = styled.div`
  font-size: var(--fontSizes-14);
`;

const Title = styled.h2`
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-bold);
  margin: 0;
`;

export const Styled = {
  Container,
  IconColumn,
  Text,
  TextColumn,
  Title,
};
