import type { AxiosError } from 'axios';

export enum BaasErrors {
  // The request lacks valid authentication credentials for the requested resource.
  FLYBUYS_SERVICE_USER_ALREADY_LINKED_ERROR = '409001064',
  FORBIDDEN = '403002001', // The flybuys account is linked to a different wallet and needs to be unlinked there.
  INITIATE_EMAIL_CHANGE_BAD_REQUEST_ERROR = '400002098', // The user either does not exist in Ping or the email is already active.
  INITIATE_EMAIL_CHANGE_CONFLICT_ERROR = '409002080', // The email the user wants to update the email to is already in use by another user.
  LOYALTY_ACCESS_TOKEN_MISMATCH = '400001122', // The user is attempting to refresh their token but has logged into a different flybuys account.
  MAX_CARDS_LIMIT = '400001108', // The user has reached the maximum number of bank cards allowed.
  PING_ACTIVATE_MFA_DEVICE_WRONG_OTP = '400002106', // The otp provided to Ping is not correct.
  PING_PATCH_USER_ERROR = '400002070', // An error was returned by Ping while attempting to update a user. "Ensure that the given user exists in Ping."
  PING_SEND_OTP_EMAIL_ERROR = '500002026',
  PING_VERIFY_EMAIL_BAD_REQUEST_ERROR = '400002025', // An error was returned by Ping while attempting to verify the email using the OTP provided.
  PING_VERIFY_EMAIL_ERROR = '500002024',
  TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'terms_and_conditions_not_accepted', // The user has not accepted the terms and conditions.
  UPDATE_EMAIL_FINALIZE_VALIDATION_ERROR = '444002076', // Request failed validation.
  UPDATE_EMAIL_INITIATE_VALIDATION_ERROR = '444002071', // Request failed validation.
  UPDATE_EMAIL_MISSING_EMAIL_RESEND_ERROR = '400002078', // No record containing the given email was found in Ping while attempting to resend the verification email to the user.
  UPDATE_EMAIL_VERIFY_INVALID_CODE_ERROR = '400002125', // No record containing the given email was found in Ping while attempting to resend the verification email to the user.
}

type BaasErrorResponse = {
  debug?: {
    errorCode: BaasErrors;
    recovery: string;
  };
  details: {
    correlation_id: string;
    error_number: number;
    timestamp: number;
  };
  dev?: {
    env: string;
    path: string;
    stack: string;
  };
  message: string;
};

export type TBaasError = AxiosError<BaasErrorResponse>;
