import creditCardType, { types as CardType } from 'credit-card-type';

import { isModirumPan } from './is-modirum-pan';
import { isValidLuhnValue } from './is-valid-luhn-value';

const allowModirumPans =
  import.meta.env.VITE_REACT_APP_ALLOW_MODIRUM_PANS === 'true';

export function validateCardNumber(value: string | null) {
  if (!value) {
    return false;
  }

  const cardValue = removeCardNumberArtifacts(value);

  if (allowModirumPans && isModirumPan(cardValue)) {
    return true;
  }

  const cardType = getSupportedCardType(cardValue);

  if (!cardType) {
    return false;
  }

  if (!isValidLuhnValue(cardValue)) {
    return false;
  }

  const cardMaxLength = Math.max.apply(null, cardType.lengths);

  return cardType.lengths?.some(
    (length) => length === cardValue.length && length <= cardMaxLength
  );
}

function removeCardNumberArtifacts(cardNumber: string) {
  const SPACE_OR_DASH_REGEX = /-|\s/g;
  return String(cardNumber).replaceAll(SPACE_OR_DASH_REGEX, '');
}

function getSupportedCardType(cardNumber: string) {
  return creditCardType(cardNumber).find(
    (card) =>
      card.type === CardType.VISA ||
      card.type === CardType.MASTERCARD ||
      card.type === CardType.AMERICAN_EXPRESS
  );
}
