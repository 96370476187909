import styled from 'styled-components';

import { Button } from '@/components';

const CardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  [aria-haspopup='dialog']:empty {
    display: none;
  }
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);
  flex-grow: 1;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  padding-bottom: var(--space-2xl);
`;

const CardDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 125%;

  .label {
    font-size: var(--fontSizes-14);
    color: var(--colors-onSurfaceC);
  }

  p {
    color: var(--colors-onSurfaceB);
    font-size: var(--fontSizes-16);
    margin: 0;
  }
`;

const ActionButton = styled(Button)`
  display: flex;
  gap: var(--space-xs);
`;

const Actions = styled.div`
  border-top: 1px solid #807f80;
  padding-top: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const PaymentCardWrapper = styled.div`
  > div {
    aspect-ratio: 7/4;
    display: flex;
    height: unset;
  }
`;

const DialogBodyCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const DialogBodyCardListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DialogBodyCardListItemWrapper = styled.li`
  display: flex;
  width: 100%;
`;

const DialogBodyCardListItem = styled.button<{ $selected: boolean }>`
  align-items: center;
  background: transparent;
  border: 1px solid
    ${(props) => (props.$selected ? 'var(--colors-onSurfaceB)' : 'transparent')};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: var(--space-m);
  padding: var(--space-xs) var(--space-s);
  text-align: left;
  width: 100%;

  &:hover {
    border-color: var(--colors-onSurfaceB);
  }
`;

const DialogBodyCardListItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: 120%;
  color: var(--colors-onSurfaceA);
  justify-content: center;

  p {
    font-size: var(--fontSizes-14);
    font-weight: var(--fontWeights-normal);
    color: var(--colors-onSurfaceB);
  }
`;

const DialogBodyCardListPaymentCardWrapper = styled.div`
  > div {
    width: 6rem;
    height: 4rem;

    svg {
      width: 3rem;
      height: 2rem;
    }
  }
`;

const NicknameColumns = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const Styled = {
  ActionButton,
  Actions,
  CardDetails,
  CardDetailsColumn,
  CardDetailsContainer,
  CardDetailsRow,
  CardDetailsWrapper,
  DialogBodyCardListContainer,
  DialogBodyCardListItem,
  DialogBodyCardListItemTextWrapper,
  DialogBodyCardListItemWrapper,
  DialogBodyCardListPaymentCardWrapper,
  DialogBodyCardListWrapper,
  NicknameColumns,
  PaymentCardWrapper,
};
