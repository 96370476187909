import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { request } from '@/services';

import { endpoints } from './endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodQueryKeys,
  paymentMethodTransactionKeys,
} from './query-keys';

import type { PaymentMethodTypes } from '../types';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetPublicKey(
  options?: Partial<UseQueryOptions<PaymentMethodTypes.TPublicKey>>
) {
  const auth = useAuth();

  const getPublicKey = async () =>
    await request<PaymentMethodTypes.TPublicKey>(
      endpoints.PUBLIC_KEY,
      auth,
      {},
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.PUBLIC_KEY,
        transaction: paymentMethodTransactionKeys.publicKey,
      }
    );

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: getPublicKey,
    queryKey: paymentMethodQueryKeys.publicKey,
    ...options,
  });
}
