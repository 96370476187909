import { ExclamationMarkIcon } from '@/components/icons';

import { Styled } from './styles';
export const OrdersEmptyPage = () => {
  return (
    <Styled.EmptyOrdersList role="alert">
      <ExclamationMarkIcon height={62} width={62} />
      <Styled.EmptyOrdersListDetails>
        <h1> Nothing to see here</h1>
        <p>We found no orders made with your flypay account</p>
      </Styled.EmptyOrdersListDetails>
    </Styled.EmptyOrdersList>
  );
};
