import { forwardRef } from 'react';

import { Styled } from './styles';

import type { SliderProps } from '@radix-ui/react-slider';

const Slider = forwardRef<HTMLSpanElement, SliderProps>(
  (
    {
      'aria-label': ariaLabel,
      asChild,
      defaultValue,
      dir,
      disabled,
      inverted,
      max,
      min,
      minStepsBetweenThumbs,
      name,
      onValueChange,
      onValueCommit,
      orientation,
      step,
      value,
    },
    forwardedRef
  ) => {
    const currentValue = value || defaultValue;
    return (
      <Styled.SliderRoot
        asChild={asChild}
        defaultValue={defaultValue}
        dir={dir}
        disabled={disabled}
        inverted={inverted}
        max={max}
        min={min}
        minStepsBetweenThumbs={minStepsBetweenThumbs}
        name={name}
        onValueChange={onValueChange}
        onValueCommit={onValueCommit}
        orientation={orientation}
        ref={forwardedRef}
        step={step}
        value={value}
      >
        <Styled.SliderTrack>
          <Styled.SliderRange />
        </Styled.SliderTrack>
        {!!currentValue &&
          currentValue.map((_, i) => (
            <Styled.SliderThumb
              aria-label={!!name ? `${name}_${i}` : `${ariaLabel}_${i}`}
              key={i}
            />
          ))}
      </Styled.SliderRoot>
    );
  }
);

Slider.displayName = 'Slider';
export { Slider };
