import { ProfileEnums } from '../enums';

import type { ProfileTypes } from '../types/profile';

export function findConsent(
  user: ProfileTypes.TUser | null = null,
  consentType: ProfileEnums.ProfileAgreementCategory
) {
  return user?.consents.find(
    (consent) => consent.active_agreement.category === consentType
  );
}

export function getConsent(
  user: ProfileTypes.TUser | null,
  isAccepting: boolean,
  consentType: ProfileEnums.ProfileAgreementCategory
): ProfileTypes.ProfileProperties.TConsentUpdate | undefined {
  const consent = findConsent(user, consentType);

  return consent
    ? {
        agreement_id: consent.active_agreement.id,
        agreement_version: consent.active_agreement.version,
        granted: isAccepting,
        participant_id: consent.participant_id,
      }
    : undefined;
}

export function getMarketingConsent(
  user: ProfileTypes.TUser | null = null,
  isAccepting: boolean = false
) {
  return getConsent(
    user,
    isAccepting,
    ProfileEnums.ProfileAgreementCategory.MARKETING_COMMUNICATIONS
  );
}

export function getTermsConsent(
  user: ProfileTypes.TUser | null = null,
  isAccepting: boolean = false
) {
  return getConsent(
    user,
    isAccepting,
    ProfileEnums.ProfileAgreementCategory.TERMS_AND_CONDITIONS
  );
}
