import { Feature } from '@/config';
import { useTheme } from '@/contexts/theme';

import { Flybuys } from './flybuys';
import { Mycoles } from './mycoles';
import { Styled } from './styles';

export const LoyaltyCardPanel = () => {
  const { theme } = useTheme();
  const showFlybuys = theme.features.includes(Feature.FLYBUYS);
  const showMyColes = theme.features.includes(Feature.MYCOLES);

  return (
    <Styled.LoyaltyCardPanel>
      <li>{showFlybuys && <Flybuys />}</li>
      <li>{showMyColes && <Mycoles />}</li>
    </Styled.LoyaltyCardPanel>
  );
};
