import { LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS } from '../constants';

import type { PaymentMethodTypes } from '@/features/card-management';

export const getMycolesCard = (
  loyaltyCards?: PaymentMethodTypes.TLoyaltyCard[]
): PaymentMethodTypes.TLoyaltyCard | null => {
  return (
    loyaltyCards?.filter(
      (card) =>
        card.program &&
        'id' in card.program &&
        card.program.id === LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS
    )?.[0] || null
  );
};

export const formatBarCode = (barcode: string | undefined) => {
  if (!barcode) {
    return '';
  }
  return `* ****** ** ${barcode.slice(-4)}`;
};
