import { splitNumberWithCommas } from '@/utils';

export function getLoyaltyBalance(
  points: number | undefined,
  exchangeRate?: number | undefined
): string {
  if (!points || !exchangeRate) {
    return '0';
  }
  const initialValue = points * exchangeRate;
  const roundedValue = Math.floor(initialValue * 100) || 0;
  const [dollars, cents] = (roundedValue / 100)
    .toFixed(2)
    .toString()
    .split('.');
  return `${splitNumberWithCommas(dollars)}.${cents}`;
}
