import * as Label from '@radix-ui/react-label';
import styled, { css } from 'styled-components';

import { visuallyHidden } from '@/styles/mixins';

import type { TComboBoxSize } from './typings';

const AutoComplete = styled.div`
  position: relative;
`;

const AutoCompleteOptionsList = styled.ul<{ $isOpen: boolean }>`
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-md);
  border: 1px solid var(--colors-onSurfaceD);
  list-style: none;
  max-height: 200;
  margin: 0;
  overflow-y: auto;
  padding: var(--space-m) 0;
  position: absolute;
  top: 54;
  width: 100%;
  z-index: 1;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  box-shadow: 0 0 12px rgb(0 0 0 / 4%);
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const AutoCompleteOptionsListItem = styled.li<{ $isFocused: boolean }>`
  cursor: pointer;
  padding: var(--space-s) var(--space-m);
  transition: background 300ms ease-out;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-onSurfaceA);

  &:hover {
    background: var(--colors-surfaceLow);
  }
  ${({ $isFocused }) =>
    $isFocused &&
    css`
      background: var(--colors-surfaceLow);
    `}
`;

const ComboBoxInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeights-normal);
`;

const ComboBoxTextInput = styled.input<{
  $hasError: boolean;
  $size?: TComboBoxSize;
}>`
  box-sizing: border-box;
  padding: var(--space-s) var(--space-xl) var(--space-s) var(--space-m);
  background-color: var(--colors-surfaceLowest);
  border: 1px solid var(--colors-onSurfaceC);
  border-radius: var(--radii-md);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-onSurfaceA);
  line-height: 0;
  text-overflow: ellipsis;
  transition: border 300ms ease-in-out;
  margin-bottom: var(--space-xs);
  height: 2.5rem;
  width: 100%;

  &::placeholder {
    color: var(--colors-onSurfaceD);
  }

  &:focus {
    outline: var(--colors-onBrand1Low);
    border-color: var(--colors-onSurfaceC);
  }

  &:hover {
    border-color: var(--colors-onSurfaceB);
  }

  &:disabled,
  &:hover:disabled {
    background-color: var(--colors-surfaceLow);
    border-color: var(--colors-onSurfaceD);
    color: var(--colors-onSurfaceD);
    cursor: not-allowed;
  }

  ${({ $size }) =>
    $size === 'small' &&
    css`
      padding: var(--space-s);
      font-size: var(--fontSizes-14);
    `}

  ${({ $size }) =>
    $size === 'regular' &&
    css`
      padding: var(--space-m);
      font-size: var(--fontSizes-16);
    `}
  
  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--colors-onErrorLow);
    `}
`;

const ComboBoxToggle = styled.div<{
  $isOpen: boolean;
  disabled?: boolean;
  size?: TComboBoxSize;
}>`
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  position: absolute;
  top: var(--space-xs);
  right: var(--space-xs);

  svg {
    transition: transform 250ms cubic-bezier(0.87, 0, 0.13, 1);
    transform: ${({ $isOpen }) => ($isOpen ? 'none' : 'rotate(180deg)')};
  }
  ${({ disabled }) => `
    ${disabled && `cursor: not-allowed;`}
  `}
  ${({ size }) =>
    size === 'small' &&
    css`
      top: var(--space-xs);
    `}
  
  ${({ size }) =>
    size === 'regular' &&
    css`
      top: var(--space-s);
    `}
`;

const ComboBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeights-normal);
  gap: var(--space-xs);
  width: 100%;
`;

const LabelRoot = styled(Label.Root)`
  color: var(--colors-onSurfaceA);
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-16);
  text-align: left;
`;

const VisuallyHiddenComboBoxResultsStatus = styled.div`
  ${visuallyHidden}
`;

export const Styled = {
  AutoComplete,
  AutoCompleteOptionsList,
  AutoCompleteOptionsListItem,
  ComboBoxInputWrapper,
  ComboBoxTextInput,
  ComboBoxToggle,
  ComboBoxWrapper,
  LabelRoot,
  VisuallyHiddenComboBoxResultsStatus,
};
