export namespace ProfileEnums {
  export enum ProfileAgreementReason {
    AGREEMENT_VERSION_UPDATED = 'agreement_version_updated',
    PARTY_NEVER_CONSENTED_BEFORE = 'party_never_consented_before',
    PARTY_RETURNED_AFTER_DELETION = 'party_returned_after_deletion',
    PARTY_REVOKED_CONSENT = 'party_revoked_consent',
  }
  export enum ProfileAgreementCategory {
    MARKETING_COMMUNICATIONS = 'marketing_communications',
    TERMS_AND_CONDITIONS = 'terms_and_conditions',
  }
}
