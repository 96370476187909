import styled from 'styled-components';

const Fieldset = styled.fieldset`
  border: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin: 0;
  padding: 0;
`;

const GridParentWrapper = styled.div`
  > div {
    place-items: baseline;
  }
`;

export const Styled = {
  Fieldset,
  GridParentWrapper,
};
