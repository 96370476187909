import { forwardRef } from 'react';

import { Styled } from './styles';

import type { ButtonLevel, ButtonVariant, IButtonProps } from '@b707/ponyta';

export const Button = forwardRef<HTMLDivElement, Omit<IButtonProps, 'css'>>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, level = 'primary', variant = 'neutral', ...props }, _ref) => {
    return (
      <Styled.ButtonStyle
        $level={level}
        $variant={variant}
        level={'' as ButtonLevel} // Completely remove any ponyta button styles
        variant={'' as ButtonVariant}
        {...props}
      >
        {children}
      </Styled.ButtonStyle>
    );
  }
);

Button.displayName = 'Button';
