import styled from 'styled-components';

const LayoutWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-surfaceLow);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  padding-top: 25dvh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: var(--fontSizes-24);
  font-weight: var(--fontWeights-bold);
  margin: var(--space-2xl) 0 var(--space-l);
`;

const Description = styled.p`
  font-size: var(--fontSizes-16);
  margin-bottom: var(--space-2xl);
`;

const Link = styled.a`
  background-color: var(--colors-brand1Highest);
  border-radius: var(--radii-md);
  color: var(--colors-onBrand1High);
  display: inline-block;
  padding: var(--space-m);
  text-decoration: none;
`;

export const Styled = {
  ContentWrapper,
  Description,
  LayoutWrapper,
  Link,
  Title,
};
