import isEmpty from 'lodash.isempty';

import { AddressInputFieldNames } from '../../address-input';

import type { GeocodeResult } from 'use-places-autocomplete';

type TAddress = {
  [key in Exclude<
    AddressInputFieldNames,
    | AddressInputFieldNames.ADDRESS_IS_DEFAULT
    | AddressInputFieldNames.ADDRESS_NAME
    | AddressInputFieldNames.ADDRESS_NICKNAME
  >]: string;
};

type TNiceAddress = TAddress & {
  addressStateShort: string;
};

export const getRelevantAddressData = (
  results: GeocodeResult[] | null
): Partial<TNiceAddress> => {
  const geocodeResultAddressComponent = getGeocodeAddressComponents(results);
  const address = formatGeocodeResult(geocodeResultAddressComponent);
  const streetNumber = address.street_number?.long_name || '';
  const addressLine1 = !!streetNumber
    ? `${streetNumber} ${address.route?.long_name}`
    : `${address?.route?.long_name || ''}`;

  return !isEmpty(address)
    ? {
        [AddressInputFieldNames.ADDRESS_LINE_1]: addressLine1,
        [AddressInputFieldNames.ADDRESS_LINE_2]: address?.subpremise
          ? address?.subpremise?.long_name
          : '',
        [AddressInputFieldNames.ADDRESS_STATE]:
          address?.administrative_area_level_1?.short_name,
        [AddressInputFieldNames.ADDRESS_POSTCODE]: address?.postal_code
          ? address?.postal_code?.long_name
          : undefined,
        [AddressInputFieldNames.ADDRESS_CITY]: address?.locality?.long_name,
      }
    : ({} as TNiceAddress);
};

export const getGeocodeAddressComponents = (
  geocodeResults: GeocodeResult[] | null
) => geocodeResults?.[0]?.address_components;

export enum AddressComponentFields {
  ADMIN_AREA_LEVEL_1 = 'administrative_area_level_1',
  COUNTRY = 'country',
  LOCALITY = 'locality',
  POSTAL_CODE = 'postal_code',
  ROUTE = 'route',
  STREET_ADDRESS = 'street_address',
  STREET_NUMBER = 'street_number',
  SUBPREMISE = 'subpremise',
}

type TAddressComponent = {
  [key in AddressComponentFields]: google.maps.GeocoderAddressComponent;
};

const formatGeocodeResult = (
  geocodeResult?: google.maps.GeocoderAddressComponent[]
): Partial<TAddressComponent> => {
  const addressFields = Object.values(AddressComponentFields);

  return !!geocodeResult?.length
    ? geocodeResult.reduce((a, c) => {
        const key = addressFields.find((m) => c.types.includes(m));

        return {
          ...a,
          ...(!!key && { [key]: c }),
        };
      }, {})
    : {};
};
