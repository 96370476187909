import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  MerchantEndpointKeys,
  merchantQueryKeys,
  merchantTransactionKeys,
} from './query-keys';

import type { MerchantTypes } from '../types';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetMerchantLogo(
  config: MerchantTypes.TMerchantLogoConfig,
  options?: UseQueryOptions<MerchantTypes.TMerchantLogo, Error>
) {
  const auth = useAuth();

  async function getMerchantLogo(): Promise<MerchantTypes.TMerchantLogo> {
    return await request<MerchantTypes.TMerchantLogoResponse>(
      endpoints.merchant.MERCHANT_LOGO(config.merchantId),
      auth,
      {
        method: RequestMethod.GET,
        responseType: 'arraybuffer',
        withResponseHeaders: true,
      },
      {
        priority: EventPriority.P4,
        requestKey: MerchantEndpointKeys.MERCHANT_LOGO,
        transaction: merchantTransactionKeys.merchantLogo,
      }
    ).then((response) => {
      const dataType = response.headers['content-type'];
      const blob = Buffer.from(response.data).toString('base64');
      return `data:${dataType};base64,${blob}`;
    });
  }

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: getMerchantLogo,
    queryKey: merchantQueryKeys.merchantLogo(config.merchantId),
    ...options,
  });
}
