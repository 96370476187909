import { getPillText } from './get-pill-text';

import type { PaymentMethodTypes } from '../types';

export const getBankCardLabel = (
  card: PaymentMethodTypes.TBankCard,
  isDefault = false
) => {
  const cardName = card.alias || card.data.issuer_name || '';
  const splitCardNumber = !!card.data?.visualization?.last_four_digits
    ? [...card.data.visualization.last_four_digits].join(' ')
    : '';

  const cardType = `${card.data.schemes.join(' ')} ${card.data.type}`;
  const cardStatusText = getPillText(card.status, isDefault) ?? '';
  return `${cardType}, ${!!cardStatusText ? cardStatusText + ' ' : ''}${!!cardName ? cardName + ' ' : ''}Card${!!splitCardNumber ? ` ending with ${splitCardNumber}` : ''}`;
};
