import { V2QrEnums } from '@/services';
import { capitalizeFirstChar, toTitleCase } from '@/utils';
import { formatFlybuysNumber } from '@/utils/format';

import type { TTransactionCardInfo } from '../../types';
import type { V2QrTypes } from '@/services';

const getCardScheme = (
  scheme: `${V2QrEnums.CardScheme}`
): string | undefined => {
  if (scheme === V2QrEnums.CardScheme.EFTPOS) {
    return 'eftpos Card';
  }

  if (scheme === V2QrEnums.CardScheme.MASTERCARD) {
    return capitalizeFirstChar(scheme);
  }

  return `${capitalizeFirstChar(scheme)} Card`;
};

export const getCard = (
  transaction: V2QrTypes.TTransaction
): TTransactionCardInfo => {
  if (transaction.card_type === 'card') {
    return {
      isSchemeCard: true,
      number: `•• ${transaction.card.last_4}`,
      type: getCardScheme(transaction.card.scheme) ?? 'Payment Card',
    };
  }
  const maskedNumber =
    typeof transaction.card?.metadata?.masked_number === 'string'
      ? transaction.card.metadata.masked_number
      : '';
  return {
    isSchemeCard: false,
    number: formatFlybuysNumber(maskedNumber),
    type: toTitleCase(transaction.card.provider),
  };
};
