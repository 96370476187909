import { filterOrders } from '@/features/activity';

import type { OrderServiceTypes } from './typings';
import type { InfiniteData } from '@tanstack/react-query';

export const select = {
  filteredOrders(query: InfiniteData<OrderServiceTypes.TOrdersList>) {
    return {
      ...query,
      pages: query.pages.map((page) => {
        return {
          next_key: page.next_key,
          orders: filterOrders(page.orders),
        };
      }),
    };
  },
};
