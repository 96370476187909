import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import { loyaltyQueryKeys } from './query-keys';

import type { FlybuysTypes } from '../types';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useFlybuysInitiateLink(
  options?: UseMutationOptions<
    FlybuysTypes.TFlybuysInitiateLinkResponse,
    TBaasError,
    void,
    unknown
  >
) {
  const auth = useAuth();

  const flybuysInitiateLink = async () =>
    await request<FlybuysTypes.TFlybuysInitiateLinkResponse>(
      endpoints.FLYBUYS_INITIATE_LINK,
      auth,
      {
        method: RequestMethod.POST,
      }
    );

  return useMutation({
    mutationFn: flybuysInitiateLink,
    mutationKey: loyaltyQueryKeys.flybuysInitiate,
    ...options,
  });
}
