export const LOYALTY_PROGRAM_ID_FLYBUYS =
  '677bad56-e67e-4f57-aa50-438e1ccbcbc6';

export const LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS =
  '5bb22cd0-155d-4092-b688-cf949a90fb1f';

export enum LOYALTY_SCHEME_NAMES {
  FLYBUYS = 'flybuys',
  TEAM_MEMBER_CARD = 'coles-team-members',
}

export enum LOYALTY_ERRORS {
  LOYALTY_CARD_FORBIDDEN_ERROR_CREATE = '400001110',
  LOYALTY_CARD_FORBIDDEN_OVER_LIMIT_CREATE = '400001112',
}

// For reference when needed
// LOYALTY_ACCESS_TOKEN_ERROR = '400001032',
// LOYALTY_ALREADY_ADDED = '409001116',
// LOYALTY_CARD_FORBIDDEN_ERROR_CREATE = '400001117',
// LOYALTY_CARD_FORBIDDEN_NOT_ACTIVE_CREATE = '400001118',
// LOYALTY_CARD_FORBIDDEN_OVER_LIMIT_CREATE = '400001119',
// LOYALTY_CARD_FOR_PROGRAM_NOT_FOUND_UNLINK = '404001042',
// LOYALTY_CARD_INVALID_TOKEN = '400001050',
// LOYALTY_CARD_KNOWN_VENDOR_UPDATE_ERROR = '400001037',
// LOYALTY_CARD_LIVE_DATA_NOT_SUPPORTED_COMPLETE = '400001054',
// LOYALTY_CARD_NOT_FOUND_DELETE = '404001044',
// LOYALTY_CARD_NOT_FOUND_GET = '404001029',
// LOYALTY_CARD_NOT_FOUND_SET_DEFAULT = '404001043',
// LOYALTY_CARD_NOT_FOUND_UPDATE = '404001035',
// LOYALTY_CARD_NOT_FOUND_UPDATE_CREDENTIALS = '404001049',
// LOYALTY_CARD_NOT_LINKED_UNLINK = '400001030',
// LOYALTY_CARD_VALIDATION_ERROR_COMPLETE_LINK = '400001038',
// LOYALTY_CARD_VALIDATION_ERROR_CREATE = '400001026',
// LOYALTY_CARD_VALIDATION_ERROR_UPDATE = '400001033',
// LOYALTY_CARD_VALIDATION_ERROR_UPDATE_CREDENTIALS = '400001047',
// LOYALTY_PROGRAM_ALREADY_LINKED_COMPLETE = '409001039',
// LOYALTY_PROGRAM_ALREADY_LINKED_CREATE = '409001028',
// LOYALTY_PROGRAM_CARD_CONFLICT_COMPLETE_LINK = '409001046',
// LOYALTY_PROGRAM_CONFLICT = '404001104',
// LOYALTY_PROGRAM_GET_SERVICE_ERROR = '400001055',
// LOYALTY_PROGRAM_LIVE_DATA_NOT_SUPPORTED_COMPLETE = '400001040',
// LOYALTY_PROGRAM_NOT_FOUND = '404001100',
// LOYALTY_PROGRAM_NOT_SUPPORTED_CREATE = '400001057',
// LOYALTY_PROGRAM_NOT_SUPPORTED_INITIATE = '400001027',
// LOYALTY_PROGRAM_NOT_SUPPORTED_UNLINK = '400001041',
// LOYALTY_PROGRAM_NOT_SUPPORTED_UPDATE_CREDENTIALS = '400001048',
// LOYALTY_PROGRAM_VALIDATION_BLACKLIST_REQUEST_ERROR = '400001112',
// LOYALTY_PROGRAM_VALIDATION_BLACKLIST_USER_REMOVE_ERROR = '500001113',
// LOYALTY_PROGRAM_VALIDATION_DATA_CONFLICT_ERROR = '409001111',
// LOYALTY_PROGRAM_VALIDATION_PAYLOAD_ERROR = '400001110',
// LOYALTY_PROGRAM_VALID_CHECK_ERROR = '400001045',
// LOYALTY_SERVICE_UNLINK_CARD_NOT_LINKED = '400001052',
// LOYALTY_SERVICE_UNLINK_PROGRAM_NOT_SUPPORTED = '400001051',
// LOYALTY_UNLINK_FLYBUY_SERVICE_ERROR = '400001056',
// LOYALTY_UNLINK_INVALID_REQUEST_BODY = '444001096',
// QR_AUTHORIZE_LOYALTY_NOT_LINKED_ERROR = '400001114',
