export const endpoints = {
  BANK_CARDS: '/v1/payment-methods/bank-cards',
  BANK_CARDS_3DS_AUTHENTICATE: (id: string) =>
    `/v1/payment-methods/bank-cards/three-d-secure/${id}/authenticate`,
  BANK_CARDS_3DS_AUTHENTICATE_INIT: (id: string) =>
    `/v1/payment-methods/bank-cards/three-d-secure/${id}/authenticate/init`,
  BANK_CARDS_AUTHORIZE: '/v1/payment-methods/bank-cards/authorize',
  BANK_CARDS_BY_ID: (id: string) => `/v1/payment-methods/bank-cards/${id}`,
  BANK_CARDS_FINALIZE: '/v1/payment-methods/bank-cards/finalize',
  PAYMENT_METHODS: '/v1/payment-methods',
  PUBLIC_KEY: '/v1/payment-methods/encryption/public-key',
};
