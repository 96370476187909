import { ErrorIcon } from '@/components/icons';

import { Styled } from './styles';

export const OrdersErrorPage = () => {
  return (
    <Styled.EmptyOrdersList role="alert">
      <ErrorIcon height={62} width={62} />
      <Styled.EmptyOrdersListDetails>
        <h1>Error loading transaction data</h1>
        <p>
          We’re sorry, there was an error loading your flypay transactions,
          please refresh the page to try again.
        </p>
      </Styled.EmptyOrdersListDetails>
    </Styled.EmptyOrdersList>
  );
};
