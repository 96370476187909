import { PaymentCard } from '@b707/ponyta';
import { useState } from 'react';

import {
  ButtonSpinner,
  CardStatusPill,
  ConfirmationDialog,
  ErrorDialog,
} from '@/components';
import { EditIcon, TrashIcon } from '@/components/icons';
import { genericContent } from '@/config/language';
import {
  ERROR_DELETE_CARD_GENERIC,
  ERROR_SAVE_CARD_GENERIC,
  ERROR_SET_DEFAULT_CARD_FAILED,
  ERROR_TITLE_DELETE_CARD,
} from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  CardWarning,
  PaymentMethodEnums,
  cardManagementContent,
} from '@/features/card-management';
import {
  useDeleteCard,
  useUpdateDefaultCard,
} from '@/features/card-management/services';
import { useAnalytics } from '@/hooks';
import { SuccessDialogStates } from '@/pages/wallet/enums';
import { validateCardExpiryDate } from '@/utils/validation';

import { CardDetailsEdit } from './card-details-edit';
import { CardListDialog } from './card-list-dialog';
import { NicknameEditButton, NicknameEditor } from './nickname-editor';
import { SetDefaultDialog } from './set-default-dialog';
import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';

interface ICardEditor {
  card: PaymentMethodTypes.TBankCard;
  cards?: PaymentMethodTypes.TBankCard[];
  handleViewCardClose: () => void;
  is3dsIframeOpen: boolean;
  isEditing: boolean;
  isEditingNickname: boolean;
  setIs3dsIframeOpen: (isOpen: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
  setIsEditingNickname: (isEditing: boolean) => void;
  showSuccessDialog: (state: SuccessDialogStates) => void;
}

const defaultErrorMessage = ERROR_SAVE_CARD_GENERIC;

export const CardEditor = ({
  card,
  cards,
  handleViewCardClose,
  is3dsIframeOpen,
  isEditing,
  isEditingNickname,
  setIs3dsIframeOpen,
  setIsEditing,
  setIsEditingNickname,
  showSuccessDialog,
}: ICardEditor) => {
  const { theme } = useTheme();
  const { track } = useAnalytics();

  const [openDefaultDialog, setOpenDefaultDialog] = useState<boolean>(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>();
  const [errorTitle, setErrorTitle] = useState<string | null>();
  const [isOpenCardList, setIsOpenCardList] = useState<boolean>(false);
  const isVerified = card.status === PaymentMethodEnums.BankCardStatus.VERIFIED;
  const isDefault = !!card?.is_default_pay;
  const isValidated =
    card.status === PaymentMethodEnums.BankCardStatus.VALIDATED;
  const isInvalid = card.status === PaymentMethodEnums.BankCardStatus.INVALID;
  const deletableCardList = cards?.filter(
    (card) =>
      !card.is_default_pay &&
      card.status === PaymentMethodEnums.BankCardStatus.VERIFIED
  );

  const deleteCard = useDeleteCard(card.id || '', {
    onError: () => {
      onDeleteError();
    },
    onSuccess: () => {
      onDeleteSuccess();
    },
  });

  const onDeleteSuccess = () => {
    handleViewCardClose();
    setIsOpenDeleteDialog(false);
    setIsOpenCardList(false);
    showSuccessDialog(SuccessDialogStates.DELETE);
  };

  const onDeleteError = () => {
    setErrorTitle(ERROR_TITLE_DELETE_CARD);
    setErrorMessage(ERROR_DELETE_CARD_GENERIC);
    setOpenErrorDialog(true);
  };

  const setDefaultCard = useUpdateDefaultCard(card.id || '', {
    onError: () => {
      setErrorTitle(genericContent.ERROR_GENERIC_TITLE);
      setErrorMessage(ERROR_SET_DEFAULT_CARD_FAILED);
      setOpenErrorDialog(true);
    },
    onSuccess: () => {
      handleViewCardClose();
      showSuccessDialog(SuccessDialogStates.SET_DEFAULT);
    },
  });

  const onCloseCardListDialog = () => setIsOpenCardList(false);

  const handleSetDefaultCard = () => {
    track('Update Default Card');
    !card?.is_default_pay &&
    card?.status === PaymentMethodEnums.BankCardStatus.VERIFIED
      ? setDefaultCard.mutate()
      : setOpenDefaultDialog(true);
  };

  const setDefaultCardBeforeDelete = useUpdateDefaultCard(
    (deletableCardList && deletableCardList[0]?.id) || '',
    {
      onSuccess: () => {
        deleteCard?.mutate();
      },
    }
  );

  const handleDeleteCard = () => {
    track('Delete Card Confirmed');

    if (card.is_default_pay) {
      if (!deletableCardList?.length) {
        deleteCard?.mutate();
      } else if (deletableCardList?.length === 1) {
        setDefaultCardBeforeDelete.mutate();
      } else {
        setIsOpenCardList(true);
        setIsOpenDeleteDialog(false);
      }
    } else {
      deleteCard?.mutate();
    }
  };

  const onEditClick = () => {
    track('Update Card Started');
    setIsEditing(true);
  };

  const onSaveSuccess = () => {
    handleViewCardClose();
    showSuccessDialog(SuccessDialogStates.EDIT);
  };

  const onSaveNicknameSuccess = () => {
    handleViewCardClose();
    setIsEditingNickname(false);
    setIsEditing(false);
    showSuccessDialog(SuccessDialogStates.UPDATE_NICKNAME);
  };

  const onTryAgainClick = () => {
    setOpenErrorDialog(false);
  };

  const handleEditNickname = () => {
    track('Update Card Nickname Started');
    setIsEditingNickname(true);
  };

  const handleClickRemove = () => {
    track('Delete Card Started');
    setIsOpenDeleteDialog(true);
  };

  const shouldShowPill = isDefault || !isVerified;

  const DisplayCard = () => {
    return (
      <Styled.PaymentCardWrapper>
        <PaymentCard
          alias={card.alias || card.data.issuer_name}
          cardType={card.data.type}
          lastFour={card.data.visualization.last_four_digits}
          schemes={card.data.schemes}
          size="lg"
        />
      </Styled.PaymentCardWrapper>
    );
  };

  if (isEditing) {
    return (
      <CardDetailsEdit
        card={card}
        displayCard={<DisplayCard />}
        is3dsIframeOpen={is3dsIframeOpen}
        onSaveSuccess={onSaveSuccess}
        setIs3dsIframeOpen={setIs3dsIframeOpen}
      />
    );
  }

  if (isEditingNickname) {
    return <NicknameEditor card={card} onSaveSuccess={onSaveNicknameSuccess} />;
  }

  const expiryString = `${card.data.expiry.month}/${card.data.expiry.year}`;

  const cardName = card.alias || card.data.issuer_name || '-';

  return (
    <Styled.CardDetailsWrapper>
      <SetDefaultDialog
        isDefault={isDefault}
        isExpired={!validateCardExpiryDate(expiryString)}
        onClickCancel={() => setOpenDefaultDialog(false)}
        open={openDefaultDialog}
        status={card.status}
      />
      <ConfirmationDialog
        acceptButtonText="Yes, remove it"
        isDanger
        isOpen={isOpenDeleteDialog}
        loading={deleteCard.isPending}
        onClickAccept={handleDeleteCard}
        onClickCancel={() => setIsOpenDeleteDialog(false)}
        title="Are you sure you want to remove this card?"
      />
      <CardListDialog
        cardId={card.id}
        cards={deletableCardList}
        onCloseDialog={onCloseCardListDialog}
        onDeleteError={onDeleteError}
        onDeleteSuccess={onDeleteSuccess}
        open={isOpenCardList}
      />
      <Styled.CardDetailsContainer>
        <DisplayCard />
        <Styled.CardDetails role="list">
          <Styled.CardDetailsRow>
            <Styled.CardDetailsColumn role="listitem">
              <div className="label">Card number</div>
              <p> ••• {card.data.visualization.last_four_digits}</p>
            </Styled.CardDetailsColumn>
            <Styled.CardDetailsColumn role="listitem">
              <div className="label">Expiry</div>
              <p>
                {card.data.expiry.month}/{card.data.expiry.year.slice(-2)}
              </p>
            </Styled.CardDetailsColumn>
            <Styled.CardDetailsColumn role="listitem">
              {shouldShowPill && (
                <CardStatusPill isDefault={isDefault} status={card.status} />
              )}
            </Styled.CardDetailsColumn>
          </Styled.CardDetailsRow>

          <Styled.CardDetailsColumn role="listitem">
            <Styled.NicknameColumns>
              <div>
                <div className="label">Card nickname</div>
                <p>{cardName}</p>
              </div>
              <div>
                {!isValidated && !isInvalid && (
                  <NicknameEditButton onClick={handleEditNickname} />
                )}
              </div>
            </Styled.NicknameColumns>
          </Styled.CardDetailsColumn>
        </Styled.CardDetails>
        {(isValidated || isInvalid) && (
          <CardWarning
            text={cardManagementContent.WHY_NOT_DELETE_MESSAGE()}
            title={cardManagementContent.WHY_NOT_DELETE_TITLE}
          />
        )}
      </Styled.CardDetailsContainer>
      <div>
        {!isInvalid && (
          <Styled.Actions>
            <Styled.ActionButton
              level="primary"
              onClick={onEditClick}
              size="medium"
              variant="branded"
            >
              {isValidated ? (
                'Verify card'
              ) : (
                <>
                  <EditIcon color={theme.styling.colors.onBrand1High.value} />
                  Edit card
                </>
              )}
            </Styled.ActionButton>
            {!isValidated && (
              <>
                <ButtonSpinner
                  disabled={setDefaultCard.isPending}
                  level="secondary"
                  loading={setDefaultCard.isPending}
                  onClick={handleSetDefaultCard}
                  size="medium"
                  spinnerColor={theme.styling.colors.brand1Highest.value}
                  variant="branded"
                >
                  Set as default card
                </ButtonSpinner>
                <Styled.ActionButton
                  level="tertiary"
                  onClick={handleClickRemove}
                  size="medium"
                  variant="destructive"
                >
                  <TrashIcon />
                  Remove
                </Styled.ActionButton>
              </>
            )}
          </Styled.Actions>
        )}
      </div>
      <ErrorDialog
        isOpen={openErrorDialog}
        message={errorMessage || defaultErrorMessage}
        onOpenChange={onTryAgainClick}
        title={errorTitle || genericContent.ERROR_GENERIC_TITLE}
      />
    </Styled.CardDetailsWrapper>
  );
};
