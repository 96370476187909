import type { IIcon } from './typings';

export const FilterIcon = ({
  color = 'currentColor',
  height = 15,
  width = 14,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 14 15"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.75 2.86324C1.75 2.654 1.91962 2.48438 2.12887 2.48438H11.4072C11.6164 2.48438 11.7861 2.654 11.7861 2.86324V4.25499C11.7861 4.35548 11.7461 4.45184 11.6751 4.52289L8.07473 8.12327V11.6777C8.07473 11.8212 7.99365 11.9524 7.8653 12.0165L6.00963 12.9444C5.89219 13.0031 5.75271 12.9968 5.64102 12.9278C5.52932 12.8588 5.46133 12.7368 5.46133 12.6055V8.12327L1.86097 4.52289C1.78992 4.45184 1.75 4.35548 1.75 4.25499V2.86324ZM2.50773 3.24211V4.09806L6.1081 7.69844C6.17915 7.76949 6.21906 7.86585 6.21906 7.96633V11.9925L7.317 11.4435V7.96633C7.317 7.86585 7.35691 7.76949 7.42797 7.69844L11.0283 4.09806V3.24211H2.50773Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
