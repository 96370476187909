import { LogoCard } from '@b707/ponyta';

import { AddIcon } from '@/components/icons';

import { Styled } from '../../styles';

import type { LegacyRef } from 'react';

interface IMycolesNotLinked {
  buttonRef: LegacyRef<HTMLButtonElement>;
  handleOpenAddCard: () => void;
}

export const MycolesNotLinked = ({
  buttonRef,
  handleOpenAddCard,
}: IMycolesNotLinked) => {
  return (
    <Styled.LoyaltyCardItemContainer>
      <Styled.LoyaltyCardItemImage>
        <LogoCard size="md" type="mycoles" />
      </Styled.LoyaltyCardItemImage>
      <Styled.LoyaltyInfoWrapper>
        <>
          <h3>mycoles discount card</h3>
          <p>
            Link your mycoles discount card to get your team member discounts.
          </p>
          <Styled.LinkButton onClick={handleOpenAddCard} ref={buttonRef}>
            <AddIcon height={16} width={16} />{' '}
            <span>Link mycoles discount card</span>
          </Styled.LinkButton>
        </>
      </Styled.LoyaltyInfoWrapper>
    </Styled.LoyaltyCardItemContainer>
  );
};
