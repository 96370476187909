import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  AddressEndpointKeys,
  addressQueryKeys,
  addressTransactionKeys,
} from './profile-query-keys';

import type { AddressesTypes } from '../types/address';
import type { MutationOptions } from '@tanstack/react-query';

interface IUseUpdateAddressRequest {
  address: Partial<AddressesTypes.AddressRequest>;
  addressId: string;
}

export function useUpdateAddress(
  options: MutationOptions<unknown, unknown, IUseUpdateAddressRequest, unknown>
) {
  const auth = useAuth();

  const updateAddressRequest = async (data: IUseUpdateAddressRequest) => {
    return await request(
      endpoints.addresses.ADDRESS_BY_ID(data.addressId),
      auth,
      {
        data: data.address,
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: AddressEndpointKeys.ADDRESS_UPDATE,
        transaction: addressTransactionKeys.addressUpdate,
      }
    );
  };

  return useMutation({
    mutationFn: updateAddressRequest,
    mutationKey: addressQueryKeys.updateAddress,
    ...options,
  });
}
