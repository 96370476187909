import { useSelect } from 'downshift';
import { useRef } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '../icons';

import { LIST_MAX_HEIGHT, LIST_MIN_HEIGHT } from './const';
import { Styled } from './styles';

import type { IDropdownProps, TOption } from './types';

const itemToString = (item: TOption | null) => {
  return !!item ? item?.label : '';
};

export const Dropdown = ({
  disabled,
  hasErrors,
  id,
  label,
  labelDirection = 'top',
  onChange,
  options,
  placeholder,
  selected,
  size = 'medium',
  variation = 'default',
}: IDropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem,
  } = useSelect({
    itemToString,
    items: options || [],
    onSelectedItemChange({ selectedItem }) {
      onChange(selectedItem ?? null);
    },
    selectedItem: selected,
  });

  if (!options) {
    return null;
  }

  const isSearch = variation === 'search';
  const iconSize = isSearch ? 14 : 24;
  const chevronColour = disabled ? '#E5E5E5' : 'var(--colors-onSurfaceA)';

  const listHeight =
    window.innerHeight -
    ((ref?.current?.offsetTop ?? 0) + (ref?.current?.offsetHeight ?? 0));

  return (
    <Styled.Wrapper $direction={labelDirection} ref={ref}>
      {!!label && (
        <Styled.Label $size={size} $variation={isSearch} {...getLabelProps()}>
          {label}
        </Styled.Label>
      )}
      <Styled.InputWrapper>
        <Styled.Selected
          $hasError={hasErrors}
          $placeholder={
            !!placeholder && selectedItem === null ? placeholder : undefined
          }
          $size={size}
          $variation={isSearch}
          aria-label={label}
          {...getToggleButtonProps({
            'aria-activedescendant': undefined,
            disabled,
            id,
            role: 'button',
          })}
        >
          {selectedItem?.label ?? ''}
          {isOpen ? (
            <ChevronUpIcon
              color={chevronColour}
              height={iconSize}
              width={iconSize}
            />
          ) : (
            <ChevronDownIcon
              color={chevronColour}
              height={iconSize}
              width={iconSize}
            />
          )}
        </Styled.Selected>
        <Styled.List
          $dynamicHeight={
            listHeight < LIST_MAX_HEIGHT && listHeight >= LIST_MIN_HEIGHT
              ? listHeight
              : undefined
          }
          $isOpen={isOpen}
          {...getMenuProps({ disabled, role: 'radiogroup' })}
        >
          {options.map((item, index) => (
            <Styled.ListItem
              $highlighted={highlightedIndex === index}
              key={`${item}${index}`}
              {...getItemProps({
                'aria-checked':
                  highlightedIndex === index ||
                  (!isOpen && selectedItem?.value === item.value),
                'aria-selected': undefined,
                index,
                item,
                role: 'radio',
              })}
            >
              {item.label}
            </Styled.ListItem>
          ))}
        </Styled.List>
      </Styled.InputWrapper>
    </Styled.Wrapper>
  );
};
