export enum CardInputFieldNames {
  CARD_ALIAS = 'cardAlias',
  CARD_EXPIRY_DATE = 'cardExpiryDate',
  CARD_IS_DEFAULT = 'cardIsDefault',
  CARD_NUMBER = 'cardNumber',
  CARD_SECURITY_CODE = 'cardSecurityCode',
}

export enum CardInputLabels {
  CARD_ALIAS = 'Card nickname',
  CARD_EXPIRY_DATE = 'Expiry date (MM/YY)*',
  CARD_NUMBER = 'Card number*',
  CARD_SECURITY_CODE = 'Security code (CVV/CVC)*',
}
