import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePrevious } from 'react-use';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { TextField } from '@/components';
import { useClickAway } from '@/hooks';
import { Keys } from '@/utils';

import { AddressInputFieldNames, AddressInputLabels } from '../address-input';

import { Styled } from './styles';
import { getRelevantAddressData } from './utils';

import type { KeyboardEvent } from 'react';
import type { Suggestion } from 'use-places-autocomplete';

type GeocodeResult = google.maps.GeocoderResult;

const DEFAULT_SELECTION = -2;
const MANUAL_SELECTION = -1;

export const AddressAutocomplete = () => {
  const geocoder = new window.google.maps.Geocoder();
  const addressAutocompleteRef = useRef<HTMLInputElement>(null);
  const addressAutocompleteInputRef = useRef<HTMLInputElement>(null);
  const { formState, getValues, reset } = useFormContext();
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [manualInput, setManualInput] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(DEFAULT_SELECTION);
  const prevSelection = usePrevious(currentSelection);

  const optionRefs = useRef<HTMLUListElement>(null);

  const {
    clearSuggestions,
    ready,
    setValue,
    suggestions: { data },
    value,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue: getValues(AddressInputFieldNames.ADDRESS_LINE_1),
    requestOptions: {
      componentRestrictions: {
        country: 'au',
      },
    },
  });

  const handleKeyUpInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      setValue(e.currentTarget.value);
    }
  };

  const handleSelectingOption = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ARROW_DOWN || e.key === Keys.ARROW_UP) {
      e.preventDefault();
      setShowSuggestions(true);
    }

    if (e.key === Keys.ARROW_DOWN && currentSelection < data.length - 1) {
      if (currentSelection < MANUAL_SELECTION) {
        setCurrentSelection(0);
        return;
      }
      setCurrentSelection((selection) => selection + 1);
    }

    if (e.key === Keys.ARROW_UP && currentSelection > MANUAL_SELECTION) {
      setCurrentSelection((selection) => selection - 1);
    }
  };

  const handleOptionSelection = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.SPACE) {
      // Prevent form submission
      e.preventDefault();

      if (currentSelection <= MANUAL_SELECTION) {
        handleClearSuggestions();
        setManualInput(true);
        return;
      }

      const selectedItem = data[currentSelection];
      if (selectedItem) {
        applySelectedSuggestion(selectedItem);
      }
    }
  };

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleSelectingOption(e);
    handleOptionSelection(e);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      showSuggestions &&
      addressAutocompleteRef.current &&
      !addressAutocompleteRef.current.contains(e.target as Node)
    ) {
      handleClearSuggestions();
    }
  };

  useClickAway(addressAutocompleteRef, handleClickOutside, 'mousedown');

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!showSuggestions && !manualInput) {
      setShowSuggestions(true);
    }
    setValue(e.target.value);
  };

  const handleClearSuggestions = () => {
    clearSuggestions();
    setShowSuggestions(false);
    setCurrentSelection(DEFAULT_SELECTION);
  };

  const handleSelectSuggestion = (suggestion: Suggestion) => () => {
    applySelectedSuggestion(suggestion);
  };

  const handleKeyboardBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget?.nodeName === 'INPUT') {
      handleClearSuggestions();
    }
  };

  const handleManualSelection = () => {
    handleClearSuggestions();
    setManualInput(true);
    // Autofocus the input
    addressAutocompleteInputRef?.current?.focus();
  };

  const handleKeySelectSuggestion =
    (suggestion: Suggestion) => (e: KeyboardEvent) => {
      if (e.key === Keys.ENTER || e.key === Keys.SPACE) {
        e.preventDefault();
        applySelectedSuggestion(suggestion);
      }
    };

  const applySelectedSuggestion = (suggestion: Suggestion) => {
    geocoder?.geocode?.(
      { address: suggestion.description },
      (results: GeocodeResult[] | null, status: string) => {
        if (status === 'OK') {
          const address = getRelevantAddressData(results);

          reset((prev) => ({
            ...prev,
            ...address,
          }));
          setValue(address[AddressInputFieldNames.ADDRESS_LINE_1] ?? '');
          handleClearSuggestions();
        }
      }
    );
  };

  useEffect(() => {
    const option = optionRefs?.current?.querySelector(
      `li:nth-of-type(${currentSelection})`
    );

    if (
      currentSelection !== prevSelection &&
      !!option &&
      !!optionRefs?.current
    ) {
      optionRefs.current.scrollBy({
        behavior: 'smooth',
        top:
          currentSelection > (prevSelection as number)
            ? option.clientHeight
            : -option.clientHeight,
      });
    }
  }, [currentSelection, prevSelection]);

  const isShowList = showSuggestions && !!data?.length;

  return (
    <div ref={addressAutocompleteRef}>
      <TextField
        aria-activedescendant={
          currentSelection >= 0 ? `ex-list-item-${currentSelection}` : undefined
        }
        aria-autocomplete="list"
        aria-controls="address-suggestions"
        aria-expanded={showSuggestions}
        aria-haspopup="listbox"
        disabled={!ready || formState.isSubmitting}
        helpText={!isShowList && 'Search for your address or enter it manually'}
        label={AddressInputLabels.ADDRESS_LINE_1}
        maxLength={60}
        name={AddressInputFieldNames.ADDRESS_LINE_1}
        onBlur={handleKeyboardBlur}
        onChange={handleChangeInput}
        onKeyDown={handleKeyDownInput}
        onKeyUp={handleKeyUpInput}
        ref={addressAutocompleteInputRef}
        required={true}
        role="combobox"
        value={value}
      />
      {isShowList && (
        <Styled.Suggestions
          aria-label="address-suggestions"
          id="address-suggestions"
          ref={optionRefs}
          role="listbox"
          tabIndex={-1}
        >
          <Styled.Suggestion
            $selected={currentSelection === MANUAL_SELECTION}
            aria-selected={currentSelection === MANUAL_SELECTION}
            key="manual-address"
            onClick={handleManualSelection}
            role="option"
          >
            Can’t find your address? Enter manually here
          </Styled.Suggestion>
          {data?.map((suggestion, idx) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Styled.SuggestionListItem
                $selected={currentSelection === idx}
                aria-selected={currentSelection === idx}
                id={`address-suggestions-${idx}`}
                key={place_id}
                onClick={handleSelectSuggestion(suggestion)}
                onKeyDown={handleKeySelectSuggestion(suggestion)}
                role="option"
              >
                {main_text} {secondary_text}
              </Styled.SuggestionListItem>
            );
          })}
        </Styled.Suggestions>
      )}
    </div>
  );
};
