import { Spinner } from '@/components';
import { useTheme } from '@/contexts/theme';

import { Styled } from './styles';

export const OrdersLoadingPage = () => {
  const { theme } = useTheme();
  return (
    <Styled.NoHistory>
      Loading payment history...
      <Spinner
        $secondaryColor={theme.styling.colors.onSurfaceD.value}
        color={theme.styling.colors.onSurfaceA.value}
        size={32}
      />
    </Styled.NoHistory>
  );
};
