import styled, { css } from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const SearchContainer = styled.form<{ $size?: 'medium' | 'small' }>`
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-xs);
  justify-content: flex-end;
  margin-bottom: 0;
  width: 100%;

  @media (min-width: ${BreakpointRems.md}) {
    grid-template-columns: minmax(32%, 200px) minmax(32%, 200px) auto auto;
  }

  @media (min-width: ${BreakpointRems.sm}) {
    grid-template-columns: minmax(33%, 200px) minmax(31%, 200px) auto auto;
  }

  > button {
    height: ${({ $size }) => ($size === 'small' ? '2.5rem' : 'auto')};

    @media (min-width: ${BreakpointRems.sm}) {
      margin-bottom: var(--space-m);
    }
  }
`;

const SearchError = styled.div<{ $size?: 'medium' | 'small' }>`
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  font-size: ${(props) =>
    props.$size === 'small' ? 'var(--fontSizes-12)' : 'var(--fontSizes-14)'};
  color: var(--colors-onErrorLow);
  line-height: var(--lineHeights-half);
  position: absolute;
  bottom: ${(props) =>
    props.$size === 'small' ? '-1.25rem' : 'calc(var(--space-xs) * -1)'};
`;

const SearchInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  position: relative;
`;

const SearchLabel = styled.label`
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
`;

const SearchInputContainer = styled.div<{
  $disabled?: boolean;
  $error?: boolean;
  $size?: 'medium' | 'small';
}>`
  width: 100%;
  padding: ${(props) =>
    props.$size === 'small'
      ? 'var(--space-xs) var(--space-s)'
      : 'var(--space-s)'};
  gap: var(--space-2xs);

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--colors-surfaceLowest);
  border: 1px solid var(--colors-onSurfaceC);
  border-radius: var(--radii-md);
  height: ${({ $size }) => ($size === 'small' ? '2.5rem' : 'auto')};

  @media (min-width: ${BreakpointRems.sm}) {
    margin-bottom: var(--space-m);
  }

  ${({ $error }) =>
    $error &&
    css`
      margin-bottom: var(--space-m);
      border-color: var(--colors-onErrorLow);
    `}

  &:focus-within {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
    border-color: ${(props) =>
      props.$error ? 'var(--colors-onErrorLow)' : 'var(--colors-onSurfaceA)'};
  }

  ${(props) =>
    props.$disabled &&
    'background: var(--colors-surfaceLow); border-color: var(--colors-onSurfaceD); cursor: not-allowed;'}

  &:hover {
    ${(props) =>
      !props.$disabled &&
      !props.$error &&
      'border-color: var(--colors-onSurfaceB);'}
  }
`;

const ClearButton = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

const SearchInput = styled.input<{ $size?: 'medium' | 'small' }>`
  width: 100%;
  border: 0;

  color: var(--colors-onSurfaceA);
  font-style: normal;

  font-weight: var(--fontWeights-normal);
  font-size: ${({ $size }) =>
    $size === 'small' ? 'var(--fontSizes-14)' : 'var(--fontSizes-16)'};
  line-height: var(--lineHeights-half);

  &::placeholder {
    color: var(--colors-onSurfaceD);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--colors-onSurfaceD);
    background: var(--colors-surfaceLow);
    cursor: not-allowed;
  }
`;

export const Styled = {
  ClearButton,
  SearchContainer,
  SearchError,
  SearchInfo,
  SearchInput,
  SearchInputContainer,
  SearchLabel,
};
