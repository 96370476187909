import { ErrorDialog } from '@/components';
import { ExclamationMarkIcon } from '@/components/icons';
import {
  ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT,
  TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
} from '@/config/language/errors';

import type { PaymentMethodEnums } from '@/features/card-management';

interface ISetDefaultDialogProps {
  isDefault?: boolean;
  isExpired?: boolean;
  onClickCancel: () => void;
  open: boolean;

  status?: PaymentMethodEnums.BankCardStatus;
}

export const SetDefaultDialog = ({
  isDefault,
  isExpired,
  onClickCancel,
  open,
  status,
}: ISetDefaultDialogProps) => {
  const statusText = isExpired ? 'Expired' : status?.toLowerCase();

  const statusIntro = ` Your card is ${statusText} and cannot be set as default. Please select a
  different card and choose “Set as default card”.`;

  const statusTitle = `Can't set ${statusText} card as default`;

  return (
    <ErrorDialog
      buttonText="Continue"
      icon={<ExclamationMarkIcon />}
      isOpen={open}
      message={
        isDefault ? <ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT /> : statusIntro
      }
      onOpenChange={onClickCancel}
      title={isDefault ? TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT : statusTitle}
      tryAgain={!isDefault}
    />
  );
};
