export const formatFlybuysNumber = (flybuysNumber?: string) => {
  const onlyDigitsAndAsterisks = /^[\d*]+$/;

  if (!flybuysNumber) {
    return '';
  }

  if (!onlyDigitsAndAsterisks.test(flybuysNumber) || flybuysNumber.length < 5) {
    return flybuysNumber;
  }

  if (flybuysNumber.length < 12) {
    return `•••• ${flybuysNumber.slice(-4)}`;
  }

  const groups = flybuysNumber.match(/.{1,4}/g);

  if (!groups) {
    return flybuysNumber;
  }

  const firstGroup = groups.shift();
  const lastGroup = groups.pop();

  const middleGroups = groups.map((group) => group.replaceAll(/[\d*]/g, '•'));

  return [firstGroup, ...middleGroups, lastGroup].join(' ');
};
