import { flybuysConfig } from '@/config/flybuys';
import { SessionStorage } from '@/config/storage/session';
import { getRandomStringBase64URLEncoded } from '@/utils/auth';

import type { FlybuysTypes } from '../types';

export const getFlybuysAuthUrl = async (
  authConfig: FlybuysTypes.TLoyaltyAuthConfig,
  code_challenge: string
): Promise<string> => {
  const { audience, client_id, code_challenge_method, endpoints, scope } =
    authConfig;
  const state = getRandomStringBase64URLEncoded(16);
  const params: FlybuysTypes.TFlybuysAuthRequest = {
    audience,
    client_id,
    code_challenge,
    code_challenge_method,
    prompt: 'login', // force a login every time
    redirect_uri: flybuysConfig.auth.authorize.redirect_uri,
    response_type: flybuysConfig.auth.authorize.response_type,
    scope,
    state,
  };
  const query = new URLSearchParams(params);
  sessionStorage.setItem(SessionStorage.FLYBUYS_STATE, state);
  return new URL(`${endpoints.authorize}?${query}`).href;
};
