import { useEffect, useRef, useState } from 'react';

import { getNameInitials } from '@/utils';

import { Styled } from './styles';

import type { CSSObject } from 'styled-components/dist/types';

export type TAvatarSize = 'lg' | 'md' | 'sm' | 'xl';

export interface TProfileAvatar {
  active?: boolean;
  alt?: string;
  avatarCSS?: CSSObject;
  delayMs?: number;
  name?: string;
  size?: TAvatarSize;
  src?: string;
}

const INITIAL_STATE = false;

export const ProfileAvatar = ({
  active,
  alt,
  avatarCSS,
  name,
  size = 'md',
  src,
}: TProfileAvatar) => {
  const initials = getNameInitials(name);
  const [showFallback, setShowFallback] = useState(INITIAL_STATE);
  const [showImage, setShowImage] = useState(INITIAL_STATE);
  const firstRender = useRef(INITIAL_STATE);

  const handleError = () => {
    setShowFallback(true);
  };

  const handleLoad = () => {
    setShowImage(true);
  };

  useEffect(() => {
    if (!!firstRender.current) {
      setShowFallback(INITIAL_STATE);
      setShowImage(INITIAL_STATE);
    }
    firstRender.current = true;
  }, [src]);

  return (
    <Styled.AvatarRoot $active={active} $size={size} style={{ ...avatarCSS }}>
      {!showFallback && !!src && (
        <Styled.AvatarImage
          $loading={!showFallback && !showImage}
          alt={alt ?? name}
          onError={handleError}
          onLoad={handleLoad}
          src={src}
        />
      )}
      {(!!initials || showFallback) && !showImage && (
        <Styled.AvatarFallback $size={size}>{initials}</Styled.AvatarFallback>
      )}
    </Styled.AvatarRoot>
  );
};
