import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { type FieldValues, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { Form, FormHint, SelectField, TextField } from '@/components';
import { ExclamationMarkIcon } from '@/components/icons';
import { useTheme } from '@/contexts/theme';
import { useDeleteProfile } from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { Routes } from '@/routes/constants';
import { validate } from '@/utils';

import { DELETE_REASONS } from './constants';
import { Styled } from './styles';

interface IDeleteDialogBody {
  closeDialog: () => void;
  setIsErrorDialogOpen: (isOpen: boolean) => void;
}

export enum FieldNames {
  FEEDBACK = 'feedback',
  REASON = 'reason',
}

const DEFAULT_VALUES = {
  [FieldNames.REASON]: '',
  [FieldNames.FEEDBACK]: '',
} as FieldValues;

export const DeleteDialogBody = ({
  closeDialog,
  setIsErrorDialogOpen,
}: IDeleteDialogBody) => {
  const { theme } = useTheme();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();

  const validationSchema = Joi.object({
    [FieldNames.REASON]: validate.requiredReason(),
  }).unknown(true);

  const formMethods = useForm({
    defaultValues: DEFAULT_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const deleteProfile = useDeleteProfile({
    onError: () => {
      setIsSubmitting(false);
      closeDialog();
      setIsErrorDialogOpen(true);
    },
    onSuccess: () => {
      auth.removeUser().then(() => {
        navigate(Routes.DELETED);
      });
    },
  });

  const onSubmitForm = async ({ feedback, reason }: FieldValues) => {
    track('Delete Account Confirmed');
    setIsSubmitting(true);
    deleteProfile.mutate({ feedback, reason });
  };

  return (
    <>
      <Styled.Body>
        <Styled.Icon>
          <ExclamationMarkIcon height={80} width={80} />
        </Styled.Icon>
        <div>
          <Styled.Title>
            Are you sure you want to delete your account?
          </Styled.Title>
          <Styled.Message>
            All your wallet, account and transaction information will be
            permanently deleted from our systems.
          </Styled.Message>
        </div>
        <Styled.FormHint>
          <FormHint />
        </Styled.FormHint>
        <Form config={formMethods} onSubmitForm={onSubmitForm}>
          <Styled.FormFields>
            <SelectField
              label="Reason *"
              name="reason"
              options={DELETE_REASONS}
              placeholder={'Select one...'}
              required
            />
            <TextField
              helpText="Response limited to 255 characters"
              label="Feedback (optional)"
              maxLength={255}
              name="feedback"
            />
          </Styled.FormFields>
          <Styled.Actions>
            <Styled.ButtonCancel
              onClick={closeDialog}
              size="medium"
              type="button"
            >
              No, keep account
            </Styled.ButtonCancel>
            <Styled.ButtonConfirm
              disabled={isSubmitting}
              loading={isSubmitting}
              size="medium"
              spinnerColor={theme.styling.colors.onSurfaceC.value}
              type="submit"
            >
              Yes, delete my account
            </Styled.ButtonConfirm>
          </Styled.Actions>
        </Form>
      </Styled.Body>
    </>
  );
};
