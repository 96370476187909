import type { IIcon } from './typings';

export const ChevronUpIcon = ({
  color = 'currentColor',
  height = 24,
  width = 24,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.3636 7.31439C11.7151 6.96291 12.2849 6.96291 12.6364 7.31439L20.7364 15.4144C21.0879 15.7659 21.0879 16.3357 20.7364 16.6872C20.3849 17.0386 19.8151 17.0386 19.4636 16.6872L12 9.22357L4.5364 16.6872C4.18492 17.0386 3.61508 17.0386 3.2636 16.6872C2.91213 16.3357 2.91213 15.7659 3.2636 15.4144L11.3636 7.31439Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
