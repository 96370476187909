import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { generateCardCipherText } from '../utils';

import { endpoints } from './endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodQueryKeys,
  paymentMethodTransactionKeys,
} from './query-keys';

import type { PaymentMethodTypes, TBaasAddPaymentMethodErrors } from '../types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

type TAddCard3dsAuthorizeResponse = {
  card_id: string;
  route: '3ds' | '3ds2';
  three_d_secure: {
    id: string;
  };
  type: number;
};

export function useAddCard3dsAuthorize(
  options: UseMutationOptions<
    TAddCard3dsAuthorizeResponse,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    PaymentMethodTypes.TAddCardRequest,
    unknown
  >
) {
  const auth = useAuth();

  const addCard3dsAuthorizeRequest = async (
    payload: PaymentMethodTypes.TAddCardRequest
  ) => {
    const cipher_text = await generateCardCipherText({
      data: payload.data,
      publicKeyDetails: payload.publicKeyDetails,
    });
    return await request<TAddCard3dsAuthorizeResponse>(
      endpoints.BANK_CARDS_AUTHORIZE,
      auth,
      {
        data: {
          ...(payload.data.alias && {
            alias: payload.data.alias,
          }),
          card: {
            cipher_text,
          },
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_ADD_3DS_AUTHORIZE,
        transaction: paymentMethodTransactionKeys.bankCardAdd3DSAuthorize,
      }
    );
  };

  return useMutation({
    mutationFn: addCard3dsAuthorizeRequest,
    mutationKey: paymentMethodQueryKeys.bankCardAddAuthorize,
    ...options,
  });
}
