import { ExclamationMarkIcon } from '@/components/icons';

import { Styled } from './styles';

interface ICardWarning {
  text: React.ReactNode;
  title: React.ReactNode;
}

export const CardWarning = ({ text, title }: ICardWarning) => {
  return (
    <Styled.Container>
      <Styled.IconColumn>
        <ExclamationMarkIcon
          backgroundColor="transparent"
          color="var(--colors-onErrorLow)"
          size={20}
        />
      </Styled.IconColumn>
      <Styled.TextColumn>
        <Styled.Title>{title}</Styled.Title>
        {!!text && <Styled.Text>{text}</Styled.Text>}
      </Styled.TextColumn>
    </Styled.Container>
  );
};
