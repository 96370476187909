export enum Routes {
  ACCEPT_TERMS = '/accept-terms',
  ACCOUNT = '/account',
  ACTIVITY = '/activity',
  ADDRESSES = '/addresses',
  CONFIRM_DETAILS = '/confirm-details',
  DELETED = '/deleted',
  FLYBUYS = '/auth/flybuys',
  HOME = '/',
  LOGIN_REDIRECT = '/login-redirect',
  LOGOUT = '/logged-out',
  MYCOLES = '',
  TIMED_OUT = '/timed-out',
  UNKNOWN = '*',
  WALLET = '/wallet',
}

export const PARAM_FLYBUYS_CODE = 'code';
export const PARAM_FLYBUYS_STATE = 'state';
