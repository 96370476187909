export enum PaymentMethodEndpointKeys {
  BANK_CARDS = 'BANK_CARDS',
  BANK_CARD_ADD_3DS_AUTHORIZE = 'BANK_CARD_ADD_3DS_AUTHORIZE',
  BANK_CARD_ADD_3DS_FINALIZE = 'CARD_ADD_3DS_FINALIZE',
  BANK_CARD_DELETE = 'BANK_CARD_DELETE',
  BANK_CARD_UPDATE_3DS = 'CARD_UPDATE_3DS',
  CARD_UPDATE_DEFAULT = 'CARD_UPDATE_DEFAULT',
  CARD_UPDATE_NICKNAME = 'CARD_UPDATE_NICKNAME',
  FLYBUYS_CARDS = 'FLYBUYS_CARDS',
  LOYALTY_CARDS = 'LOYALTY_CARDS',
  LOYALTY_CARD_ADD = 'LOYALTY_CARD_ADD',
  LOYALTY_CARD_DELETE = 'LOYALTY_CARD_DELETE',
  MYCOLES_CARDS = 'MYCOLES_CARDS',

  PUBLIC_KEY = 'PUBLIC_KEY',
}
export const paymentMethodTransactionKeys = {
  bankCardAdd3DSAuthorize: 'Add Bank Card 3DS Authorize',
  bankCardAdd3DSFinalize: 'Add Bank Card 3DS Finalize',
  bankCardDelete: 'Delete Bank Card',
  bankCardUpdate3DS: 'Update Bank Card 3DS',
  bankCards: 'Get Bank Cards',
  cardUpdateDefault: 'Update Default Card',
  cardUpdateNickname: 'Update Card Nickname',
  flybuysCards: 'Get Flybuys Cards',
  loyaltyCardAdd: 'Add Loyalty Card',
  loyaltyCardDelete: 'Delete Loyalty Card',
  loyaltyCards: 'Get Loyalty Cards',
  mycolesCards: 'Get mycoles Cards',
  publicKey: 'Public Key',
};

export const paymentMethodQueryKeys = {
  bankCardAddAuthorize: ['bank-card-add-authorize'],
  bankCardAddFinalize: ['bank-card-add-finalize'],
  bankCardDelete: ['bank-card-delete'],
  bankCardUpdate: ['bank-card-update'],
  bankCards: ['bank-cards'],
  flybuysCards: ['flybuys-cards'],
  loyaltyCardAdd: ['loyalty-card-add'],
  loyaltyCards: ['loyalty-cards'],
  loyaltyCardsDelete: ['loyalty-cards-delete'],
  mycolesCards: ['mycoles-cards'],

  publicKey: ['public-key'],
  updateCardNickname: ['update-card-nickname'],
  updateDefaultCard: ['update-default-card'],
};
