export enum Keys {
  A = 'a',
  ALT = 'Alt',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArowRight',
  ARROW_UP = 'ArrowUp',
  B = 'b',
  BACKSPACE = 'Backspace',
  C = 'c',
  CAPSLOCK = 'CapsLock',
  CONTEXT_MENU = 'ContextMenu',
  CTRL = 'Ctrl',
  D = 'd',
  DELETE = 'Delete',
  E = 'e',
  END = 'End',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  F = 'f',
  F1 = 'f1',
  F10 = 'f10',
  F11 = 'f11',
  F12 = 'f12',
  F13 = 'f13',
  F14 = 'f14',
  F15 = 'f15',
  F16 = 'f16',
  F17 = 'f17',
  F18 = 'f18',
  F19 = 'f19',
  F2 = 'f2',
  F20 = 'f20',
  F21 = 'f21',
  F22 = 'f22',
  F23 = 'f23',
  F24 = 'f24',
  F3 = 'f3',
  F4 = 'f4',
  F5 = 'f5',
  F6 = 'f6',
  F7 = 'f7',
  F8 = 'f8',
  F9 = 'f9',
  G = 'g',
  H = 'h',
  HOME = 'Home',
  I = 'i',
  INSERT = 'Insert',
  J = 'j',
  K = 'k',
  L = 'l',
  M = 'm',
  N = 'n',
  NUMLOCK = 'NumLock',
  NUMPAD_ADD = 'NumpadAdd',
  NUMPAD_COMMA = 'NumpadComma',
  NUMPAD_DECIMAL = 'NumpadDecimal',
  NUMPAD_DIVIDE = 'NumpadDivide',
  NUMPAD_EQUAL = 'NumpadEqual',
  NUMPAD_MULTIPLY = 'NumpadMultiply',
  NUMPAD_SUBSTRACT = 'NumpadSubtract',
  NUM_0 = 'Num0',
  NUM_1 = 'Num1',
  NUM_2 = 'Num2',
  NUM_3 = 'Num3',
  NUM_4 = 'Num4',
  NUM_5 = 'Num5',
  NUM_6 = 'Num6',
  NUM_7 = 'Num7',
  NUM_8 = 'Num8',
  NUM_9 = 'Num9',
  O = 'o',
  P = 'p',
  PAGE_DOWN = 'PageDown',
  PAGE_UP = 'PageUp',
  PAUSE = 'Pause',
  PRINT_SCREEN = 'PrintScreen',
  Q = 'q',
  R = 'r',
  S = 's',
  SCROLL_LOCK = 'ScrollLock',
  SHIFT = 'Shift',
  SLASH = '/',
  SPACE = 'Space',
  T = 't',
  TAB = 'Tab',
  U = 'u',
  V = 'v',
  W = 'w',
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export type TKeys = keyof typeof Keys;
export type TKeyValues = `${Keys}`;

export const keyboard = {
  [Keys.ALT]: 18,
  [Keys.ARROW_DOWN]: 40,
  [Keys.ARROW_LEFT]: 37,
  [Keys.ARROW_RIGHT]: 39,
  [Keys.ARROW_UP]: 38,
  [Keys.CAPSLOCK]: 20,
  [Keys.CONTEXT_MENU]: 93,
  [Keys.CTRL]: 17,
  [Keys.DELETE]: 46,
  [Keys.END]: 35,
  [Keys.ENTER]: 13,
  [Keys.ESCAPE]: 27,
  [Keys.F1]: 112,
  [Keys.F2]: 113,
  [Keys.F3]: 114,
  [Keys.F4]: 115,
  [Keys.F5]: 116,
  [Keys.F6]: 117,
  [Keys.F7]: 118,
  [Keys.F8]: 119,
  [Keys.F9]: 120,
  [Keys.F10]: 121,
  [Keys.F11]: 122,
  [Keys.F12]: 123,
  [Keys.F13]: 124,
  [Keys.F14]: 125,
  [Keys.F15]: 126,
  [Keys.F16]: 127,
  [Keys.F17]: 128,
  [Keys.F18]: 129,
  [Keys.F19]: 130,
  [Keys.F20]: 131,
  [Keys.F21]: 132,
  [Keys.F22]: 133,
  [Keys.F23]: 134,
  [Keys.F24]: 135,
  [Keys.HOME]: 36,
  [Keys.INSERT]: 45,
  [Keys.NUM_0]: 96,
  [Keys.NUM_1]: 97,
  [Keys.NUM_2]: 98,
  [Keys.NUM_3]: 99,
  [Keys.NUM_4]: 100,
  [Keys.NUM_5]: 101,
  [Keys.NUM_6]: 102,
  [Keys.NUM_7]: 103,
  [Keys.NUM_8]: 104,
  [Keys.NUM_9]: 105,
  [Keys.NUMLOCK]: 144,
  [Keys.NUMPAD_ADD]: 107,
  [Keys.NUMPAD_COMMA]: 194,
  [Keys.NUMPAD_DECIMAL]: 110,
  [Keys.NUMPAD_DIVIDE]: 111,
  [Keys.NUMPAD_EQUAL]: 12,
  [Keys.NUMPAD_MULTIPLY]: 106,
  [Keys.NUMPAD_SUBSTRACT]: 109,
  [Keys.PAGE_UP]: 33,
  [Keys.PAGE_DOWN]: 34,
  [Keys.PAUSE]: 19,
  [Keys.PRINT_SCREEN]: 44,
  [Keys.SCROLL_LOCK]: 145,
  [Keys.SHIFT]: 16,
  [Keys.SPACE]: 32,
  [Keys.TAB]: 9,
  [Keys.A]: 65,
  [Keys.B]: 66,
  [Keys.C]: 67,
  [Keys.D]: 68,
  [Keys.E]: 69,
  [Keys.F]: 70,
  [Keys.G]: 71,
  [Keys.H]: 72,
  [Keys.I]: 73,
  [Keys.J]: 74,
  [Keys.K]: 75,
  [Keys.L]: 76,
  [Keys.M]: 77,
  [Keys.N]: 78,
  [Keys.O]: 79,
  [Keys.P]: 80,
  [Keys.Q]: 81,
  [Keys.R]: 82,
  [Keys.S]: 83,
  [Keys.T]: 84,
  [Keys.U]: 85,
  [Keys.V]: 86,
  [Keys.W]: 87,
  [Keys.X]: 88,
  [Keys.Y]: 89,
  [Keys.Z]: 90,
};
