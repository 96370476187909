import { hasConsentedToTerms, useGetProfile } from '@/features/profile';
import { AcceptTerms, ConfirmDetails } from '@/features/user';

export const CheckTerms = () => {
  const { data: user, isError, isLoading } = useGetProfile();

  if (!isLoading && !isError) {
    if (!user?.first_name || !user?.last_name) {
      return <ConfirmDetails />;
    } else if (!hasConsentedToTerms(user)) {
      return <AcceptTerms />;
    }
  }

  return null;
};
