import type { OrderServiceTypes } from './typings';

export const merchantTransactionKeys = {
  merchantLogo: 'Get Merchant Logo',
  orders: 'Get Orders Activity',
};

export enum MerchantEndpointKeys {
  MERCHANT_LOGO = 'MERCHANT_LOGO',
  ORDERS = 'ORDERS',
}

export const merchantQueryKeys = {
  merchantLogo: (merchantId: string) => ['merchant-logo', merchantId],
};

export const ordersQueryKeys = {
  orders: (params?: OrderServiceTypes.TOrdersListRequest) => ['orders', params],
};
