import { forwardRef } from 'react';

import { Styled } from './styles';

type Ref = HTMLInputElement;

interface IRangeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string;
}

const RangeInput = forwardRef<Ref, IRangeInputProps>((props, ref) => {
  return (
    <Styled.RangeBox>
      {props.prefix}
      <Styled.RangeInput
        {...props}
        max={props.max}
        min={props.min}
        ref={ref}
        type="number"
      />
    </Styled.RangeBox>
  );
});

RangeInput.displayName = 'RangeInput';
export { RangeInput };
