import type { IIcon } from './typings';

export const PowerIcon = ({ color = 'black', size = 18 }: IIcon) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 18 18"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.48379 9.91056C8.48379 10.2005 8.71884 10.4356 9.00879 10.4356C9.29874 10.4356 9.53379 10.2005 9.53379 9.91056V4.24766C9.53379 3.95771 9.29874 3.72266 9.00879 3.72266C8.71884 3.72266 8.48379 3.95771 8.48379 4.24766V9.91056Z"
      fill={color}
    />
    <path
      d="M4.60834 11.264C4.25594 10.3877 4.17377 9.42599 4.37241 8.50258C4.57105 7.57917 5.04141 6.7363 5.72299 6.08239C5.93222 5.88166 5.9391 5.54932 5.73837 5.34009C5.53764 5.13086 5.20529 5.12398 4.99607 5.32471C4.1633 6.12367 3.5886 7.15351 3.3459 8.28176C3.10319 9.41001 3.20358 10.5851 3.63415 11.6558C4.06472 12.7265 4.80579 13.6439 5.76202 14.2901C6.71825 14.9362 7.84591 15.2814 8.99997 15.2814C10.154 15.2814 11.2817 14.9362 12.2379 14.2901C13.1942 13.6439 13.9352 12.7265 14.3658 11.6558C14.7964 10.5851 14.8967 9.41 14.654 8.28176C14.4113 7.15351 13.8366 6.12367 13.0039 5.32471C12.7946 5.12398 12.4623 5.13086 12.2616 5.34009C12.0608 5.54932 12.0677 5.88166 12.2769 6.08239C12.9585 6.7363 13.4289 7.57917 13.6275 8.50258C13.8262 9.42599 13.744 10.3877 13.3916 11.264C13.0392 12.1404 12.4327 12.8912 11.6501 13.4201C10.8674 13.9489 9.9445 14.2314 8.99997 14.2314C8.05544 14.2314 7.1325 13.9489 6.34988 13.4201C5.56725 12.8912 4.96074 12.1404 4.60834 11.264Z"
      fill={color}
    />
  </svg>
);
