import { create } from 'zustand';

type TA11yActions = {
  clearA11yAnnouncement: () => void;
  setA11yAnnouncement: (announcement: string) => void;
};

interface IA11yStore {
  a11yAnnouncement: string | null;
  actions: TA11yActions;
}

const useAppErrorStore = create<IA11yStore>((set) => ({
  a11yAnnouncement: null,
  actions: {
    clearA11yAnnouncement: () => set({ a11yAnnouncement: null }),
    setA11yAnnouncement: (a11yAnnouncement) => set({ a11yAnnouncement }),
  },
}));

export const useA11yAnnouncement = () =>
  useAppErrorStore((state) => state.a11yAnnouncement);

export const useSetA11yActions = () =>
  useAppErrorStore((state) => state.actions);
