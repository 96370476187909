import isEmpty from 'lodash.isempty';
import { User } from 'oidc-client-ts';

const urlParams = new URLSearchParams(window.location.search);
const clientId =
  urlParams.get('clientId') || import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID;

export const authAuthority = import.meta.env.VITE_REACT_APP_AUTH_AUTHORITY;
export const authClientId =
  clientId || import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID;

export const AuthStorageKeys = {
  OIDC: `oidc.user:${authAuthority}:${authClientId}`,
};

function getOidcAuthStorage() {
  const oidcStorage = sessionStorage.getItem(AuthStorageKeys.OIDC) || '';
  return !isEmpty(oidcStorage) ? User.fromStorageString(oidcStorage) : null;
}

export function hasAuthAccessToken() {
  const oidcAuthStorage = getOidcAuthStorage();
  return !!oidcAuthStorage?.access_token;
}
