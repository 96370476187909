import { isValid, parse, parseISO } from 'date-fns';

import { DateFormats } from '@/config/language';

const parseDayMonthYear = (date: string) =>
  parse(date, DateFormats.DAY_MONTH_YEAR, new Date());
const parseDayMonthYearShort = (date: string) =>
  parse(date, DateFormats.DAY_MONTH_YEAR_SHORT, new Date());
const parseOther = (date: string) => new Date(date);

export const parseDate = (date?: string) => {
  if (!date) {
    return;
  }
  if (isValid(parseDayMonthYearShort(date))) {
    return parseDayMonthYearShort(date);
  }
  if (isValid(parseDayMonthYear(date))) {
    return parseDayMonthYear(date);
  }
  if (isValid(parseISO(date))) {
    return parseISO(date);
  }
  if (isValid(parseOther(date))) {
    return parseOther(date);
  }
};
