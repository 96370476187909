import type { OrderServiceTypes } from '@/features/activity/services';

export const sortOrdersByDate = (orders?: OrderServiceTypes.TOrder[]) => {
  return !orders || orders.length === 0
    ? undefined
    : orders.sort((a, b) => {
        const dateA = new Date(a.details.order.created_datetime);
        const dateB = new Date(b.details.order.created_datetime);
        return dateB.getTime() - dateA.getTime();
      });
};
