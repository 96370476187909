import PaymentIcon from '@/assets/images/payment-icon.svg';
import { PanelError } from '@/components';
import { AddIcon } from '@/components/icons';

import { LoadingCard } from '../loading-card';

import { Styled } from './styles';

interface IPanelCardProps {
  hasEmptyList?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  items?: React.ReactNode;
  onClickAdd?: () => void;
}

export const PanelCard = ({
  hasEmptyList,
  isError,
  isLoading,
  items,
  onClickAdd,
}: IPanelCardProps) => {
  let content = items;

  if (isLoading) {
    content = <LoadingCard />;
  } else if (isError) {
    content = <PanelError description="payment cards" title="Payment cards" />;
  } else if (hasEmptyList) {
    content = (
      <>
        <img alt="" src={PaymentIcon} />
        <Styled.PanelCardContent>
          <Styled.PanelCardTitle>Shop in store or online</Styled.PanelCardTitle>
          <Styled.PanelCardDescription>
            Pay in store with QR or checkout online using your payment cards
          </Styled.PanelCardDescription>
          <Styled.PanelCardButton
            level="tertiary"
            onClick={onClickAdd}
            size="small"
            variant="neutral"
          >
            <Styled.ButtonWrapper>
              <AddIcon height={16} width={16} />
              Add Payment card
            </Styled.ButtonWrapper>
          </Styled.PanelCardButton>
        </Styled.PanelCardContent>
      </>
    );
  }

  return (
    <Styled.PanelCardWrapper>
      <Styled.PanelCardLabelWrapper>
        <h2>My Cards</h2>
        {!hasEmptyList && (
          <Styled.AddBtn
            aria-label="Add Payment card"
            level="tertiary"
            onClick={onClickAdd}
            variant="branded"
          >
            <Styled.ButtonWrapper>
              <AddIcon /> Add
            </Styled.ButtonWrapper>
          </Styled.AddBtn>
        )}
      </Styled.PanelCardLabelWrapper>
      <Styled.PanelCardContentContainer>
        {content}
      </Styled.PanelCardContentContainer>
    </Styled.PanelCardWrapper>
  );
};
