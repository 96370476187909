import styled from 'styled-components';

const GhostButton = styled.button`
  background: none;
  border: none;
  color: var(--colors-onSurfaceA);
  cursor: pointer;
  display: flex;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-bold);
  gap: var(--space-xs);
  margin-left: auto;
  margin-right: var(--space-l);
  padding: 0;
  text-decoration-line: underline;
  text-indent: 0;
`;

const MerchantDetails = styled.div`
  align-items: center;
  color: var(--colors-onSurfaceA);
  display: flex;
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  gap: var(--space-m);
  line-height: var(--lineHeights-half);
  text-align: left;
`;

const OrderTable = styled.table`
  background: var(--colors-surfaceLowest);
  border-collapse: collapse;
  border-radius: var(--radii-xl);
  width: 100%;

  td:last-child {
    width: 100%;
  }
`;

const OrderTableCell = styled.td<{ $color?: 'onSurfaceB'; $fontSize?: '14' }>`
  color: ${(props) =>
    props.$color
      ? `var(--colors-${props.$color})`
      : 'var(--colors-onSurfaceA)'};
  font-size: ${(props) =>
    props.$fontSize
      ? `var(--fontSizes-${props.$fontSize})`
      : 'var(--fontSizes-16)'};
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
  overflow: hidden;
  padding: var(--space-m) var(--space-l);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OrderTableFoot = styled.tfoot`
  height: var(--space-xl);
`;

const OrderTableHeader = styled.thead`
  border-bottom: 2px solid var(--colors-onSurfaceD);
`;

const OrderTableHeaderCell = styled.th`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
  padding: var(--space-m) var(--space-l);
  text-align: left;
`;

const OrderTableRow = styled.tr<{ $hasRefund?: boolean }>`
  border-bottom: ${(props) =>
    props.$hasRefund ? 'none' : '1px solid var(--colors-onSurfaceD)'};
`;

const OrderTableSubCell = styled(OrderTableCell)`
  color: var(--colors-onSurfaceC);
  font-size: var(--fontSizes-14);
  padding-top: 0;
`;

const OrderTableSubRow = styled.tr<{ $isLast?: boolean }>`
  border-bottom: ${(props) =>
    props.$isLast && '1px solid var(--colors-onSurfaceD)'};
`;

export const Styled = {
  GhostButton,
  MerchantDetails,
  OrderTable,
  OrderTableCell,
  OrderTableFoot,
  OrderTableHeader,
  OrderTableHeaderCell,
  OrderTableRow,
  OrderTableSubCell,
  OrderTableSubRow,
};
