import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring/enums';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { ProfileTypes } from '@/features/profile';

export const useGetProfile = () => {
  const auth = useAuth();

  const getProfileRequest = async () =>
    await request<ProfileTypes.TUser>(
      `${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${
        endpoints.profile.PROFILE
      }`,
      auth,
      { method: RequestMethod.GET },
      {
        priority: EventPriority.P1,
        requestKey: ProfileEndpointKeys.PROFILE,
        transaction: profileTransactionKeys.profile,
      }
    );

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: getProfileRequest,
    queryKey: profileQueryKeys.profile,
    throwOnError: true,
  });
};
