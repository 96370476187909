export function validateCardExpiryDate(value: string, separator = '/') {
  const expiryDateArray = value.split(separator);
  const expiryMonth = expiryDateArray[0];
  const expiryYear = expiryDateArray[1];
  const isExpiryYearCurrentYear = isCurrentYear(expiryYear);

  return (
    validateExpiryMonth(expiryMonth, isExpiryYearCurrentYear) &&
    validateExpiryYear(expiryYear)
  );
}

export function validateExpiryMonth(value: string, isCurrentYear: boolean) {
  const EXPIRY_MONTH_REGEX = /^\d{2}$/;
  const currentMonth = new Date().getMonth() + 1;
  const month = Number.parseInt(value, 10);

  const isValidMonthValue = EXPIRY_MONTH_REGEX.test(value);
  const isWithinExpiryMonthRange = month >= currentMonth;
  const isWithinTwelveMonths = month > 0 && month < 13;

  return isCurrentYear
    ? isValidMonthValue && isWithinTwelveMonths && isWithinExpiryMonthRange
    : isValidMonthValue && isWithinTwelveMonths;
}

export function validateExpiryYear(value: string) {
  const EXPIRY_YEAR_REGEX = /^\d{4}$|^\d{2}$/;
  const isValidYear = EXPIRY_YEAR_REGEX.test(value);
  const currentYear = new Date().getFullYear();
  const dateValue =
    value.length === 4
      ? Number.parseInt(value, 10)
      : Number.parseInt(`20${value}`, 10);

  return isValidYear && dateValue >= currentYear;
}

export function isCurrentYear(year: string) {
  const currentYear = new Date().getFullYear();
  const yearPrefix = Number(String(currentYear).slice(0, 2));
  const yearString = year.length === 2 ? yearPrefix + year : year;
  const yearValue = Number.parseInt(yearString, 10);

  return currentYear === yearValue;
}
