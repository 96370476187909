export namespace PaymentMethodEnums {
  export enum BankCardFunding {
    CREDIT = 'credit',
    DEBIT = 'debit',
  }

  export enum BankCardStatus {
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    VALIDATED = 'VALIDATED',
    VERIFIED = 'VERIFIED',
  }

  export enum BankCardSchemes {
    AMEX = 'amex',
    DEFAULT = 'default',
    EFTPOS = 'eftpos',
    MASTERCARD = 'mastercard',
    VISA = 'visa',
  }

  export enum BarcodeDisplayFormat {
    AZTEC = 'AZTEC',
    CODABAR = 'CODABAR',
    CODE_128 = 'CODE_128',
    CODE_39 = 'CODE_39',
    CODE_93 = 'CODE_93',
    DATA_MATRIX = 'DATA_MATRIX',
    EAN_13 = 'EAN_13',
    EAN_8 = 'EAN_8',
    PDF_417 = 'PDF_417',
    QR = 'QR',
    TEXT = 'TEXT',
    UPC_A = 'UPC_A',
    UPC_E = 'UPC_E',
  }

  export enum CardAccountType {
    CHEQUE = 'cheque',
    CREDIT = 'credit',
    DEFAULT = 'default',
    SAVINGS = 'savings',
  }

  export enum CardProgramType {
    CUSTOM = 'custom',
    KNOWN = 'known',
  }

  export enum EntityType {
    BANK_CARD = 'BankCard',
    GIFT_CARD = 'GiftCard',
    LOYALTY_CARD = 'LoyaltyCard',
  }

  export enum LoyaltyCardChannel {
    BAR_CODE = 'bar_code',
    FLYBUYS = 'flybuys',
  }

  export enum LoyaltyCardStatus {
    LINKED = 'LINKED',
    PENDING_LINK = 'PENDING_LINK',
    STATIC_DATA = 'STATIC_DATA',
  }

  export enum PaymentMethod {
    BANK_CARD = 'bank_card',
    GIFT_CARD = 'gift_card',
    LOYALTY_CARD = 'loyalty_card',
  }

  export enum PaymentMethodType {
    CARD = 'card',
    GIFT_CARD = 'giftCard',
    LOYALTY = 'loyalty',
  }

  export enum MpgsPostMessage {
    AUTH_FAIL = 'auth-fail',
    AUTH_SUCCESS = 'auth-success',
  }
}
