import { Button, Title } from '@/components';
import { FilterIcon } from '@/components/icons';
import { Search } from '@/components/search';
import { useBreakpoints } from '@/hooks';

import { SearchOptions } from './enums';
import { Styled } from './styles';

import type { TSearchQuery } from './types';

type TActivityHeader = {
  filterNumber?: string;
  initialSearchQuery?: TSearchQuery;
  onClearSearch?: () => void;
  onOpenFilters?: () => void;
  onSearch?: (query: TSearchQuery) => void;
  orderSubhead?: string;
};

export const ActivityHeader = ({
  filterNumber,
  initialSearchQuery,
  onClearSearch,
  onOpenFilters,
  onSearch,
  orderSubhead,
}: TActivityHeader) => {
  const breakpoints = useBreakpoints();
  const handleOpenFilters = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    onOpenFilters?.();
  };
  return (
    <Styled.HeaderDetailsWrapping>
      <Styled.HeaderRow>
        <Title spacing={false} title="Activity" />
        {!breakpoints.lg && (
          <Styled.SubHeading role="alert">{orderSubhead}</Styled.SubHeading>
        )}
        <Styled.SearchWrapper>
          <Search
            $labelLeft={breakpoints.sm && !breakpoints.lg}
            $size="small"
            handleClear={onClearSearch}
            handleSearch={onSearch}
            helper="Search by Merchant Name"
            initialQuery={initialSearchQuery}
            options={SearchOptions}
          >
            <Button
              level="secondary"
              size="small"
              style={breakpoints.sm ? { maxWidth: '5.5rem' } : {}}
              type="submit"
              variant="neutral"
            >
              Search
            </Button>
            <Button
              level="secondary"
              onClick={handleOpenFilters}
              size="small"
              style={
                breakpoints.sm
                  ? { gap: 'var(--space-2xs)', maxWidth: '7.25rem' }
                  : { gap: 'var(--space-2xs)' }
              }
              variant="neutral"
            >
              <FilterIcon />
              Filters {filterNumber}
            </Button>
          </Search>
        </Styled.SearchWrapper>
      </Styled.HeaderRow>
      {breakpoints.lg && (
        <Styled.SubHeading role="alert">{orderSubhead}</Styled.SubHeading>
      )}
    </Styled.HeaderDetailsWrapping>
  );
};
