import type { IIcon } from './typings';
export const ExternalLinkIcon = ({
  color = 'currentColor',
  height = 20,
  width = 20,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 20 21"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.22856 15.3651C5.52827 15.6577 6.00849 15.652 6.30115 15.3523L13.4236 8.05827L13.483 13.0532C13.488 13.4721 13.8316 13.8076 14.2505 13.8026C14.6694 13.7976 15.0049 13.454 14.9999 13.0351L14.9187 6.20919C14.9137 5.79031 14.5701 5.45479 14.1512 5.45977L7.32526 5.54101C6.90639 5.54599 6.57086 5.8896 6.57585 6.30847C6.58083 6.72735 6.92444 7.06287 7.34331 7.05789L12.3382 6.99844L5.21579 14.2925C4.92313 14.5922 4.92884 15.0724 5.22856 15.3651Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
