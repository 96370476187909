import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { ProfileTypes } from '@/features/profile';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';
import type { AuthContextProps } from 'react-oidc-context';

export function useUpdateProfile(
  options?: UseMutationOptions<
    ProfileTypes.TUser,
    TBaasError,
    ProfileTypes.TUpdateUserProfileRequestPayload,
    unknown
  >
) {
  const auth = useAuth();

  const updateProfileRequest = async (
    auth: AuthContextProps,
    payload: ProfileTypes.TUpdateUserProfileRequestPayload
  ) =>
    await request<ProfileTypes.TUser>(
      endpoints.profile.PROFILE,
      auth,
      {
        data: payload,
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P2,
        requestKey: ProfileEndpointKeys.PROFILE_UPDATE,
        transaction: profileTransactionKeys.profileUpdate,
      }
    );

  return useMutation({
    mutationFn: (payload) => updateProfileRequest(auth, payload),
    mutationKey: profileQueryKeys.profileUpdate,
    ...options,
  });
}
