import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import {
  type PaymentMethodTypes,
  paymentMethodQueryKeys,
} from '@/features/card-management';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';

import type { MutationOptions } from '@tanstack/react-query';

export function useUpdateDefaultCard(cardId: string, options: MutationOptions) {
  const auth = useAuth();

  const setDefaultCardRequest = async () =>
    await request<PaymentMethodTypes.TBankCard>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: {
          is_default_pay: true,
        },
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.CARD_UPDATE_DEFAULT,
        transaction: paymentMethodTransactionKeys.cardUpdateDefault,
      }
    );

  return useMutation({
    mutationFn: setDefaultCardRequest,
    mutationKey: paymentMethodQueryKeys.updateDefaultCard,
    ...options,
  });
}
