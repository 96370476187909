import type { IIcon } from './typings';

export const RefundIcon = ({ color = '#404040' }: IIcon) => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.95807 4.74557C4.89533 4.74557 4.83903 4.76969 4.80059 4.80659C4.76283 4.84284 4.74557 4.88758 4.74557 4.92974V10.3697C4.74557 10.4119 4.76283 10.4566 4.80059 10.4929C4.83903 10.5298 4.89533 10.5539 4.95807 10.5539H12.2256L10.9897 9.36745C10.7921 9.17781 10.7857 8.86393 10.9754 8.66638C11.165 8.46884 11.4789 8.46243 11.6765 8.65207L13.8014 10.692C13.8988 10.7855 13.9539 10.9147 13.9539 11.0497C13.9539 11.1847 13.8989 11.3139 13.8015 11.4074L11.6765 13.4474C11.4789 13.6371 11.165 13.6307 10.9754 13.4331C10.7857 13.2356 10.7921 12.9217 10.9897 12.7321L12.2256 11.5456H4.95807C4.64509 11.5456 4.34106 11.4264 4.11383 11.2082C3.8859 10.9894 3.75391 10.6883 3.75391 10.3697V4.92974C3.75391 4.6112 3.8859 4.31002 4.11383 4.09122C4.34106 3.87307 4.64509 3.75391 4.95807 3.75391H7.79141C8.06525 3.75391 8.28724 3.9759 8.28724 4.24974C8.28724 4.52358 8.06525 4.74557 7.79141 4.74557H4.95807Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
