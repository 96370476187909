import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--space-xl);
  gap: var(--space-l);
`;

export const Styled = {
  Container,
  Footer,
};
