import { Breakpoints } from '@/config/breakpoints';
import { useWindowSize } from '@/hooks';

import { PowerIcon } from '../icons/power-icon';

import { NavItem } from './nav-item';
import { Styled } from './styles';

import type { INavItemProps } from './nav-item';

interface ISideNavProps {
  footerItems?: INavItemProps[];
  items?: INavItemProps[];
  onLogout?: () => void;
}

export const SideNav = ({ footerItems, items, onLogout }: ISideNavProps) => {
  const windowSize = useWindowSize();
  const isMobile = (windowSize?.width || 0) < Breakpoints.sm;

  return (
    <Styled.SideNavRoot id={isMobile ? 'mobile-side-nav' : 'side-nav'}>
      <Styled.SideNavList>
        {!!items?.length &&
          items.map((item, index) => <NavItem key={index} {...item} />)}
      </Styled.SideNavList>
      {footerItems && <Styled.SideNavSeparator />}
      {footerItems && (
        <Styled.SideNavList className="footer">
          <Styled.SideNavItem $mobileOnly>
            <Styled.SideNavButton onClick={onLogout}>
              <PowerIcon />
              <Styled.SideNavIconSpacer />
              Log out
            </Styled.SideNavButton>
          </Styled.SideNavItem>
          {footerItems.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
        </Styled.SideNavList>
      )}
    </Styled.SideNavRoot>
  );
};
