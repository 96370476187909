import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

interface IMobileVerifyRequest {
  mobile_number: string;
  verification_code: string;
}

export function useMobileVerify(
  options?: UseMutationOptions<
    unknown,
    TBaasError,
    IMobileVerifyRequest,
    unknown
  >
) {
  const auth = useAuth();

  const mobileVerifyRequest = async (payload: IMobileVerifyRequest) =>
    await request(
      endpoints.profile.PROFILE_MOBILE_VERIFY,
      auth,
      {
        data: payload,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: ProfileEndpointKeys.MOBILE_VERIFY,
        transaction: profileTransactionKeys.mobileVerify,
      }
    );

  return useMutation<unknown, TBaasError, IMobileVerifyRequest, unknown>({
    mutationFn: mobileVerifyRequest,
    mutationKey: profileQueryKeys.mobileVerify,
    ...options,
  });
}
