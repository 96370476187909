import type { IIcon } from './typings';
export const CheckIcon = ({
  color = 'currentColor',
  height = 80,
  width = 80,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="black" height={height} rx="40" width={width} />
      <path
        clipRule="evenodd"
        d="M58.7618 27.0163C59.5514 27.8061 59.5513 29.0865 58.7615 29.8762L35.2376 53.3941C34.4479 54.1836 33.1676 54.1835 32.378 53.3939L21.2351 42.251C20.4454 41.4613 20.4454 40.1809 21.2351 39.3912C22.0248 38.6015 23.3052 38.6015 24.095 39.3912L33.8081 49.1043L55.902 27.0159C56.6918 26.2263 57.9722 26.2265 58.7618 27.0163Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
