import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: var(--space-m);
  align-items: center;
`;

const Icon = styled.div`
  color: var(--colors-onSurfaceB);
  flex-grow: 0;
  line-height: var(--lineHeights-normal);
  padding-top: var(--space-2xs);
`;

const Paragraph = styled.p`
  color: var(--colors-brand1Highest);
  cursor: pointer;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
  margin: 0;
  text-align: left;
  text-decoration: underline;
`;

const ActionDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: left;
`;
const ActionDialogTitle = styled.h2`
  font-size: var(--fontSizes-24);
  font-weight: var(--fontWeights-bold);
  color: var(--colors-onSurfaceA);
  margin: 0;
  padding: 0;
`;
const ActionDialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-style: normal;
  line-height: 1.2;
`;

const ActionDialogDescription = styled.div`
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-onSurfaceB);
  margin: 0;
  padding: 0;
`;

const TriggerButton = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Styled = {
  ActionDialogDescription,
  ActionDialogHeader,
  ActionDialogTitle,
  ActionDialogWrapper,
  Container,
  Icon,
  Paragraph,
  TriggerButton,
};
