import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { paymentMethodQueryKeys } from '@/features/card-management';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { useHasUserConsentedTerms } from '@/features/profile';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';

import type { PaymentMethodTypes } from '@/features/card-management';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetBankCards(
  options?: UseQueryOptions<
    PaymentMethodTypes.TPaymentMethodsBankCardsResponse,
    TBaasErrorResponse
  >
) {
  const auth = useAuth();
  const consentedTerms = useHasUserConsentedTerms();

  const getCardsRequest = async () =>
    await request<PaymentMethodTypes.TPaymentMethodsBankCardsResponse>(
      `${endpoints.BANK_CARDS}`,
      auth,
      { method: RequestMethod.GET },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARDS,
        transaction: paymentMethodTransactionKeys.bankCards,
      }
    );

  return useQuery({
    enabled: auth.isAuthenticated && consentedTerms,
    queryFn: getCardsRequest,
    queryKey: paymentMethodQueryKeys.bankCards,
    ...options,
  });
}
