import styled, { css } from 'styled-components';

import type { TAvatarSize } from './avatar';

export const AvatarRoot = styled.div<{
  $active?: boolean;
  $size: TAvatarSize;
}>`
  align-items: center;
  background-color: var(--colors-brand1Lowest);
  border-radius: 99999rem;
  color: var(--colors-onBrand1Low);
  display: flex;
  justify-content: center;

  ${({ $active }) =>
    $active &&
    css`
      outline: 4px solid var(--colors-brand1Low);
    `}

  ${({ $size }) => {
    switch ($size) {
      case 'sm': {
        return css`
          width: 2rem;
          height: 2rem;
        `;
      }
      case 'lg': {
        return css`
          width: 5rem;
          height: 5rem;
        `;
      }
      case 'xl': {
        return css`
          width: 7.5rem;
          height: 7.5rem;
        `;
      }
      default: {
        return css`
          width: 3rem;
          height: 3rem;
        `;
      }
    }
  }}
`;

export const AvatarImage = styled.img<{ $loading: boolean }>`
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${({ $loading }) =>
    $loading &&
    css`
      display: none;
    `}
`;

export const AvatarFallback = styled.div<{ $size: TAvatarSize }>`
  display: flex;
  align-items: center;

  ${({ $size }) => {
    switch ($size) {
      case 'sm': {
        return css`
          font-size: var(--fontSizes-12);
        `;
      }
      case 'lg': {
        return css`
          font-size: var(--fontSizes-24);
        `;
      }
      case 'xl': {
        return css`
          font-size: var(--fontSizes-32);
        `;
      }
      default: {
        return css`
          font-size: var(--fontSizes-16);
        `;
      }
    }
  }}
`;

export const Styled = {
  AvatarFallback,
  AvatarImage,
  AvatarRoot,
};
