import { theme } from '@/config/themes/flypay';

import type { IIcon } from './typings';

export const TickIcon = ({
  color = theme.styling.colors.onSurfaceB.value,
  height = 16,
  width = 16,
}: IIcon) => (
  <svg
    fill="none"
    height={`${height}`}
    viewBox="0 0 16 16"
    width={`${width}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.3297 5.00294C12.5119 5.1852 12.5119 5.48068 12.3296 5.6629L6.90106 11.0901C6.71881 11.2723 6.42336 11.2723 6.24113 11.0901L3.6697 8.51864C3.48746 8.3364 3.48746 8.04092 3.6697 7.85868C3.85195 7.67643 4.14743 7.67643 4.32967 7.85868L6.57116 10.1002L11.6697 5.00285C11.852 4.82063 12.1475 4.82067 12.3297 5.00294Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
