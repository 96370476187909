import type { IIcon } from './typings';

export const TrashIcon = ({
  color = '#CD4014',
  height = 14,
  width = 10,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.97563 0C3.73649 0 3.54264 0.193856 3.54264 0.43299V2.02436H0.43299C0.193856 2.02436 0 2.21821 0 2.45735C0 2.69648 0.193856 2.89034 0.43299 2.89034H3.97219C3.97334 2.89035 3.97448 2.89035 3.97563 2.89035C3.97677 2.89035 3.97792 2.89035 3.97906 2.89034H8.02093C8.02207 2.89035 8.02322 2.89035 8.02436 2.89035C8.02551 2.89035 8.02666 2.89035 8.0278 2.89034H11.567C11.8061 2.89034 12 2.69648 12 2.45735C12 2.21821 11.8061 2.02436 11.567 2.02436H8.45735V0.43299C8.45735 0.193856 8.2635 0 8.02436 0H3.97563ZM7.59137 2.02436V0.865981H4.40862V2.02436H7.59137ZM2.38426 4.48174C2.38426 4.24261 2.19041 4.04875 1.95127 4.04875C1.71214 4.04875 1.51828 4.24261 1.51828 4.48174V10.5549C1.51828 10.9381 1.67054 11.3057 1.94157 11.5767C2.21259 11.8478 2.58017 12 2.96346 12H9.03656C9.41985 12 9.78743 11.8478 10.0585 11.5767C10.3295 11.3057 10.4817 10.9381 10.4817 10.5549V4.48174C10.4817 4.24261 10.2879 4.04875 10.0487 4.04875C9.80961 4.04875 9.61576 4.24261 9.61576 4.48174V10.5549C9.61576 10.7085 9.55473 10.8558 9.44611 10.9644C9.33749 11.073 9.19017 11.1341 9.03656 11.1341H2.96346C2.80985 11.1341 2.66253 11.073 2.55391 10.9644C2.44529 10.8558 2.38426 10.7085 2.38426 10.5549V4.48174ZM6 5.56702C6.23913 5.56702 6.43299 5.76088 6.43299 6.00001V9.03657C6.43299 9.2757 6.23913 9.46956 6 9.46956C5.76087 9.46956 5.56701 9.2757 5.56701 9.03657V6.00001C5.56701 5.76088 5.76087 5.56702 6 5.56702ZM4.40862 6.00001C4.40862 5.76088 4.21476 5.56702 3.97563 5.56702C3.73649 5.56702 3.54264 5.76088 3.54264 6.00001V9.03657C3.54264 9.2757 3.73649 9.46956 3.97563 9.46956C4.21476 9.46956 4.40862 9.2757 4.40862 9.03657V6.00001ZM8.02437 5.56702C8.26351 5.56702 8.45737 5.76088 8.45737 6.00001V9.03657C8.45737 9.2757 8.26351 9.46956 8.02437 9.46956C7.78524 9.46956 7.59138 9.2757 7.59138 9.03657V6.00001C7.59138 5.76088 7.78524 5.56702 8.02437 5.56702Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
