import styled, { css } from 'styled-components';

import { Button } from '@/components';

const StyledButton = styled(Button)<{ $loading?: boolean }>`
  border-radius: var(--radii-md);
  display: flex;
  gap: var(--space-xs);
  ${({ $loading }) =>
    $loading &&
    css`
      cursor: default;
    `}
`;

export const Styled = {
  StyledButton,
};
