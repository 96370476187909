import styled from 'styled-components';

const DateTitle = styled.div`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-14);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-normal);
`;

const MobileOrderListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const MobileOrderListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const MobileOrderWrapper = styled.div`
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-md);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: var(--space-m);

  &:hover {
    background: var(--colors-surfaceHigh);
  }
`;

const Text = styled.p`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
  margin: 0;
`;

const IdText = styled(Text)`
  font-size: var(--fontSizes-12);
  color: var(--colors-onSurfaceB);
  text-transform: uppercase;
`;

const ValueText = styled(Text)`
  margin-left: auto;
`;

const MobileOrderHeader = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-xs);
`;

const HeaderNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileOrderDivider = styled.div`
  background-color: var(--colors-onSurfaceE);
  height: 1px;
  margin: 0.375rem 0;
  width: 100%;
`;

const MobilePaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const MobilePaymentItem = styled.div`
  display: flex;
  gap: var(--space-m);
`;

const MobilePaymentInfoText = styled(Text)`
  color: var(--colors-onSurfaceC);
  display: block;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
`;

const MobilePaymentValueText = styled(MobilePaymentInfoText)`
  margin-left: auto;
`;

export const Styled = {
  DateTitle,
  HeaderNameWrapper,
  IdText,
  MobileOrderDivider,
  MobileOrderHeader,
  MobileOrderListItemsWrapper,
  MobileOrderListWrapper,
  MobileOrderWrapper,
  MobilePaymentInfoText,
  MobilePaymentInfoWrapper,
  MobilePaymentItem,
  MobilePaymentValueText,
  Text,
  ValueText,
};
