export interface IFlybuysAuth {
  authorize: {
    redirect_uri: string;
    response_type: 'code';
  };
  token: {
    grant_type: 'authorization_code';
    redirect_uri: string;
  };
}

export const auth: IFlybuysAuth = {
  authorize: {
    redirect_uri: import.meta.env.VITE_REACT_APP_FLYBUYS_AUTH_REDIRECT_URI,
    response_type: 'code',
  },
  token: {
    grant_type: 'authorization_code',
    redirect_uri: import.meta.env.VITE_REACT_APP_FLYBUYS_AUTH_REDIRECT_URI,
  },
};
