import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './query-keys';

import type { PaymentMethodEnums } from '@/features/card-management';
import type { TBaasError } from '@/services/errors';
import type { MutationOptions } from '@tanstack/react-query';

export type TUseAddLoyaltyCardRequest = {
  data: {
    bar_code: string;
    bar_code_format: PaymentMethodEnums.BarcodeDisplayFormat;
    pin?: string;
  };
  is_default?: boolean;
  program: {
    id: string;
    type: 'known';
  };
};

export function useAddLoyaltyCard(
  options: MutationOptions<
    unknown,
    TBaasError,
    TUseAddLoyaltyCardRequest,
    unknown
  >
) {
  const auth = useAuth();

  const addLoyaltyCardRequest = async (payload: TUseAddLoyaltyCardRequest) => {
    return await request<TUseAddLoyaltyCardRequest>(
      endpoints.LOYALTY_CARDS,
      auth,
      {
        data: payload,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: LoyaltyEndpointKeys.LOYALTY_CARD_ADD,
        transaction: loyaltyTransactionKeys.loyaltyCardAdd,
      }
    );
  };

  return useMutation({
    mutationFn: addLoyaltyCardRequest,
    mutationKey: loyaltyQueryKeys.loyaltyCardAdd,
    ...options,
  });
}
