import React from 'react';

import { ErrorIcon } from '../icons';

import { Styled } from './styles';

import type { ComponentPropsWithRef, PropsWithChildren } from 'react';

interface ICheckbox extends ComponentPropsWithRef<'input'> {
  error?: string;
}
type Ref = HTMLInputElement;

export const Checkbox = React.forwardRef<Ref, PropsWithChildren<ICheckbox>>(
  ({ children, defaultChecked, disabled, error, id, name, ...props }, ref) => (
    <Styled.Wrapper>
      <Styled.Container $disabled={disabled}>
        {children}
        <Styled.Input
          {...props}
          defaultChecked={!!defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          ref={ref}
        />
        <Styled.Checkmark $disabled={disabled} />
      </Styled.Container>
      {!!error && (
        <Styled.ErrorWrapper>
          <ErrorIcon height={16} width={16} />
          <Styled.ErrorMessage>{error}</Styled.ErrorMessage>
        </Styled.ErrorWrapper>
      )}
    </Styled.Wrapper>
  )
);

Checkbox.displayName = 'Checkbox';
