export const genericContent = {
  BACK_TO_APP: 'Back to flypay.com.au',
  CONTACT_SUPPORT: 'contact flypay Customer Care',
  ERROR_GENERIC_TITLE: 'Hmm, that didn’t work',
  LOG_OUT_CANCEl: 'No, stay logged in',
  LOG_OUT_CONFIRM: 'Yes, log out',
  LOG_OUT_TITLE: 'Are you sure you want to log out?',
  PAGE_NOT_FOUND_CONTACT: 'No luck?',
  PAGE_NOT_FOUND_MESSAGE:
    "The page you're looking for doesn't seem to be here. Check the URL or try refreshing the page.",
  PAGE_NOT_FOUND_TITLE: 'Where did that page go?',
  SKIP_CONTENT: 'Skip to content',
  TRY_AGAIN: (what: string) => `Try ${what} again or`,
};
