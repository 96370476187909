import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

interface IUnsubscribeRequest {
  user_id: string;
  wallet_id: string;
}

export function useMarketingUnsubscribe(
  options?: UseMutationOptions<
    unknown,
    TBaasError,
    IUnsubscribeRequest,
    unknown
  >
) {
  const auth = useAuth();

  const marketingUnsubscribe = async (payload: IUnsubscribeRequest) =>
    await request(
      `${
        endpoints.profile.PROFILE_MARKETING_UNSUBSCRIBE
      }?user_id=${encodeURIComponent(payload.user_id)}&wallet_id=${
        payload.wallet_id
      }`,
      auth,
      {
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: ProfileEndpointKeys.MARKETING_UNSUBSCRIBE,
        transaction: profileTransactionKeys.marketingUnsubscribe,
      }
    );

  return useMutation({
    mutationFn: marketingUnsubscribe,
    mutationKey: profileQueryKeys.marketingSubscribe,
    ...options,
  });
}
