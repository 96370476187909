import {
  MAX_CHARS_ADDRESS_NICKNAME,
  MIN_CHARS_DEFAULT,
} from '@/features/profile';

export function validateAddressNickname(value: string) {
  return (
    value.length >= MIN_CHARS_DEFAULT &&
    value.length <= MAX_CHARS_ADDRESS_NICKNAME
  );
}
