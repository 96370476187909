import { LoyaltyCard } from '@b707/ponyta';
import { useCallback, useEffect, useState } from 'react';

import {
  ConfirmationDialog,
  DualActionDialog,
  ErrorDialog,
  Spinner,
} from '@/components';
import {
  AddIcon,
  ExclamationMarkTriangleIcon,
  ExternalLinkIcon,
} from '@/components/icons';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_FLYBUYS_LINKING,
  ERROR_FLYBUYS_LINKING_ACCOUNT_ERROR,
  ERROR_FLYBUYS_LINKING_ALREADY_LINKED,
  ERROR_FLYBUYS_TOKEN_EXPIRED,
  ERROR_FLYBUYS_UNLINKING,
  ERROR_TITLE_FLYBUYS_LINKING,
  ERROR_TITLE_FLYBUYS_LINKING_ACCOUNT_ERROR,
  ERROR_TITLE_FLYBUYS_LINKING_ALREADY_LINKED,
  ERROR_TITLE_FLYBUYS_TOKEN_EXPIRED,
  ERROR_TITLE_FLYBUYS_UNLINKING,
} from '@/config/language/errors';
import { SessionStorage } from '@/config/storage/session';
import {
  FLYBUYS_FLOW_TYPES,
  getFlybuysAuthUrl,
  useDeleteLoyaltyCard,
  useFlybuysCompleteLink,
  useFlybuysGetTokens,
  useFlybuysInitiateLink,
  useFlybuysUpdateCredentials,
} from '@/features/loyalty';
import { useAnalytics } from '@/hooks';
import { BaasErrors } from '@/services';
import { generateCodeChallenge } from '@/utils/auth';

import { LOYALTY_SCHEME_NAMES } from '../../constants';
import { Styled } from '../../styles';

import type { PaymentMethodTypes } from '@/features/card-management';

// timeout is still run in the background even in flybuys, 3s is generous enough for link to open
const FLYBUYS_LINKING_TIMEOUT = 3000;

interface IFlybuysNotLinked {
  hasExpiredRefreshToken: boolean;
  linkedCard?: PaymentMethodTypes.TLoyaltyCard;
  pendingLinkCard?: PaymentMethodTypes.TLoyaltyCard;
  reloadLoyaltyCardList: () => void;
}

export const FlybuysNotLinked = ({
  hasExpiredRefreshToken,
  linkedCard,
  pendingLinkCard,
  reloadLoyaltyCardList,
}: IFlybuysNotLinked) => {
  const { track } = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isAuthErrorWithCard, setIsAuthErrorWithCard] = useState(false);
  const [isCardholderLinkedError, setIsCardholderLinkedError] = useState(false);
  const [isUnlinkError, setIsUnlinkError] = useState(false);
  const [isUnlinkLoading, setIsUnlinkLoading] = useState(false);
  const [isExpiredTokenDialogOpen, setIsExpiredTokenDialogOpen] =
    useState(false);

  const storedCode = sessionStorage.getItem(SessionStorage.FLYBUYS_CODE);
  const authError = sessionStorage.getItem(SessionStorage.FLYBUYS_ERROR_TYPE);

  const initiateLink = useFlybuysInitiateLink({
    onError: () => {
      setIsError(true);
      setIsLoading(false);
    },
    onSuccess: async ({ auth }) => {
      const { challenge, verifier } = await generateCodeChallenge();
      sessionStorage.setItem(SessionStorage.FLYBUYS_VERIFIER, verifier);
      sessionStorage.setItem(
        SessionStorage.FLYBUYS_AUTH_CONFIG,
        JSON.stringify(auth)
      );
      const flybuysUrl = await getFlybuysAuthUrl(auth, challenge);
      if (!flybuysUrl) {
        setIsError(true);
        setIsLoading(false);
      } else {
        window.location.assign(flybuysUrl);
        setTimeout(() => {
          setIsLoading(false);
        }, FLYBUYS_LINKING_TIMEOUT);
      }
    },
  });

  const handleInitiateLink = useCallback(() => {
    setIsLoading(true);
    track('Link Loyalty Started', {
      scheme: LOYALTY_SCHEME_NAMES.FLYBUYS,
    });
    const flowType = hasExpiredRefreshToken
      ? FLYBUYS_FLOW_TYPES.REFRESH
      : FLYBUYS_FLOW_TYPES.LINK;
    sessionStorage.setItem(SessionStorage.FLYBUYS_FLOW_TYPE, flowType);
    initiateLink.mutate();
  }, [track, hasExpiredRefreshToken, initiateLink]);

  const completeLink = useFlybuysCompleteLink({
    onError: (error) => {
      const errorCode =
        error?.response?.data?.details?.error_number?.toString();
      if (errorCode === BaasErrors.FLYBUYS_SERVICE_USER_ALREADY_LINKED_ERROR) {
        setIsCardholderLinkedError(true);
      } else if (errorCode === BaasErrors.LOYALTY_ACCESS_TOKEN_MISMATCH) {
        setIsAuthErrorWithCard(true);
      } else {
        setIsError(true);
      }
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onSuccess: async () => {
      sessionStorage.removeItem(SessionStorage.FLYBUYS_FLOW_TYPE);
      reloadLoyaltyCardList();
    },
  });

  const updateCredentials = useFlybuysUpdateCredentials({
    onError: (error) => {
      const errorCode =
        error?.response?.data?.details?.error_number?.toString();
      if (errorCode === BaasErrors.FLYBUYS_SERVICE_USER_ALREADY_LINKED_ERROR) {
        setIsCardholderLinkedError(true);
      } else if (errorCode === BaasErrors.LOYALTY_ACCESS_TOKEN_MISMATCH) {
        setIsAuthErrorWithCard(true);
      } else {
        setIsError(true);
      }
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onSuccess: async () => {
      sessionStorage.removeItem(SessionStorage.FLYBUYS_FLOW_TYPE);
      reloadLoyaltyCardList();
    },
  });

  const getTokens = useFlybuysGetTokens({
    onError: () => {
      setIsLoading(false);
      setIsError(true);
    },
    onSuccess: ({ access_token, refresh_token }) => {
      if (access_token && refresh_token) {
        const flowType = sessionStorage.getItem(
          SessionStorage.FLYBUYS_FLOW_TYPE
        );
        if (flowType === FLYBUYS_FLOW_TYPES.REFRESH) {
          updateCredentials.mutate({ access_token, refresh_token });
        } else {
          completeLink.mutate({ access_token, refresh_token });
        }
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    },
  });

  const card = linkedCard || pendingLinkCard;
  const { mutate: deleteLoyaltyCard } = useDeleteLoyaltyCard(card?.id || '', {
    onError: () => {
      setIsUnlinkError(true);
    },
    onSettled: () => {
      setIsAuthErrorWithCard(false);
      setIsUnlinkLoading(false);
    },
    onSuccess: () => {
      reloadLoyaltyCardList();
    },
  });

  const handleCloseAuthError = () => {
    setIsAuthError(false);
  };

  const handleCloseAuthWithCardError = () => {
    setIsAuthErrorWithCard(false);
  };

  const handleUnlinkCard = () => {
    if (card) {
      setIsUnlinkLoading(true);
      deleteLoyaltyCard();
    } else {
      setIsAuthError(false);
    }
  };

  useEffect(() => {
    if (!!authError) {
      if (linkedCard || pendingLinkCard) {
        setIsAuthErrorWithCard(true);
      } else {
        setIsAuthError(true);
      }
      sessionStorage.removeItem(SessionStorage.FLYBUYS_ERROR_TYPE);
    } else if (!!storedCode) {
      setIsLoading(true);
      const auth_config_string = sessionStorage.getItem(
        SessionStorage.FLYBUYS_AUTH_CONFIG
      );
      const auth_config = auth_config_string
        ? JSON.parse(auth_config_string)
        : null;
      const code_verifier = sessionStorage.getItem(
        SessionStorage.FLYBUYS_VERIFIER
      );

      sessionStorage.removeItem(SessionStorage.FLYBUYS_AUTH_CONFIG);
      sessionStorage.removeItem(SessionStorage.FLYBUYS_CODE);
      sessionStorage.removeItem(SessionStorage.FLYBUYS_STATE);
      sessionStorage.removeItem(SessionStorage.FLYBUYS_VERIFIER);

      if (auth_config && code_verifier) {
        getTokens.mutate({ auth_config, code: storedCode, code_verifier });
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    }
  }, [
    authError,
    linkedCard,
    pendingLinkCard,
    getTokens,
    setIsAuthError,
    storedCode,
  ]);

  return (
    <Styled.LoyaltyCardItemContainer>
      <Styled.LoyaltyCardItemImage>
        <LoyaltyCard scheme="flybuys" size="md" />
      </Styled.LoyaltyCardItemImage>
      <Styled.LoyaltyInfoWrapper>
        {!!pendingLinkCard || hasExpiredRefreshToken ? (
          <>
            <h3>Flybuys</h3>
            {!storedCode && <p>Your wallet can’t connect to Flybuys.</p>}
            <Styled.LinkButton
              onClick={() => setIsExpiredTokenDialogOpen(true)}
            >
              {isLoading ? (
                <>
                  <Spinner size={16} /> <span>Linking</span>
                </>
              ) : (
                <>Resolve this issue</>
              )}
            </Styled.LinkButton>
          </>
        ) : (
          <>
            <h3>Flybuys</h3>
            <p>Collect points or redeem them when you checkout with flypay.</p>
            <Styled.LinkButton
              aria-label={!isLoading ? 'Link Flybuys' : 'Linking Flybuys'}
              onClick={handleInitiateLink}
            >
              {isLoading ? (
                <>
                  <Spinner size={16} /> <span>Linking</span>
                </>
              ) : (
                <>
                  <AddIcon height={16} width={16} /> <span>Link Flybuys</span>
                </>
              )}
            </Styled.LinkButton>
          </>
        )}
      </Styled.LoyaltyInfoWrapper>

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isError}
        message={ERROR_FLYBUYS_LINKING}
        onOpenChange={() => setIsError(false)}
        title={ERROR_TITLE_FLYBUYS_LINKING}
        tryWhat=""
      />

      <ErrorDialog
        buttonText={'Continue'}
        isOpen={isCardholderLinkedError}
        message={<ERROR_FLYBUYS_LINKING_ALREADY_LINKED />}
        onOpenChange={() => setIsCardholderLinkedError(false)}
        title={ERROR_TITLE_FLYBUYS_LINKING_ALREADY_LINKED}
        tryAgain={false}
      />

      <ConfirmationDialog
        acceptButtonText={isUnlinkLoading ? 'Loading' : 'Unlink Flybuys'}
        cancelButtonText="Go back"
        icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
        intro={<ERROR_FLYBUYS_LINKING_ACCOUNT_ERROR />}
        isDanger
        isOpen={isAuthErrorWithCard}
        loading={isUnlinkLoading}
        onClickAccept={handleUnlinkCard}
        onClickCancel={handleCloseAuthWithCardError}
        title={ERROR_TITLE_FLYBUYS_LINKING_ACCOUNT_ERROR}
      />

      <DualActionDialog
        buttonLeftText="Unlink Flybuys"
        buttonRightText={
          <>
            Log in to Flybuys <ExternalLinkIcon />
          </>
        }
        icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
        intro={<ERROR_FLYBUYS_TOKEN_EXPIRED />}
        isButtonLeftLoading={isUnlinkLoading}
        isOpen={isExpiredTokenDialogOpen}
        onClickLeft={handleUnlinkCard}
        onClickRight={handleInitiateLink}
        onOpenChange={() => setIsExpiredTokenDialogOpen(false)}
        title={ERROR_TITLE_FLYBUYS_TOKEN_EXPIRED}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isAuthError}
        message={ERROR_FLYBUYS_LINKING}
        onOpenChange={handleCloseAuthError}
        title={ERROR_TITLE_FLYBUYS_LINKING}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isUnlinkError}
        message={ERROR_FLYBUYS_UNLINKING}
        onOpenChange={() => setIsUnlinkError(false)}
        title={ERROR_TITLE_FLYBUYS_UNLINKING}
      />
    </Styled.LoyaltyCardItemContainer>
  );
};
