const merchant = {
  MERCHANT_LOGO: (merchantId: string) =>
    `/v1/payments/opapi/merchants/${merchantId}/logo`,
};
const qrOrders = {
  ORDERS: () => `/v1/orders`,
  ORDERS_BY_ID: (id: string) => `/v1/orders/${id}`,
};

export const endpoints = { merchant, qrOrders };
