import type { TSearchQuery } from '../types';

export function getOrderQuery(searchQuery: TSearchQuery) {
  if (searchQuery?.category === 'orderId') {
    return { order_reference: searchQuery?.query };
  }
  if (searchQuery?.category === 'merchantName') {
    return { payee_name: searchQuery?.query };
  }
  return undefined;
}
