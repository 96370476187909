export namespace DetailsFormEnums {
  export enum DetailsFormFieldNames {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    MARKETING_CONSENT = 'marketingConsent',
    TERMS_AND_CONDITIONS = 'termsAndConditions',
  }

  export enum DetailsFormLabels {
    FIRST_NAME = 'First name*',
    LAST_NAME = 'Last name*',
    MARKETING_CONSENT = 'I wish to receive flypay marketing communications (optional).',
  }

  export enum DetailsFormPlaceholders {
    FIRST_NAME = 'First name',
    LAST_NAME = 'Last name',
  }
}
