import * as Separator from '@radix-ui/react-separator';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const sideNavButtonStyles = css`
  text-decoration: none;
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-16);
  border-radius: var(--radii-lg);
  padding: var(--space-xs) var(--space-s);
  display: flex;
  align-items: center;
  width: inherit;
  color: var(--colors-onSurfaceB);

  &:focus {
    outline: 1px solid var(--colors-focusLight);
  }

  &:hover {
    color: var(--colors-onSurfaceA);
  }

  &.active {
    color: var(--colors-onSurfaceA);
    background: var(--colors-surfaceHigh);
    cursor: default;
  }
`;

const SideNavRoot = styled.nav`
  background-color: var(--colors-surfaceLowest);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 240px;
  overflow: auto;
  padding: var(--space-xl);
  position: relative;
  z-index: 1;
`;

const SideNavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  &.footer {
    margin-top: var(--space-m);
  }
`;

const SideNavItem = styled.li<{ $mobileOnly?: boolean }>`
  width: 100%;
  margin-bottom: var(--space-m);

  @media (width >= 768px) {
    display: ${(props) => (props.$mobileOnly ? 'none' : 'block')};
  }
`;

const SideNavLink = styled(NavLink)`
  ${sideNavButtonStyles}
`;

const SideNavButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${sideNavButtonStyles}
`;

const SideNavIcon = styled.img`
  height: 16px;
  width: 16px;
  object-fit: contain;
  object-position: center;
  margin-right: var(--space-s);
`;

const SideNavIconSpacer = styled.div`
  margin-right: var(--space-s);
`;

const SideNavSeparator = styled(Separator.Root)`
  margin: var(--space-m) 0;
  height: 1px;
  background-color: var(--colors-onSurfaceC);
  width: 100%;
`;

const SideNavLogoWrapper = styled.div`
  margin-top: var(--space-2xl);
  display: flex;
  flex-grow: 2;
  align-items: flex-end;
  padding: 0 var(--space-s);
`;

export const Styled = {
  SideNavButton,
  SideNavIcon,
  SideNavIconSpacer,
  SideNavItem,
  SideNavLink,
  SideNavList,
  SideNavLogoWrapper,
  SideNavRoot,
  SideNavSeparator,
};
