import React, { useState } from 'react';
import { FormProvider as HookFormProvider } from 'react-hook-form';

import type { CSSProperties, ReactNode } from 'react';
import type {
  FieldValues,
  FormProviderProps,
  UseFormReturn,
} from 'react-hook-form';

type TFormProps<Values extends FieldValues> = {
  children: ReactNode;
  config: UseFormReturn;
  isPreventSubmit?: boolean;
  onSubmitForm?: (
    values: Partial<Values>,
    e?: React.FormEvent<HTMLFormElement>
  ) => Promise<Partial<IOnSubmitResult> | void> | void;
  styles?: CSSProperties;
};

interface IOnSubmitResult {
  [prop: string]: unknown;
  FORM_ERROR?: string;
}

type TFormProviderProps = FormProviderProps & {
  submissonError?: string | null;
};

export const FORM_ERROR = 'FORM_ERROR';

const FormProvider = ({ children, ...props }: TFormProviderProps) => (
  <HookFormProvider {...props}>{children}</HookFormProvider>
);

export const Form = React.forwardRef<
  HTMLFormElement | null,
  TFormProps<FieldValues>
>(
  (
    { children, config, isPreventSubmit = false, onSubmitForm, styles },
    ref
  ) => {
    const [formSubmissionError, setFormSubmissionError] = useState<
      string | null
    >(null);

    const submitForm = async (values: FieldValues) => {
      const result = (await onSubmitForm?.(values)) || {};

      if (result.FORM_ERROR) {
        setFormSubmissionError(result.FORM_ERROR);
      }
    };

    return (
      <FormProvider {...config} submissonError={formSubmissionError}>
        <form
          noValidate
          onSubmit={
            !isPreventSubmit
              ? config.handleSubmit(submitForm)
              : (e) => e.preventDefault()
          }
          ref={ref}
          style={styles}
        >
          {children}
        </form>
      </FormProvider>
    );
  }
);
