const base64URLEncode = (str: Buffer) => {
  return str
    .toString('base64')
    .replaceAll('=', '')
    .replaceAll('+', '-')
    .replaceAll('/', '_');
};

async function sha256(string: string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = [...new Uint8Array(hashBuffer)];
  return Buffer.from(hashArray);
}
export const generateCodeChallenge = async () => {
  const str = getRandomString(32);
  const verifier = base64URLEncode(str);
  const hash = await sha256(verifier);
  return {
    challenge: base64URLEncode(hash),
    verifier,
  };
};

export const getRandomString = (size: number) => {
  const bytes = Buffer.allocUnsafe(size);
  return window.crypto.getRandomValues(bytes);
};

export const getRandomStringBase64URLEncoded = (size: number) =>
  base64URLEncode(getRandomString(size));
