import styled, { css } from 'styled-components';

import { visuallyHidden } from '@/styles/mixins';

const Checkmark = styled.span<{
  $disabled?: boolean;
}>`
  border: 1px solid var(--colors-onSurfaceA);
  border-radius: var(--radii-md);
  height: 1.25rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;

  &::after {
    border: solid white;
    border-width: 0 1px 1px 0;
    content: '';
    display: none;
    height: 10px;
    left: 6px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 5px;
  }

  ${(props) =>
    props.$disabled &&
    css`
      background-color: var(--colors-surfaceLow);
      border: 1px solid var(--colors-onSurfaceD);
      cursor: not-allowed;
    `}
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
  ${visuallyHidden}
`;

const Container = styled.label<{
  $disabled?: boolean;
}>`
  color: var(--colors-onSurfaceB);
  cursor: pointer;
  display: block;
  font-size: var(--fontSizes-16);
  padding-left: 1.75rem;
  position: relative;
  user-select: none;

  &:hover ${Checkmark} {
    background-color: var(--colors-onSurfaceE);
  }

  &:focus-within ${Checkmark} {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }

  ${Input}:checked ~ ${Checkmark} {
    background-color: var(--colors-brand1Highest);
    border-color: var(--colors-brand1Highest);

    &::after {
      display: block;
    }
  }
  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      color: var(--colors-onSurfaceC);

      &:hover ${Checkmark} {
        background-color: var(--colors-surfaceLow);
      }

      ${Input}:checked ~ ${Checkmark} {
        background-color: var(--colors-brand1High);
      }
    `}
`;

const ErrorMessage = styled.p`
  color: var(--colors-onErrorLow);
  font-size: var(--fontSizes-14);
  line-height: var(--lineHeights-half);
  margin: 0;
`;

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: var(--space-xs);
  padding-left: var(--space-l);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  min-height: 4rem;
`;

export const Styled = {
  Checkmark,
  Container,
  ErrorMessage,
  ErrorWrapper,
  Input,
  Wrapper,
};
