import { createContext, forwardRef, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { ComboBoxInput } from './combobox-input';
import { ComboBoxOptions } from './combobox-options';
import { ComboBoxResultsStatus } from './combobox-results-status';
import { Styled } from './styles';

import type {
  IComboBoxOption,
  TComboBoxContext,
  TComboBoxProps,
} from './typings';
import type { RefObject } from 'react';

export const ComboBoxContext = createContext<TComboBoxContext | null>(null);

export const ComboBox = forwardRef<HTMLDivElement | null, TComboBoxProps>(
  (
    {
      disabled,
      id,
      label,
      labelCSS,
      name,
      onClickOption,
      options,
      placeholder = 'Select',
      size,
      validate,
      value,
    },
    ref
  ) => {
    const localRef = useRef<HTMLDivElement | null>(null);
    const comboBoxRef = ref ?? localRef;

    useClickAway(comboBoxRef as RefObject<HTMLElement | null>, () => {
      if (isOpen) {
        setIsOpen(false);
      }
    });

    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [selectOptions, setSelectOptions] =
      useState<IComboBoxOption[]>(options);
    const [selectedOption, setSelectedOption] = useState<
      IComboBoxOption | undefined
    >();

    return (
      <ComboBoxContext.Provider
        value={{
          cursorPosition,
          disabled,
          id,
          inputValue,
          isOpen,
          label,
          name,
          onClickOption,
          options,
          placeholder,
          selectOptions,
          selectedOption,
          setCursorPosition,
          setInputValue,
          setIsOpen,
          setSelectOptions,
          setSelectedOption,
          size,
          validate,
          value,
        }}
      >
        <Styled.ComboBoxWrapper ref={localRef}>
          {label && (
            <Styled.LabelRoot htmlFor={name} style={labelCSS}>
              <span>{label}</span>
            </Styled.LabelRoot>
          )}

          <Styled.AutoComplete>
            <ComboBoxInput />
            <ComboBoxOptions />
            <ComboBoxResultsStatus />
          </Styled.AutoComplete>
        </Styled.ComboBoxWrapper>
      </ComboBoxContext.Provider>
    );
  }
);

ComboBox.displayName = 'ComboBox';
