export interface IChevronUpIconProps {
  color?: string;
  height?: string;
  width?: string;
}
export const ChevronRightIcon = ({
  color = 'currentColor',
  height = '18',
  width = '17',
}: IChevronUpIconProps) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 17 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.18037 2.54609C5.42933 2.29714 5.83297 2.29714 6.08193 2.54609L11.8194 8.28359C12.0684 8.53255 12.0684 8.9362 11.8194 9.18516L6.08193 14.9227C5.83297 15.1716 5.42933 15.1716 5.18037 14.9227C4.93141 14.6737 4.93141 14.2701 5.18037 14.0211L10.4671 8.73438L5.18037 3.44766C4.93141 3.1987 4.93141 2.79505 5.18037 2.54609Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
