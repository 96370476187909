import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { request } from '@/services/request';

import { endpoints } from './endpoints';
import {
  MerchantEndpointKeys,
  merchantTransactionKeys,
  ordersQueryKeys,
} from './query-keys';
import { select } from './use-get-orders-selectors';

import type { OrderServiceTypes } from './typings';
import type { InfiniteData } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

export type TGetOrdersArgs = {
  pageParam?: { nextKey: string };
  params?: OrderServiceTypes.TOrdersListRequest;
};

type TGetOrdersRequestArgs = {
  pageParam?: string;
};

export function useGetOrders({ params }: TGetOrdersArgs) {
  const auth = useAuth();

  const getOrdersRequest = async ({ pageParam }: TGetOrdersRequestArgs) =>
    await request<OrderServiceTypes.TOrdersList>(
      endpoints.qrOrders.ORDERS(),
      auth,
      { params: { ...params, next_key: pageParam } },
      {
        priority: EventPriority.P2,
        requestKey: MerchantEndpointKeys.ORDERS,
        transaction: merchantTransactionKeys.orders,
      }
    );

  return useInfiniteQuery<
    OrderServiceTypes.TOrdersList,
    AxiosError,
    InfiniteData<OrderServiceTypes.TOrdersList>,
    unknown[],
    string | undefined
  >({
    enabled: auth.isAuthenticated,
    getNextPageParam: (lastPage) =>
      !!lastPage.next_key && lastPage.next_key !== ''
        ? lastPage.next_key
        : undefined,
    initialPageParam: undefined,
    queryFn: getOrdersRequest,
    queryKey: ordersQueryKeys.orders(params),
    select: select.filteredOrders,
  });
}
