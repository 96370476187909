import type { OrderServiceTypes } from '@/features/activity/services';

export const getOrderById = (
  id?: string,
  orders?: OrderServiceTypes.TOrder[]
) => {
  if (!orders || !id) {
    return;
  }
  return orders.find((order) => order.id === id);
};
