import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export const LoginRedirect = () => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading) {
      auth.removeUser();
      auth.signinRedirect();
    }
  }, [auth]);

  return <div />;
};
