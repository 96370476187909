import styled from 'styled-components';

import { Button } from '@/components';

const PanelCardWrapper = styled.div`
  align-items: flex-start;
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  padding: var(--space-xl) var(--space-l);
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: var(--fontWeights-bold);
    font-size: var(--fontSizes-20);
    line-height: 1.4;
    color: var(--colors-onSurfaceA);
    display: flex;
    gap: var(--space-s);
    align-items: center;
    margin: 0;
  }
`;

const PanelCardLabelWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PanelCardContentContainer = styled.div`
  align-content: start;
  display: flex;
  gap: var(--space-m);
  width: 100%;
`;

const PanelCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-xs);
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 24px;
  width: 100%;
`;

const PanelCardTitle = styled.h3`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-normal);
  margin: 0;
`;

const PanelCardDescription = styled.div`
  color: var(--colors-onSurfaceA);
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 1.25;
`;

const PanelCardButton = styled(Button)`
  background: var(--colors-surfaceLowest);
  color: var(--colors-brand1);
  border-radius: var(--radii-md);
  display: flex;
  gap: var(--space-xs);
  margin-top: var(--space-xs);
  padding: 10px var(--space-s);
  width: max-content;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AddBtn = styled(Button)`
  background: var(--colors-brand1Lowest);
  border: none;
  margin-top: -0.25rem;
`;

const ErrorIcon = styled.div`
  flex-basis: 80px;
  text-align: center;
`;

export const Styled = {
  AddBtn,
  ButtonWrapper,
  ErrorIcon,
  PanelCardButton,
  PanelCardContent,
  PanelCardContentContainer,
  PanelCardDescription,
  PanelCardLabelWrapper,
  PanelCardTitle,
  PanelCardWrapper,
  SpinnerWrapper,
};
