import { PaymentCard } from '@b707/ponyta';

import {
  PaymentMethodEnums,
  getBankCardLabel,
} from '@/features/card-management';
import { useBreakpoints } from '@/hooks';

import { CardStatusPill } from '../card-status-pill';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';

interface IPaymentCardListItemProps {
  card: PaymentMethodTypes.TBankCard;
  onClick?: () => void;
  selected?: boolean;
}

export const PaymentCardListItem = ({
  card,
  onClick,
  selected,
}: IPaymentCardListItemProps) => {
  const breakpoints = useBreakpoints();

  const isUnavailable =
    card.status === PaymentMethodEnums.BankCardStatus.INVALID;
  const isDefault = !!card.is_default_pay;
  const isVerified =
    card?.status === PaymentMethodEnums.BankCardStatus.VERIFIED;
  const hasPill = isDefault || !isVerified;

  const cardName = card.alias || card.data.issuer_name || '';
  const maskedCardNumber = `••• ${card.data.visualization.last_four_digits}`;

  return (
    <Styled.CardItem
      $hasPill={hasPill}
      $isUnavailable={isUnavailable}
      aria-label={getBankCardLabel(card, isDefault)}
      onClick={onClick}
      selected={selected}
    >
      <Styled.CardItemContent aria-hidden={'true'}>
        <PaymentCard
          cardType={card.data.type}
          schemes={card.data.schemes}
          size={breakpoints.xs ? 'md' : 'sm'}
        />

        <Styled.CardItemContentTextContainer>
          <Styled.CardItemDetails>
            <Styled.CardItemName data-testid={'card-name'}>
              {cardName}
            </Styled.CardItemName>
            <Styled.CardItemNumber data-testid={'card-number'}>
              {maskedCardNumber}
            </Styled.CardItemNumber>
          </Styled.CardItemDetails>

          {hasPill && (
            <CardStatusPill
              isDefault={card.is_default_pay}
              status={card.status}
            />
          )}
        </Styled.CardItemContentTextContainer>
      </Styled.CardItemContent>
    </Styled.CardItem>
  );
};
