import { SuccessDialogStates } from '../../enums';

export const getAddressSuccessMessage = (
  successDialogState: SuccessDialogStates | null
) => {
  const message = {
    description: '',
    title: '',
  };

  switch (successDialogState) {
    case SuccessDialogStates.SET_DEFAULT: {
      message.title = 'Default address set';
      message.description =
        'This address will now be selected for you when possible.';
      return message;
    }

    case SuccessDialogStates.EDIT: {
      message.title = 'Address updated';
      message.description =
        'You have successfully updated your address details.';
      return message;
    }

    case SuccessDialogStates.ADD: {
      message.title = 'New address added';
      message.description =
        'You have successfully added a new address to your flypay wallet.';
      return message;
    }

    default: {
      message.title = 'Address removed';
      message.description =
        'You have successfully removed this address from your flypay wallet.';
      return message;
    }
  }
};
