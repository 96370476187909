import { format, isAfter, isEqual, isValid } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import FocusLock from 'react-focus-lock';

import { useClickAway, useEventListener } from '@/hooks';
import { Keys } from '@/utils';

import { Styled } from './styles';

import type { RefObject } from 'react';
import type {
  DateFormatter,
  DateRange,
  SelectRangeEventHandler,
} from 'react-day-picker';

import 'react-day-picker/dist/style.css';

interface IDatePickerProps {
  anchor: RefObject<HTMLDivElement>;
  onChange: (dateRange?: DateRange, selectedDay?: Date) => void;
  onClickAway: () => void;
  onMonthChange: (month: Date) => void;
  showCalendar: boolean;
  value?: DateRange;
  viewMonth?: Date;
}

const currentYear = new Date().getFullYear();

const formatWeekdayName: DateFormatter = (day) => format(day, 'eee');

const checkValues = (values?: DateRange) => {
  if (!values) {
    return false;
  }

  const { from, to } = values;

  if (!from || !to) {
    return isValid(from) || isValid(to);
  }

  if (isEqual(from, to)) {
    return isValid(from);
  }

  return isAfter(to, from);
};

let timer: NodeJS.Timeout;

export const DatePicker = ({
  anchor,
  onChange,
  onClickAway,
  onMonthChange,
  showCalendar,
  value,
  viewMonth,
}: IDatePickerProps) => {
  const isValidValues = checkValues(value);
  const calRef = useRef<HTMLDivElement>(null);
  const [openedElement, setOpenedElement] = useState<HTMLButtonElement | null>(
    null
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === Keys.ESCAPE) {
      onClickAway();
    }
  };

  useClickAway(anchor, () => {
    if (showCalendar) {
      onClickAway();
    }
  });

  useEventListener('keydown', onKeyDown);

  useEffect(() => {
    if (showCalendar) {
      timer = setTimeout(() => {
        calRef?.current?.querySelector('select')?.focus();
      }, 50);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showCalendar]);

  useEffect(() => {
    if (!showCalendar && openedElement) {
      openedElement.focus();
    }
  }, [openedElement, showCalendar]);

  const handleRangeSelect: SelectRangeEventHandler = (range, selectedDay) => {
    onChange(range, selectedDay);
  };

  return (
    <FocusLock
      autoFocus
      disabled={!showCalendar}
      returnFocus={(el) => {
        setOpenedElement(el as HTMLButtonElement);
        return true;
      }}
      whiteList={(node) => {
        return !anchor?.current?.contains(node);
      }}
    >
      <Styled.DatePickerWrapper
        $showCalendar={showCalendar}
        aria-hidden={!showCalendar ? 'true' : 'false'}
        ref={calRef}
      >
        <DayPicker
          captionLayout="dropdown-buttons"
          formatters={{ formatWeekdayName }}
          fromYear={currentYear - 10}
          initialFocus
          mode="range"
          modifiersClassNames={{
            range_end: 'custom-range-end',
            range_start: 'custom-range-start',
          }}
          month={viewMonth}
          onMonthChange={onMonthChange}
          onSelect={handleRangeSelect}
          selected={!!isValidValues ? value : undefined}
          toYear={currentYear}
        />
      </Styled.DatePickerWrapper>
    </FocusLock>
  );
};
