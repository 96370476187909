import { useRef, useState } from 'react';

import {
  ConfirmationDialog,
  ErrorDialog,
  LoadingCard,
  SideSheet,
  SuccessDialog,
} from '@/components';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_MYCOLES_UNLINKING,
  ERROR_TITLE_MYCOLES_UNLINKING,
} from '@/config/language/errors';
import {
  useDeleteLoyaltyCard,
  useGetMycolesCards,
} from '@/features/loyalty/services';
import { useHasUserConsentedTerms } from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { BaasErrors } from '@/services';

import { LOYALTY_SCHEME_NAMES } from '../constants';

import { MycolesAddCard } from './add-card';
import { MycolesFetchError } from './fetch-error/mycoles-fetch-error';
import { MycolesLinked } from './linked/mycoles-linked';
import { MycolesDetails } from './mycoles-details';
import { MycolesNotLinked } from './not-linked/mycoles-not-linked';

export const Mycoles = () => {
  const { track } = useAnalytics();
  const consentedTerms = useHasUserConsentedTerms();

  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [isViewCardOpen, setIsViewCardOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isUnlinkLoading, setIsUnlinkLoading] = useState(false);
  const [isUnlinkConfirmOpen, setIsUnlinkConfirmOpen] = useState(false);
  const [isUnlinkError, setIsUnlinkError] = useState(false);

  const addButtonRef = useRef<HTMLButtonElement>(null);
  const viewButtonRef = useRef<HTMLButtonElement>(null);

  const { data, error, isError, isFetching, refetch } = useGetMycolesCards();

  const errorCode =
    error?.details?.error_number?.toString() ?? error?.details?.toString();
  const isGetMyColesError = isError && errorCode !== BaasErrors.FORBIDDEN;
  const termsNotAcceptedError =
    errorCode === BaasErrors.TERMS_AND_CONDITIONS_NOT_ACCEPTED;
  const isGetMyColesCardsLoading =
    !consentedTerms ||
    termsNotAcceptedError ||
    (isFetching && !isGetMyColesError);

  const card = data?.cards?.[0];
  const lastFour = card?.data?.bar_code?.slice(-4);

  const handleOpenAddCard = () => {
    track('Link Loyalty Started', {
      scheme: LOYALTY_SCHEME_NAMES.TEAM_MEMBER_CARD,
    });
    setIsAddCardOpen(true);
  };

  const handleCloseAddCard = () => {
    setIsAddCardOpen(false);
    refetch();
  };

  const handleCloseViewCard = () => {
    setIsViewCardOpen(false);
    addButtonRef.current?.focus();
  };

  const deleteLoyaltyCard = useDeleteLoyaltyCard(card?.id || '', {
    onError: () => {
      setIsUnlinkLoading(false);
      setIsUnlinkError(true);
    },
    onSuccess: () => {
      setIsUnlinkLoading(false);
      setIsSuccessDialogOpen(true);
      setIsViewCardOpen(false);
      refetch();
    },
  });

  const handleClickUnlink = () => {
    track('Unlink Loyalty Started', {
      scheme: LOYALTY_SCHEME_NAMES.TEAM_MEMBER_CARD,
    });
    setIsUnlinkConfirmOpen(true);
  };

  const handleUnlink = () => {
    track('Unlink Loyalty Confirmed', {
      scheme: LOYALTY_SCHEME_NAMES.TEAM_MEMBER_CARD,
    });
    setIsUnlinkConfirmOpen(false);
    setIsUnlinkLoading(true);
    deleteLoyaltyCard.mutate();
  };

  if (isGetMyColesCardsLoading) {
    return <LoadingCard />;
  }

  if (isGetMyColesError) {
    return <MycolesFetchError />;
  }

  return (
    <>
      {!!card && (
        <MycolesLinked
          buttonRef={viewButtonRef}
          handleOpenViewCard={() => setIsViewCardOpen(true)}
        />
      )}
      {!card && (
        <MycolesNotLinked
          buttonRef={addButtonRef}
          handleOpenAddCard={handleOpenAddCard}
        />
      )}

      <SideSheet
        onClose={handleCloseAddCard}
        open={isAddCardOpen}
        title="Link mycoles discount card"
      >
        <MycolesAddCard handleCloseAddCard={handleCloseAddCard} />
      </SideSheet>

      <SideSheet
        onClose={handleCloseViewCard}
        open={isViewCardOpen}
        title="mycoles discount card"
      >
        <MycolesDetails
          card={card}
          handleClickUnlink={handleClickUnlink}
          isUnlinkLoading={isUnlinkLoading}
        />
      </SideSheet>

      <ConfirmationDialog
        acceptButtonText="Yes, unlink it"
        isDanger
        isOpen={isUnlinkConfirmOpen}
        onClickAccept={handleUnlink}
        onClickCancel={() => setIsUnlinkConfirmOpen(false)}
        title={`Are you sure you want to unlink your mycoles discount card ending in • • • ${lastFour}?`}
      />

      <SuccessDialog
        buttonText="Done"
        isOpen={isSuccessDialogOpen}
        onOpenChange={() => setIsSuccessDialogOpen(false)}
        title={'Your mycoles discount card has been unlinked'}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isUnlinkError}
        message={ERROR_MYCOLES_UNLINKING}
        onOpenChange={() => setIsUnlinkError(false)}
        title={ERROR_TITLE_MYCOLES_UNLINKING}
      />
    </>
  );
};
