import { Dialog } from '@/components';
import { CheckIcon } from '@/components/icons';
import { useTheme } from '@/contexts/theme';
import { useSetA11yActions } from '@/store';

import { Styled } from './styles';

interface ISuccessDialog {
  buttonText?: string;
  description?: string;
  isOpen: boolean;
  onOpenChange: () => void;
  title: string;
}

const SuccessDialogBody = ({
  buttonText,
  description,
  onOpenChange,
  title,
}: Partial<ISuccessDialog>) => {
  const { theme } = useTheme();

  return (
    <Styled.DialogWrapper>
      <CheckIcon color={theme.styling.colors.onSurfaceInverse.value} />
      <Styled.DialogHeader>
        <Styled.DialogTitle>{title}</Styled.DialogTitle>
        {!!description && (
          <Styled.DialogDescription>{description}</Styled.DialogDescription>
        )}
      </Styled.DialogHeader>

      <Styled.ActionButton onClick={onOpenChange} size="medium">
        {buttonText}
      </Styled.ActionButton>
    </Styled.DialogWrapper>
  );
};

export const SuccessDialog = ({
  buttonText,
  description,
  isOpen,
  onOpenChange,
  title,
}: ISuccessDialog) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleOpenChange = () => {
    if (isOpen) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }
    onOpenChange?.();
  };

  return (
    <Dialog
      body={
        <SuccessDialogBody
          buttonText={buttonText}
          description={description}
          onOpenChange={handleOpenChange}
          title={title}
        />
      }
      description={title}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
    />
  );
};
