import { useEffect } from 'react';

import { useSetA11yActions } from '@/store';

import { appName } from './const';

export const usePageTitle = (title?: string) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  useEffect(() => {
    const pageTitle = !!title ? `${title} | ${appName}` : appName;
    setTimeout(() => {
      setA11yAnnouncement(`${pageTitle}.`);
    }, 50);

    document.title = pageTitle;
  }, [setA11yAnnouncement, title]);
};
