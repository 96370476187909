import { joiResolver } from '@hookform/resolvers/joi';
import { useQueryClient } from '@tanstack/react-query';
import Joi from 'joi';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  CardInput,
  ErrorDialog,
  Form,
  FormHint,
} from '@/components';
import { CardInputFieldNames } from '@/components/card-input';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_SAVE_CARD_GENERIC,
  ERROR_TITLE_SAVE_CARD,
} from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  PaymentMethodEnums,
  ThreeDsIframe,
  endpoints,
  formatDate,
  use3dsCardListener,
  useAddCard3dsFinalize,
  useUpdateCard3ds,
} from '@/features/card-management';
import { paymentMethodQueryKeys } from '@/features/card-management/services';
import { validate } from '@/utils';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';
import type { FieldValues } from 'react-hook-form';

interface ICardDetailsEdit {
  card: PaymentMethodTypes.TBankCard;
  displayCard: JSX.Element;
  is3dsIframeOpen: boolean;
  onSaveSuccess: () => void;
  setIs3dsIframeOpen: (isOpen: boolean) => void;
}

const DEFAULT_FORM_VALUES = {
  [CardInputFieldNames.CARD_EXPIRY_DATE]: '',
  [CardInputFieldNames.CARD_SECURITY_CODE]: '',
} as FieldValues;

export const CardDetailsEdit = ({
  card,
  displayCard,
  is3dsIframeOpen,
  onSaveSuccess,
  setIs3dsIframeOpen,
}: ICardDetailsEdit) => {
  const queryClient = useQueryClient();
  const { theme } = useTheme();
  const { message } = use3dsCardListener();

  const [isError, setIsError] = useState<boolean>(false);
  const [threeDSecureId, setThreeDSecureId] = useState('');
  const [finalizePayload, setFinalizePayload] =
    useState<PaymentMethodTypes.TAddCard3dsFinalizeRequest>();

  const validationSchema = Joi.object({
    [CardInputFieldNames.CARD_EXPIRY_DATE]: validate.cardExpiryDate(),
    [CardInputFieldNames.CARD_SECURITY_CODE]: validate.cardSecurityCode(card),
  });

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const reloadBankCardList = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.bankCards,
    });
  }, [queryClient]);

  const updateCard3ds = useUpdateCard3ds(
    (card as PaymentMethodTypes.TBankCard)?.id,
    {
      onError: () => {
        setIsError(true);
        reloadBankCardList();
      },
      onSuccess: (data) => {
        setThreeDSecureId(data?.three_d_secure?.id);
        setFinalizePayload({
          authorization_id: data?.three_d_secure?.id,
          card_id: card.id,
        });
        setIs3dsIframeOpen(true);
      },
    }
  );

  const onSubmitForm = async ({
    cardExpiryDate,
    cardSecurityCode,
  }: FieldValues) => {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    const cardDetails = {
      cvv: cardSecurityCode,
      expiry: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    updateCard3ds.mutate({
      card: cardDetails,
    });
  };

  const { isPending: isFinalizeLoading, mutate: finalize } =
    useAddCard3dsFinalize({
      onError: () => {
        reloadBankCardList();
        setIsError(true);
      },
      onSuccess: () => {
        formMethods.reset();
        onSaveSuccess();
      },
    });

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS) {
        if (finalizePayload) {
          finalize(finalizePayload);
        } else {
          setIs3dsIframeOpen(false);
          reloadBankCardList();
          setIsError(true);
        }
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        reloadBankCardList();
        setIsError(true);
      }
    }
  }, [
    finalize,
    finalizePayload,
    is3dsIframeOpen,
    message,
    reloadBankCardList,
    setIs3dsIframeOpen,
  ]);

  const isProcessing =
    isFinalizeLoading || updateCard3ds.isPending || is3dsIframeOpen;

  return (
    <Styled.CardDetailsWrapper>
      {is3dsIframeOpen && threeDSecureId ? (
        <ThreeDsIframe
          src={`${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${endpoints.BANK_CARDS_3DS_AUTHENTICATE_INIT(threeDSecureId)}`}
        />
      ) : (
        <Form config={formMethods} onSubmitForm={onSubmitForm}>
          <Styled.CardDetailsContainer>
            {displayCard}
            <Styled.CardDetails>
              <FormHint />
              <CardInput
                isEditing
                isSubmitting={isProcessing}
                lastFour={card?.data?.visualization?.last_four_digits}
                schemes={card?.data?.schemes}
              />
            </Styled.CardDetails>
          </Styled.CardDetailsContainer>
          <Styled.Actions>
            <ButtonSpinner
              disabled={isProcessing}
              level="primary"
              loading={isProcessing}
              size="medium"
              spinnerColor={theme.styling.colors.onSurfaceC.value}
              variant="branded"
            >
              Save
            </ButtonSpinner>
          </Styled.Actions>
        </Form>
      )}
      {isError && (
        <ErrorDialog
          buttonText={BUTTON_TEXT_TRY_AGAIN}
          isOpen={isError}
          message={ERROR_SAVE_CARD_GENERIC}
          onOpenChange={() => setIsError(false)}
          title={ERROR_TITLE_SAVE_CARD}
        />
      )}
    </Styled.CardDetailsWrapper>
  );
};
