import { V2QrEnums } from '@/services';

import type { OrderServiceTypes } from '@/features/activity/services';

/**
 * Filters out any aborted orders with no refunds
 */
export function filterOrders(orders?: OrderServiceTypes.TOrder[]) {
  if (!orders) {
    return [];
  }
  return orders.reduce<OrderServiceTypes.TOrder[]>((result, order) => {
    if (order.status === V2QrEnums.OrderStatusV2.ABORTED) {
      const hasRefund = order.details?.order?.payments?.some(
        (payment) => payment.is_refund
      );
      if (hasRefund) {
        result.push(order);
      }
    } else {
      result.push(order);
    }
    return result;
  }, []);
}
