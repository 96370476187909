import { useCallback, useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';

import { CheckTerms, ConfirmationDialog, SkipLink } from '@/components';
import { ExclamationMarkIcon } from '@/components/icons';
import { Breakpoints } from '@/config/breakpoints';
import { genericContent } from '@/config/language';
import { getUserFullName, useGetProfile } from '@/features/profile';
import { useWindowSize } from '@/hooks';
import { Routes } from '@/routes/constants';
import { Keys } from '@/utils';

import { A11yAnnouncement } from '../a11y-announcement/a11y-announcement';
import { TopBar } from '../top-bar';

import { Styled } from './styles';
import { WalletSideNav } from './wallet-side-nav';

import type { PropsWithChildren } from 'react';

interface IProps {
  hideSideNav?: boolean;
}

const MAIN = 'main';

export const Layout = ({
  children,
  hideSideNav,
}: PropsWithChildren<IProps>) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const { data: user } = useGetProfile();
  const userDetails = {
    details: user?.email || user?.handle || '',
    logo: user?.avatar_url || undefined,
    name: getUserFullName(user),
  };
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const windowSize = useWindowSize();
  const isMobile = (windowSize?.width || 0) < Breakpoints.sm;

  const handleLogoutClick = () => {
    setIsMobileNavOpen(false);
    setIsLogoutDialogOpen(true);
    toggleBodyScrolling(false);
  };

  const handleLogout = () => {
    auth.removeUser();
    auth.signoutRedirect();
  };

  const handleToggleMobileOpen = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
    toggleBodyScrolling(!isMobileNavOpen);
  };

  const handleLogoClick = () => {
    navigate(Routes.HOME);
  };

  const toggleBodyScrolling = (disabled = false) => {
    document.body.style.position = disabled ? 'relative' : '';
    document.body.style.overflow = disabled ? 'hidden' : '';
  };

  useEffect(() => {
    toggleBodyScrolling(false);
    setTimeout(() => {
      ref.current?.focus();
    }, 150);
  }, [location]);

  const closeOpenMobileNav = useCallback(
    (e: KeyboardEvent) => {
      if (isMobile && isMobileNavOpen && e.key === Keys.ESCAPE) {
        setIsMobileNavOpen(false);
        toggleBodyScrolling(false);
      }
    },
    [isMobile, isMobileNavOpen]
  );

  useEffect(() => {
    window.addEventListener('keydown', closeOpenMobileNav);
    return () => {
      window.removeEventListener('keydown', closeOpenMobileNav);
    };
  }, [closeOpenMobileNav]);

  useEffect(() => {
    if (isMobileNavOpen && !isMobile) {
      setIsMobileNavOpen(false);
      toggleBodyScrolling(false);
    }
  }, [isMobile, isMobileNavOpen]);

  return (
    <>
      <CheckTerms />
      <A11yAnnouncement />
      <Styled.Container ref={ref}>
        <Styled.TopNavWrapper>
          <SkipLink id={MAIN} refToFocus={contentRef?.current} />
          <TopBar
            isMobileOpen={isMobileNavOpen}
            onLogoClick={handleLogoClick}
            onLogout={handleLogoutClick}
            onMobileToggleClick={handleToggleMobileOpen}
            userDetails={userDetails}
          />
        </Styled.TopNavWrapper>
        <Styled.Wrapper>
          {!hideSideNav && (
            <>
              <Styled.SideNavContainer>
                <WalletSideNav />
              </Styled.SideNavContainer>
              <Styled.MobileNavContainer
                $isMobile={isMobile}
                $navOpen={isMobileNavOpen}
              >
                {isMobile && (
                  <Styled.DimOverlay
                    $isDim={isMobileNavOpen}
                    $isOpen={isMobileNavOpen}
                    onClick={handleToggleMobileOpen}
                  />
                )}
                <FocusLock
                  disabled={!isMobileNavOpen}
                  shards={[
                    document.querySelector('#mobile-nav-button') as HTMLElement,
                  ]}
                >
                  <WalletSideNav onLogout={handleLogoutClick} />
                </FocusLock>
              </Styled.MobileNavContainer>
            </>
          )}
          <Styled.Content id={MAIN} ref={contentRef}>
            {children}
          </Styled.Content>
        </Styled.Wrapper>
      </Styled.Container>

      <ConfirmationDialog
        acceptButtonText={genericContent.LOG_OUT_CONFIRM}
        cancelButtonText={genericContent.LOG_OUT_CANCEl}
        icon={<ExclamationMarkIcon />}
        isOpen={isLogoutDialogOpen}
        onClickAccept={handleLogout}
        onClickCancel={() => setIsLogoutDialogOpen(false)}
        title={genericContent.LOG_OUT_TITLE}
      />
    </>
  );
};
