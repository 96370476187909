import { Spinner } from '@/components';
import { useTheme } from '@/contexts/theme';

import { Styled } from './styles';

export const LoadingScreen = () => {
  const { theme } = useTheme();
  return (
    <Styled.Container>
      <Spinner
        $secondaryColor={theme.styling.colors.brand1Highest.value}
        color="transparent"
      />
    </Styled.Container>
  );
};
