import { CustomerService } from '@/components';
import { landingUrl } from '@/config/links';
import { AppErrorTypes } from '@/store';

type TAppErrorMessage = {
  button?: () => React.ReactNode;
  message: () => React.ReactNode;
  title: () => React.ReactNode;
};

export const AppErrorMsg: Record<AppErrorTypes, TAppErrorMessage> = {
  [AppErrorTypes.DEFAULT]: {
    button() {
      return <a href={landingUrl}>Back to flypay.com.au</a>;
    },
    message() {
      return (
        <>
          Check the URL or try refreshing the page. No luck? <CustomerService />
          .
        </>
      );
    },
    title() {
      return 'We can’t load your wallet right now';
    },
  },

  [AppErrorTypes.FLYBUYS_AUTH]: {
    button() {
      return <a href="/">Back to wallet</a>;
    },

    message() {
      return <>There was an error linking your Flybuys account.</>;
    },

    title() {
      return <>Flybuys Linking Error</>;
    },
  },

  [AppErrorTypes.FLYBUYS_INVALID_ACCOUNT]: {
    button() {
      return <a href="/">Back to wallet</a>;
    },

    message() {
      return (
        <>
          The Flybuys account used is invalid. Please use a different account.
        </>
      );
    },

    title() {
      return <>Flybuys Linking Error</>;
    },
  },
};
