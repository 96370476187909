import { min } from 'date-fns';

import { V2QrEnums } from '@/services';

import { getTransactions } from './get-transactions';

import type { TPaymentInfo, TTransactionInfo } from '../../types';
import type { V2QrTypes } from '@/services';

export const getPayments = (
  payments: V2QrTypes.TPaymentV2[],
  status: `${V2QrEnums.OrderStatusV2}`
): TPaymentInfo[] => {
  const allPayments = payments.map((payment) => {
    const transactions = getTransactions(payment.transactions, status);
    return {
      amount: getPaymentAmount(transactions),
      date: getPaymentDate(payment),
      hasRefund: payment.is_refund,
      id: payment.payment_id,
      status: payment.status,
      transactions,
    };
  });
  return allPayments.filter(
    (payment) =>
      payment.transactions.length > 0 &&
      payment.status !== V2QrEnums.PaymentStatus.DECLINED
  );
};

export const getPaymentAmount = (transactions: TTransactionInfo[]): number => {
  return transactions.reduce((total, transaction) => {
    return total + transaction.amount;
  }, 0);
};

export const getPaymentDate = (payment: V2QrTypes.TPaymentV2): Date => {
  return !hasProcessedTransactions(payment)
    ? new Date(payment.created_datetime)
    : getEarliestProcessedDate(payment);
};

export const hasProcessedTransactions = (payment: V2QrTypes.TPaymentV2) => {
  return payment.transactions.some(
    (transaction) => transaction.card_type === 'card' && !!transaction.processed
  );
};

export const getEarliestProcessedDate = (
  payment: V2QrTypes.TPaymentV2
): Date => {
  const processedDates = [];
  for (const transaction of payment.transactions) {
    if (transaction.card_type === 'card' && !!transaction.processed) {
      processedDates.push(new Date(transaction.processed));
    }
  }
  return min(processedDates);
};
