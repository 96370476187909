import { ButtonSpinner, Dialog } from '@/components';
import { BREAKPOINT_BOTTOM_SHEET_TO_DIALOG } from '@/config/breakpoints';
import { useTheme } from '@/contexts/theme';
import { useWindowSize } from '@/hooks';
import { useSetA11yActions } from '@/store';

import { Styled } from './styles';

interface IDualActionDialog {
  buttonLeftText: React.ReactNode | string;
  buttonRightText: React.ReactNode | string;
  icon?: React.ReactNode;
  intro?: React.ReactNode;
  isButtonLeftDanger?: boolean;
  isButtonLeftLoading?: boolean;
  isButtonRightDanger?: boolean;
  isButtonRightLoading?: boolean;
  isOpen: boolean;
  onClickLeft: () => void;
  onClickRight: () => void;
  onOpenChange: () => void;
  title: string;
}

const DialogBody = ({
  buttonLeftText,
  buttonRightText,
  icon,
  intro,
  isButtonLeftDanger,
  isButtonLeftLoading,
  isButtonRightDanger,
  isButtonRightLoading,
  onClickLeft,
  onClickRight,
  title,
}: Partial<IDualActionDialog>) => {
  const { theme } = useTheme();
  const windowSize = useWindowSize();

  const isMobile =
    (windowSize?.width || 0) <= BREAKPOINT_BOTTOM_SHEET_TO_DIALOG;

  return (
    <Styled.DialogWrapper>
      {icon && <Styled.DialogIcon>{icon}</Styled.DialogIcon>}

      <Styled.DialogHeader>
        <Styled.DialogTitle>{title}</Styled.DialogTitle>
        <Styled.DialogDescription>{intro}</Styled.DialogDescription>
      </Styled.DialogHeader>

      <Styled.DialogActions $isMobile={isMobile}>
        <ButtonSpinner
          level="secondary"
          loading={isButtonLeftLoading}
          onClick={onClickLeft}
          size={isMobile ? 'medium' : 'large'}
          spinnerColor={theme.styling.colors.onSurfaceC.value}
          variant={isButtonLeftDanger ? 'destructive' : 'neutral'}
        >
          {buttonLeftText}
        </ButtonSpinner>
        <ButtonSpinner
          level="primary"
          loading={isButtonRightLoading}
          onClick={onClickRight}
          size={isMobile ? 'medium' : 'large'}
          spinnerColor={theme.styling.colors.errorLowest.value}
          {...(isButtonRightDanger && { variant: 'destructive' })}
        >
          {buttonRightText}
        </ButtonSpinner>
      </Styled.DialogActions>
    </Styled.DialogWrapper>
  );
};

export const DualActionDialog = ({
  buttonLeftText,
  buttonRightText,
  icon,
  intro,
  isButtonLeftDanger,
  isButtonLeftLoading,
  isButtonRightDanger,
  isButtonRightLoading,
  isOpen,
  onClickLeft,
  onClickRight,
  onOpenChange,
  title,
}: IDualActionDialog) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleClose = () => {
    setA11yAnnouncement('Dialog closed');
    onOpenChange();
  };

  return (
    <Dialog
      body={
        <DialogBody
          buttonLeftText={buttonLeftText}
          buttonRightText={buttonRightText}
          icon={icon}
          intro={intro}
          isButtonLeftDanger={isButtonLeftDanger}
          isButtonLeftLoading={isButtonLeftLoading}
          isButtonRightDanger={isButtonRightDanger}
          isButtonRightLoading={isButtonRightLoading}
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
          title={title}
        />
      }
      description={title}
      isOpen={isOpen}
      onOpenChange={handleClose}
    />
  );
};
