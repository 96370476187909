import { endOfDay, formatISO, parse, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Layout, LoadingScreen, SideSheet } from '@/components';
import { DateFormats } from '@/config/language';
import { useGetOrders } from '@/features/activity/services';
import { usePageTitle } from '@/hooks/use-page-title';

import { ActivityHeader } from './activity-header';
import { Filters } from './filters';
import { OrderDetails } from './order-details';
import { OrdersTable } from './order-table';
import { Styled } from './styles';
import { getOrderById, getTotalActiveFilters, sortOrdersByDate } from './utils';
import { getOrderQuery } from './utils/get-order-query';
import { getOrderSubhead } from './utils/get-order-subhead';

import type { TFilters } from './filters';
import type { FilterFields } from './filters/filters';
import type { TResponseFilters, TSearchQuery } from './types';
import type { OrderServiceTypes } from '@/features/activity/services';

const parseFromDate = (date: string) => {
  const parsedDate = parse(date, DateFormats.DAY_MONTH_YEAR, new Date());
  return startOfDay(parsedDate);
};

const parseToDate = (date: string) => {
  const parsedDate = parse(date, DateFormats.DAY_MONTH_YEAR, new Date());
  return endOfDay(parsedDate);
};

const initialSearchQuery = {
  category: '',
  query: '',
};

export const Activity = () => {
  usePageTitle('Activity');
  const auth = useAuth();
  const [searchQuery, setSearchQuery] =
    useState<TSearchQuery>(initialSearchQuery);
  const [formattedFilters, setFormattedFilters] = useState<TResponseFilters>(
    {}
  );
  const [filters, setFilters] = useState<TFilters>();
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [isOpenOrderDetails, setIsOpenOrderDetails] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] =
    useState<OrderServiceTypes.TOrder | null>(null);

  const {
    data: ordersList,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading: isLoadingOrders,
  } = useGetOrders({
    params: {
      ...formattedFilters,
      ...getOrderQuery(searchQuery),
      status: ['pending', 'finalized', 'aborted'],
    },
  });

  if (auth.isLoading) {
    return (
      <Layout>
        <LoadingScreen />
      </Layout>
    );
  }

  const orders = sortOrdersByDate(
    ordersList?.pages.flatMap((page) => page.orders)
  );

  const handleViewOrder = (id: string) => {
    const order = getOrderById(id, orders);
    if (!!order) {
      setSelectedOrder(order);
      setIsOpenOrderDetails(true);
    }
  };

  const handleFilter = (filters: TFilters) => {
    const cleanedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        acc[key as FilterFields] = value === '' ? undefined : value;
        return acc;
      },
      {} as TFilters
    );

    const formattedFilters: TResponseFilters = {
      from_date: cleanedFilters.dateFrom
        ? formatISO(parseFromDate(cleanedFilters.dateFrom))
        : undefined,
      max_amount: cleanedFilters.maxAmount
        ? Number.parseFloat(cleanedFilters.maxAmount) * 100
        : undefined,
      min_amount: cleanedFilters.minAmount
        ? Number.parseFloat(cleanedFilters.minAmount) * 100
        : undefined,
      payment_context: cleanedFilters.type,
      to_date: cleanedFilters.dateTo
        ? formatISO(parseToDate(cleanedFilters.dateTo))
        : undefined,
    };

    setFormattedFilters(formattedFilters);
    setFilters(cleanedFilters);
    setIsOpenFilters(false);
  };

  const handleClearFilters = () => {
    setFormattedFilters({});
    setFilters(undefined);
  };

  const handleOnClearSearch = () => {
    setSearchQuery((query) => ({ category: query.category, query: '' }));
  };

  const handleOnOpenFilters = () => {
    setIsOpenFilters(true);
  };

  return (
    <Layout>
      <Styled.ActivityWrapper>
        <Styled.HeaderContainer>
          <ActivityHeader
            filterNumber={getTotalActiveFilters(formattedFilters)}
            initialSearchQuery={searchQuery}
            onClearSearch={handleOnClearSearch}
            onOpenFilters={handleOnOpenFilters}
            onSearch={setSearchQuery}
            orderSubhead={getOrderSubhead(formattedFilters, searchQuery?.query)}
          />
        </Styled.HeaderContainer>
        <Styled.TableWrapper>
          <OrdersTable
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isError={isError}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoadingOrders}
            onSelectOrder={handleViewOrder}
            orders={orders}
          />
        </Styled.TableWrapper>
      </Styled.ActivityWrapper>
      <Filters
        filterCount={getTotalActiveFilters(formattedFilters)}
        filters={filters}
        isOpen={isOpenFilters}
        onClearFilters={handleClearFilters}
        onClose={() => setIsOpenFilters(false)}
        onFilter={handleFilter}
      />
      <SideSheet
        onClose={() => setIsOpenOrderDetails(false)}
        open={isOpenOrderDetails}
        title="Activity details"
      >
        {!!selectedOrder && <OrderDetails order={selectedOrder} />}
      </SideSheet>
    </Layout>
  );
};
