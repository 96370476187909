export const MoreIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08276 6L14.8001 11.7173C14.8373 11.7544 14.8667 11.7985 14.8868 11.847C14.9069 11.8955 14.9173 11.9475 14.9173 12C14.9173 12.0525 14.9069 12.1045 14.8868 12.153C14.8667 12.2015 14.8373 12.2456 14.8001 12.2827L9.08276 18"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
);
