import { genericContent } from '@/config/language';

import { Styled } from './styles';

const focusableItems = [
  'a[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  '[tabindex="0"]',
];

export const SkipLink = ({
  id,
  refToFocus,
}: {
  id: string;
  refToFocus: HTMLElement | null;
}) => {
  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    // Focus on the first interactive child element
    const focusableElements = refToFocus?.querySelectorAll(
      focusableItems.join(',')
    ) as NodeListOf<HTMLElement>;

    if (focusableElements) {
      for (const el of focusableElements) {
        if (el.checkVisibility({ checkVisibilityCSS: true })) {
          el.focus();
          el.scrollTo({
            top: el.getBoundingClientRect().top,
          });
          break;
        }
      }
    }
  };

  return (
    <Styled.SkipButton
      aria-label="Skip to main content"
      href={`#${id}`}
      onClick={handleOnClick}
    >
      {genericContent.SKIP_CONTENT}
    </Styled.SkipButton>
  );
};
