import styled, { css, keyframes } from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const skeletonLoading = keyframes`
  0% {
    background-color: var(--colors-surfaceLowest);
  }
  100% {
    background-color: var(--colors-surfaceHighest);
  }
`;

const skeletonStyles = css`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: var(--radii-md);
`;

const LoadingCard = styled.div`
  align-items: center;
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-md);
  display: flex;
  gap: var(--space-m);
  justify-content: space-between;
  padding: var(--space-m) var(--space-xs);
  width: 100%;
`;

const CardLoader = styled.div`
  aspect-ratio: 79 / 50;
  width: 6rem;
  ${skeletonStyles};

  @media (min-width: ${BreakpointRems.xs}) {
    width: 10rem;
    height: 6.3rem;
  }
`;

const CardWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 3;
  gap: var(--space-s);
`;

const TextLoaderLong = styled.div`
  flex: 1;
  height: 1rem;
  margin-bottom: var(--space-xs);
  ${skeletonStyles};
`;

const TextLoaderShort = styled.div`
  flex: 1;
  max-width: 4rem;
  height: 1rem;
  margin-bottom: var(--space-xs);
  ${skeletonStyles};
`;

const TextWrapper = styled.div`
  flex: 1 1 100%;
`;

export const Styled = {
  CardLoader,
  CardWrapper,
  LoadingCard,
  TextLoaderLong,
  TextLoaderShort,
  TextWrapper,
};
