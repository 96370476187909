import { useTheme } from '@/contexts/theme';

import { Styled } from './styles';

export const TopBarLoggedOut = () => {
  const { theme } = useTheme();

  return (
    <Styled.TopBar>
      <img alt="flypay logo" src={theme.icon.large} />
    </Styled.TopBar>
  );
};
