const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const authConfig = {
  logoUrl:
    'https://play-lh.googleusercontent.com/EJtlKJEZ0oWsXN5SshWFgUwXKuHVx-n6GH_0UkOcGRQZR-dM40iZLaEbs5oMwN13Dg',
  oidc: {
    authority: import.meta.env.VITE_REACT_APP_AUTH_AUTHORITY,
    client_id: import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID,
    onSigninCallback,
    post_logout_redirect_uri: `${window.location.origin}/logged-out`,
    scope: import.meta.env.VITE_REACT_APP_AUTH_SCOPE,
    skipUserInfo: true,
  },
};
