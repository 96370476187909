import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Layout, LoadingScreen, Title } from '@/components';
import { usePageTitle } from '@/hooks/use-page-title';

import { Styled } from './styles';

import { AddressSettings } from './index';

export const Addresses = () => {
  usePageTitle('Addresses');
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth.isLoading) {
      setLoading(false);
    }
  }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading]);

  return (
    <Layout>
      <Styled.Container>
        <Styled.TitleWrapper>
          <Title title="Addresses" />
        </Styled.TitleWrapper>
        <Styled.ContentWrapper>
          {loading ? <LoadingScreen /> : <AddressSettings />}
        </Styled.ContentWrapper>
      </Styled.Container>
    </Layout>
  );
};
