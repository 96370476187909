import styled, { css } from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const textStyles = css`
  color: var(--colors-onSurfaceA);
  font-family: var(--fonts-sans);
  line-height: var(--lineHeights-half);
`;

const LayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--colors-surfaceLow);
  display: flex;
  flex-direction: column;
  align-items: center;

  [aria-haspopup='dialog'] {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  padding-top: 7dvh;
`;

const Title = styled.h1`
  ${textStyles}
  font-size: 2.25rem;
  font-weight: var(--fontWeights-bold);
  margin: 0 0 var(--space-m);
  text-align: center;
`;

const Subheader = styled.p`
  ${textStyles}
  font-size: var(--fontSizes-20);
  font-weight: var(--fontWeights-bold);
  margin: 0 0 var(--space-2xl);
  text-align: center;
`;

const Panel = styled.div`
  max-width: 580px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xl);
  background-color: var(--colors-surfaceLowest);
  border-radius: var(--radii-sm);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 2%);
`;

const PanelHeader = styled.h2`
  ${textStyles}
  font-size: var(--fontSizes-20);
  font-weight: var(--fontWeights-bold);
  margin: 0;
`;

const FooterBar = styled.div`
  align-items: center;
  background: var(--colors-surfaceHigh);
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  justify-content: center;
  left: 0;
  padding: var(--space-l) var(--space-m);
  position: absolute;
  right: 0;
  width: 100%;

  @media (min-width: ${BreakpointRems.xs}) {
    flex-direction: row;
    gap: var(--space-xl);
  }

  @media (min-width: ${BreakpointRems.sm}) {
    gap: var(--space-2xl);
  }
`;

const FooterLink = styled.a`
  ${textStyles}
  text-decoration: underline;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-onSurfaceB);

  @media (min-width: ${BreakpointRems.sm}) {
    font-size: var(--fontSizes-16);
  }
`;

export const Styled = {
  ContentWrapper,
  FooterBar,
  FooterLink,
  LayoutWrapper,
  Panel,
  PanelHeader,
  Subheader,
  Title,
};
