import { ErrorSection, TopBarLoggedOut } from '@/components';
import { genericContent } from '@/config/language';

import { Styled } from './styles';

export const NotFound = () => (
  <Styled.LayoutWrapper>
    <TopBarLoggedOut />
    <ErrorSection
      message={genericContent.PAGE_NOT_FOUND_MESSAGE}
      title={genericContent.PAGE_NOT_FOUND_TITLE}
    />
  </Styled.LayoutWrapper>
);
