import { Spinner } from '@/components';

import { Styled } from './styles';

interface IPanelCardProps {
  button?: React.ReactNode;
  children?: React.ReactNode;
  isLoading?: boolean;
  title?: string;
}

export const Panel = ({
  button,
  children,
  isLoading,
  title,
}: IPanelCardProps) => {
  return (
    <Styled.PanelWrapper>
      <Styled.PanelTitleWrapper>
        <Styled.PanelTitle>{title}</Styled.PanelTitle>
        {!button && <Styled.ButtonWrapper>{button}</Styled.ButtonWrapper>}
      </Styled.PanelTitleWrapper>
      {isLoading ? (
        <Styled.SpinnerWrapper>
          <Spinner color="transparent" size={25} />
        </Styled.SpinnerWrapper>
      ) : (
        children
      )}
    </Styled.PanelWrapper>
  );
};
