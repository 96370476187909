export const regex = {
  // name regex from engagement
  ALPHABETS_ONLY: /^[^\d!#$%&()*+/:<=>?@[\\\]^_{|}~¡¢£§ª«¶º»æ÷†‡•…™∞≠≤≥]+$/i,
  // * only Oz mobile number, supports national and international code
  AU_MOBILE_NUMBER: /^(04|\+614)(\d{8})$/,
  AU_POSTCODE: /^(0[289]\d{2})|([1-9]\d{3})$/,
  // * allows for 3 or 4 digits
  CARD_SECURITY_CODE: /^\d{3,4}$/,
  FOUR_DIGITS: /^\d{4}$/,
  MYCOLES_BARCODE: /^\d(\s)?\d{6}(\s)?\d{6}$/,
  NUMERIC_ONLY: /^\d*$/,
  SPACES_AND_TABS: /[\s()]/g,
};
