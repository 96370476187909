import React from 'react';
import { get, useFormContext } from 'react-hook-form';

import { DangerIcon } from '@/components/icons';

import { Styled } from './styles';

import type {
  FieldErrors,
  FieldName,
  Message,
  MultipleFieldErrors,
} from 'react-hook-form';

type FieldValuesFromFieldErrors<TFieldErrors> =
  TFieldErrors extends FieldErrors<infer TFieldValues> ? TFieldValues : never;

type TErrorMessage<TFieldErrors extends FieldErrors> = {
  errors?: TFieldErrors;
  id?: string;
  message?: Message;
  name: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
  render?: (data: {
    error: unknown;
    message: Message;
    messages?: MultipleFieldErrors;
  }) => React.ReactNode;
};

export const ErrorMessage = <TFieldErrors extends FieldErrors>({
  errors,
  id,
  message,
  name,
  render,
}: TErrorMessage<TFieldErrors>) => {
  const methods = useFormContext();
  const error = get(errors || methods.formState.errors, name);
  const testId = {
    ...(import.meta.env.NODE_ENV !== 'production' && {
      'data-testid': 'error',
    }),
  };

  if (!!render) {
    return render({
      error,
      message: error?.message || message,
      messages: error?.types,
    }) as React.ReactElement;
  }

  return (
    <Styled.ErrorMessageContainer $hasError={!!error?.message || !!message}>
      <DangerIcon />
      <Styled.ErrorMessageText
        aria-live="polite"
        id={id}
        role="alert"
        {...testId}
      >
        {error?.message || message}
      </Styled.ErrorMessageText>
    </Styled.ErrorMessageContainer>
  );
};
