import type { IIcon } from './typings';
export const DangerIcon = ({
  color = '#d04114',
  height = 16,
  width = 18,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      style={{ flexShrink: 0 }}
      viewBox="0 0 18 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.275 12.492l-6.45-11c-.767-1.317-2.683-1.317-3.45 0l-6.433 11c-.775 1.333.183 3.008 1.725 3.008h12.875c1.541 0 2.508-1.683 1.725-3.008h.008zM8.308 4.908c0-.4.334-.725.725-.725.392 0 .725.334.725.725v4.367a.73.73 0 01-.725.725.736.736 0 01-.725-.725V4.908zm.725 8.009A.914.914 0 018.117 12c0-.508.408-.917.916-.917.509 0 .917.409.917.917a.914.914 0 01-.917.917z"
        fill={color}
      />
    </svg>
  );
};
