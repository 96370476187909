import type { IIcon } from './typings';

export const ExclamationMarkIcon = ({
  backgroundColor = 'black',
  color = 'white',
  height,
  size,
  width,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height || size || 80}
      viewBox="0 0 80 80"
      width={width || size || 80}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={backgroundColor} height="80" rx="40" width="80" />
      <path
        d="M40 66C42.0336 66 43.6822 64.3514 43.6822 62.3179C43.6822 60.2843 42.0336 58.6357 40 58.6357C37.9664 58.6357 36.3179 60.2843 36.3179 62.3179C36.3179 64.3514 37.9664 66 40 66Z"
        fill={color}
      />
      <path
        clipRule="evenodd"
        d="M40 14C41.2654 14 42.2911 15.0258 42.2911 16.2911V49.4304C42.2911 50.6957 41.2654 51.7215 40 51.7215C38.7347 51.7215 37.7089 50.6957 37.7089 49.4304V16.2911C37.7089 15.0258 38.7347 14 40 14Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
