import type { IIcon } from './typings';

export const ExclamationMarkTriangleIcon = ({
  color = 'var(--colors-surfaceInverse)',
  height,
  size,
  width,
}: IIcon) => (
  <svg
    fill="none"
    height={height || size || 80}
    viewBox="0 0 87 80"
    width={width || size || 87}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.1758 4.13191C42.1953 2.3195 44.8047 2.31951 45.8242 4.13191L86.2646 76.026C87.2645 77.8036 85.9799 80 83.9404 80H3.05959C1.02006 80 -0.264514 77.8036 0.735392 76.026L41.1758 4.13191Z"
      fill={color}
    />
    <path
      d="M43.4998 66.668C44.673 66.668 45.6241 65.7169 45.6241 64.5437C45.6241 63.3704 44.673 62.4193 43.4998 62.4193C42.3266 62.4193 41.3755 63.3704 41.3755 64.5437C41.3755 65.7169 42.3266 66.668 43.4998 66.668Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M43.4998 36.668C44.2298 36.668 44.8216 37.2598 44.8216 37.9898V57.1086C44.8216 57.8386 44.2298 58.4304 43.4998 58.4304C42.7698 58.4304 42.178 57.8386 42.178 57.1086V37.9898C42.178 37.2598 42.7698 36.668 43.4998 36.668Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);
