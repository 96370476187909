import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

interface IEmailVerifyRequest {
  verification_code: string;
}

export function useEmailVerify(
  options?: UseMutationOptions<
    unknown,
    TBaasError,
    IEmailVerifyRequest,
    unknown
  >
) {
  const auth = useAuth();

  const emailVerifyRequest = async (payload: IEmailVerifyRequest) =>
    await request(
      endpoints.profile.PROFILE_EMAIL_VERIFY,
      auth,
      {
        data: payload,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: ProfileEndpointKeys.EMAIL_VERIFY,
        transaction: profileTransactionKeys.emailVerify,
      }
    );

  return useMutation<unknown, TBaasError, IEmailVerifyRequest, unknown>({
    mutationFn: emailVerifyRequest,
    mutationKey: profileQueryKeys.emailVerify,
    ...options,
  });
}
