import { Spinner } from '@/components';

import { Styled } from './styles';

import type { ButtonLevel, ButtonSize, ButtonVariant } from '@b707/ponyta';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

interface IButtonSpinnerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  disabled?: boolean;
  level?: ButtonLevel;
  loading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  spinnerColor?: string;
  spinnerSize?: number;
  spinnerThickness?: number;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariant;
}

export const ButtonSpinner = ({
  children,
  disabled,
  level,
  loading,
  onClick,
  size,
  spinnerColor,
  spinnerSize = 14,
  spinnerThickness,
  type,
  variant,
  ...props
}: IButtonSpinnerProps) => {
  return (
    <Styled.StyledButton
      $loading={loading}
      disabled={!!disabled}
      level={level}
      onClick={!loading ? onClick : undefined}
      size={size}
      type={type}
      variant={variant}
      {...props}
    >
      {loading && (
        <Spinner
          $secondaryColor={spinnerColor}
          $thickness={spinnerThickness}
          color="transparent"
          size={spinnerSize}
        />
      )}
      {children}
    </Styled.StyledButton>
  );
};
