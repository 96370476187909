const formatMonth = (month: string) => {
  return month.length === 1 ? `0${month}` : month;
};

const formatYear = (year: string) => {
  return year.length > 2 ? year.slice(-2) : year;
};

export function formatCardExpiryDate(date: string) {
  const [m, y] = date.split('/');
  const month = m?.trim();
  const year = y?.trim();

  // if month is 1-2 digits (0-9) and year is 2-4 digits (0-9), format them
  if (month && year && /^\d{1,2}$/.test(month) && /^\d{2,4}$/.test(year)) {
    const formattedMonth = formatMonth(month);
    const formattedYear = formatYear(year);

    return formattedMonth + '/' + formattedYear;
  }

  return date;
}
