import { Button } from '@/components';

import { Styled } from './styles';
interface IDialogContentProps {
  onClick?: () => void;
}
export const DialogContent = ({ onClick }: IDialogContentProps) => {
  return (
    <Styled.ActionDialogWrapper>
      <Styled.ActionDialogHeader>
        <Styled.ActionDialogTitle>
          We take security very seriously
        </Styled.ActionDialogTitle>
        <Styled.ActionDialogDescription>
          <p>
            Your data is protected using industry leading security and all
            important information is encrypted so people can’t access it. Plus,
            we authenticate every transaction with real time fraud monitoring.
          </p>
          <p>
            You’ll need to hold up your end of the bargain too though. It might
            seem obvious, but don’t send money to people you personally don’t
            know and trust. And please keep your account details and passcode
            secure.
          </p>
          <p>
            Also, to back you up if things go wrong, we have a dedicated support
            team which will assist in resolving fraudulent issues which could
            arise.
          </p>
        </Styled.ActionDialogDescription>
      </Styled.ActionDialogHeader>

      <Button onClick={onClick} size="medium">
        Continue
      </Button>
    </Styled.ActionDialogWrapper>
  );
};
