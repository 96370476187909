import type { IIcon } from './typings';

export const CloseFilledIcon = ({
  color = 'currentColor',
  height = 24,
  width = 24,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M8.66683 8.66504L15.3335 15.3317"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="1.4"
    />
    <path
      d="M15.334 8.66504L8.66736 15.3317"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="1.4"
    />
  </svg>
);
