import { EditIcon } from '@/components/icons';

import { Styled } from './styles';

interface INicknameEditButton {
  onClick: () => void;
}

export const NicknameEditButton = ({ onClick }: INicknameEditButton) => {
  return (
    <Styled.EditButton aria-label="Edit Nickname" onClick={onClick}>
      <EditIcon height={24} width={24} /> Edit
    </Styled.EditButton>
  );
};
