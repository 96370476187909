import { create } from 'zustand';

import { createSelectors } from './create-selectors';

import type { ProfileTypes } from '@/features/profile';

type TProfileStoreActions = {
  setProfile: (profile: ProfileTypes.TUser) => void;
};

interface IProfileStore {
  actions: TProfileStoreActions;
  currentWalletId: string | null;
  profile: ProfileTypes.TUser | null;
}

const useProfileStoreBase = create<IProfileStore>((set) => ({
  actions: {
    setProfile: (profile) =>
      set({ currentWalletId: profile.current_wallet_id, profile }),
  },
  currentWalletId: null,
  profile: null,
}));

export const useProfileStore = createSelectors(useProfileStoreBase);
