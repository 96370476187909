import type { IIcon } from './typings';

export const PadlockIcon = ({
  color = 'currentColor',
  height = 18,
  width = 18,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 18 18"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72429 10.3973C9.72429 9.99731 9.40007 9.6731 9.00014 9.6731C8.6002 9.6731 8.27599 9.99731 8.27599 10.3973V13.8841C8.27599 14.2841 8.6002 14.6083 9.00014 14.6083C9.40007 14.6083 9.72429 14.2841 9.72429 13.8841V10.3973Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M3.71254 5.28744C3.71254 2.36727 6.07981 0 8.99998 0C11.9202 0 14.2874 2.36727 14.2874 5.28744V6.37645C16.1945 6.79889 17.6208 8.49995 17.6208 10.5341V13.7415C17.6208 16.0934 15.7142 18 13.3623 18H4.63761C2.28573 18 0.37915 16.0934 0.37915 13.7415V10.5341C0.37915 8.49994 1.80545 6.79887 3.71254 6.37644V5.28744ZM12.8391 5.28744V6.27569H5.16084V5.28744C5.16084 3.16714 6.87968 1.4483 8.99998 1.4483C11.1203 1.4483 12.8391 3.16714 12.8391 5.28744ZM13.3623 7.72398H4.63761C3.0856 7.72398 1.82745 8.98214 1.82745 10.5341V13.7415C1.82745 15.2936 3.0856 16.5517 4.63761 16.5517H13.3623C14.9143 16.5517 16.1725 15.2936 16.1725 13.7415V10.5341C16.1725 8.98214 14.9143 7.72398 13.3623 7.72398Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
