import type { IIcon } from './typings';
export const ErrorIcon = ({
  color = '#CD4014',
  height = 86,
  width = 80,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 86 80"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5137 6.19787C41.0429 3.47927 44.9571 3.47926 46.4863 6.19787L84.6469 74.039C86.1468 76.7054 84.2199 80 81.1606 80H4.83939C1.78008 80 -0.146771 76.7054 1.35309 74.039L39.5137 6.19787Z"
        fill={color}
      />
      <path
        d="M43 66.667C44.1733 66.667 45.1244 65.7159 45.1244 64.5427C45.1244 63.3695 44.1733 62.4184 43 62.4184C41.8268 62.4184 40.8757 63.3695 40.8757 64.5427C40.8757 65.7159 41.8268 66.667 43 66.667Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M43 36.667C43.7301 36.667 44.3218 37.2588 44.3218 37.9888V57.1076C44.3218 57.8376 43.7301 58.4294 43 58.4294C42.27 58.4294 41.6783 57.8376 41.6783 57.1076V37.9888C41.6783 37.2588 42.27 36.667 43 36.667Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
