import { forwardRef, useId } from 'react';

import { ErrorIcon } from '@/components/icons';

import { Styled } from './styles';

type Ref = HTMLInputElement;

interface IDateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  afterIcon?: React.ReactNode;
  beforeIcon?: React.ReactNode;
  error?: string;
  iconLabel?: string;
  onClickAfterIcon?: () => void;
  onClickBeforeIcon?: () => void;
}

export const FilterInput = forwardRef<Ref, IDateInputProps>(
  (
    {
      afterIcon,
      beforeIcon,
      error,
      iconLabel,
      max,
      min,
      name,
      onBlur,
      onChange,
      onClickAfterIcon,
      onClickBeforeIcon,
      value,
      ...props
    },
    ref
  ) => {
    const fieldErrorId = useId();
    const handleClick = (e: React.MouseEvent, iconType: 'after' | 'before') => {
      e.preventDefault();
      iconType === 'after' ? onClickAfterIcon?.() : onClickBeforeIcon?.();
    };

    const BeforeIcon: React.ElementType = !!onClickBeforeIcon
      ? Styled.IconWrapperButton
      : Styled.IconWrapper;

    const AfterIcon: React.ElementType = !!onClickAfterIcon
      ? Styled.IconWrapperButton
      : Styled.IconWrapper;

    return (
      <Styled.InputErrorWrapper>
        <Styled.InputBox $hasError={!!error}>
          {!!beforeIcon && (
            <BeforeIcon
              aria-expanded={props['aria-expanded']}
              aria-label={!!onClickBeforeIcon ? iconLabel : undefined}
              onClick={(e: React.MouseEvent) => handleClick(e, 'before')}
              type={onClickBeforeIcon ? 'button' : undefined}
            >
              {beforeIcon}
            </BeforeIcon>
          )}
          <Styled.FilterInput
            {...props}
            aria-describedby={fieldErrorId}
            aria-errormessage={fieldErrorId}
            aria-expanded={undefined}
            aria-invalid={!!error}
            max={max}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
          />
          {!!afterIcon && (
            <AfterIcon
              aria-expanded={props['aria-expanded']}
              aria-label={!!onClickAfterIcon ? iconLabel : undefined}
              onClick={(e: React.MouseEvent) => handleClick(e, 'after')}
              type={onClickAfterIcon ? 'button' : undefined}
            >
              {afterIcon}
            </AfterIcon>
          )}
        </Styled.InputBox>
        {!!error && (
          <Styled.InputError id={fieldErrorId} role="alert">
            <ErrorIcon height={20} width={20} />
            {error}
          </Styled.InputError>
        )}
      </Styled.InputErrorWrapper>
    );
  }
);

FilterInput.displayName = 'FilterInput';
