import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const InformationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-xl);
  padding: 0 var(--space-m);
  max-width: 580px;
  width: 100%;
`;

const Heading = styled.h1`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-32);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
  margin: 0;
  text-align: center;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
`;

export { Container, InformationWrapper, Heading, Paragraph };
