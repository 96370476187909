import styled from 'styled-components';

const LayoutWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-surfaceLow);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const Styled = {
  LayoutWrapper,
};
