export const endpoints = {
  FLYBUYS_COMPLETE_LINK: '/v1/payment-methods/loyalty/flybuys/complete-link',
  FLYBUYS_CREDENTIALS: '/v1/payment-methods/loyalty/flybuys/credentials',
  FLYBUYS_INITIATE_LINK: '/v1/payment-methods/loyalty/flybuys/initiate-link',
  FLYBUYS_REFRESH_CREDENTIALS:
    '/v1/payment-methods/loyalty/flybuys/credentials',
  LOYALTY_CARDS: '/v1/payment-methods/loyalty',
  LOYALTY_CARDS_BY_ID: (id: string) => `/v1/payment-methods/loyalty/${id}`,
  LOYALTY_CARD_BY_ID: (id: string) => `/v1/payment-methods/loyalty/${id}`,
  PAYMENT_METHODS: '/v1/payment-methods',
};
