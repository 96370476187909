export function formatCardNumber(input: string): string {
  const inputElement = document.getElementById(
    'cardNumber'
  ) as HTMLInputElement;
  let cursorPosition = inputElement?.selectionStart || 0;

  const value = input.replace(/\s+/g, '').replace(/\D/g, '');
  let formattedValue = '';
  for (let i = 0; i < value.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedValue += ' ';
      if (i < cursorPosition + (formattedValue.length - value.length)) {
        cursorPosition++;
      }
    }
    formattedValue += value.charAt(i);
  }
  if (inputElement) {
    inputElement.value = formattedValue;
    inputElement.selectionStart = inputElement.selectionEnd = cursorPosition;
  }

  return formattedValue;
}
