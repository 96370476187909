import type { IIcon } from './typings';

export const DownArrowIcon = ({
  color = 'currentColor',
  height = 14,
  width = 14,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 14 15"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.00021 11.2609C6.89191 11.2609 6.78805 11.2179 6.71147 11.1413L4.37814 8.80795C4.21868 8.64848 4.21868 8.38994 4.37815 8.23047C4.53761 8.07101 4.79616 8.07102 4.95562 8.23048L6.59188 9.86678L6.59188 3.61927C6.59188 3.39375 6.7747 3.21094 7.00021 3.21094C7.22573 3.21094 7.40854 3.39375 7.40854 3.61927V9.86678L9.04481 8.23048C9.20427 8.07102 9.46281 8.07101 9.62228 8.23047C9.78174 8.38994 9.78175 8.64848 9.62228 8.80795L7.28895 11.1413C7.21237 11.2179 7.10851 11.2609 7.00021 11.2609Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
