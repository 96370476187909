import { ProfileEnums } from '../enums';

import { findConsent } from './get-consent';

import type { ProfileTypes } from '../types/profile';

export function hasConsented(
  user: ProfileTypes.TUser | null = null,
  consentType: ProfileEnums.ProfileAgreementCategory
) {
  const consent = findConsent(user, consentType);
  return consent?.consent.granted ?? false;
}

export function hasConsentedToMarketing(
  user: ProfileTypes.TUser | null = null
): boolean {
  return hasConsented(
    user,
    ProfileEnums.ProfileAgreementCategory.MARKETING_COMMUNICATIONS
  );
}

export function hasConsentedToTerms(
  user: ProfileTypes.TUser | null = null
): boolean {
  return hasConsented(
    user,
    ProfileEnums.ProfileAgreementCategory.TERMS_AND_CONDITIONS
  );
}
