import { V2QrEnums } from '@/services';

import { getCard } from './get-card';

import type { TTransactionInfo } from '../../types';
import type { V2QrTypes } from '@/services';

export const getTransactions = (
  transactions: V2QrTypes.TTransaction[],
  status: `${V2QrEnums.OrderStatusV2}`
): TTransactionInfo[] => {
  const allTransactions = transactions.map((transaction) => {
    const type = transaction.transaction_type;
    return {
      amount: getAmount(transaction.amount, type),
      card: getCard(transaction),
      id: transaction.transaction_id,
      status: transaction.status,
      type,
    };
  });

  if (status == V2QrEnums.OrderStatusV2.ABORTED) {
    return allTransactions.filter(
      (transaction) =>
        transaction.status !== V2QrEnums.TransactionStatus.PENDING
    );
  } else {
    return allTransactions.filter(isValidTransaction);
  }
};

const isValidTransaction = (transaction: TTransactionInfo): boolean => {
  const { amount, status } = transaction;
  return (
    !(
      status === V2QrEnums.TransactionStatus.FAILED ||
      status === V2QrEnums.TransactionStatus.DECLINED
    ) &&
    (amount !== 0 || status === V2QrEnums.TransactionStatus.PENDING)
  );
};

export const getAmount = (
  amount: V2QrTypes.TAmountEntry,
  type: `${V2QrEnums.TransactionType}`
): number => {
  const parsedValue = Number.parseFloat(amount.value);
  const positiveAmount = Number.isNaN(parsedValue) ? 0 : parsedValue;

  return type === V2QrEnums.TransactionType.PURCHASE
    ? positiveAmount
    : -positiveAmount;
};
