import EventEmitter from 'events';
import process from 'process';

import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';

// use browser versions of these node packages
window.process = process;
window.EventEmitter = EventEmitter;

if (
  !import.meta.env.MODE.startsWith('development') &&
  !import.meta.env.MODE.startsWith('stage')
) {
  Sentry.init({
    dsn: 'https://ff7dd9e868dc4c3d8c63e6228dd9db92@o4504884912717824.ingest.sentry.io/4504909038288896',
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [
          400, 401, 403, 404, 405, 409, 417, 429, 500, 501, 502, 503, 504, 599,
        ],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    tracesSampleRate: 1,
  });
}

const container = document.querySelector('#root');
const root = createRoot(container as Element);

if (import.meta.env.MODE === 'development') {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} else {
  root.render(<App />);
}
