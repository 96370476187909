export enum ProfileEndpointKeys {
  EMAIL_INITIATE_CHANGE = 'EMAIL_INITIATE_CHANGE',
  EMAIL_RESEND_CHANGE = 'EMAIL_RESEND_CHANGE',
  EMAIL_VERIFY = 'EMAIL_VERIFY',
  MARKETING_SUBSCRIBE = 'MARKETING_SUBSCRIBE',
  MARKETING_UNSUBSCRIBE = 'MARKETING_UNSUBSCRIBE',
  MOBILE = 'MOBILE',
  MOBILE_CANCEL_CHANGE = 'MOBILE_CANCEL_CHANGE',
  MOBILE_INITIATE_CHANGE = 'MOBILE_INITIATE_CHANGE',
  MOBILE_RESEND_CHANGE = 'MOBILE_RESEND_CHANGE',
  MOBILE_VERIFY = 'MOBILE_VERIFY',
  PROFILE = 'PROFILE_GET',
  PROFILE_DELETE = 'PROFILE_DELETE',
  PROFILE_UPDATE = 'PROFILE_UPDATE',
}

export const profileTransactionKeys = {
  emailInitiateChange: 'Initiate Email Change',
  emailResendChange: 'Resend Email Change',
  emailVerify: 'Verify Email',
  marketingSubscribe: 'Subscribe to Marketing',
  marketingUnsubscribe: 'Subscribe to Marketing',
  mobileCancelChange: 'Cancel Mobile Change',
  mobileInitiateChange: 'Initiate Mobile Change',
  mobileResendChange: 'Resend Mobile Change',
  mobileVerify: 'Verify Mobile',
  profile: 'Get User Profile',
  profileDelete: 'Delete User Profile',
  profileUpdate: 'Update User Profile',
};

export enum AddressEndpointKeys {
  ADDRESSES = 'ADDRESSES',
  ADDRESS_ADD = 'ADDRESS_ADD',
  ADDRESS_DELETE = 'ADDRESS_DELETE',
  ADDRESS_UPDATE = 'ADDRESS_UPDATE',
}

export const addressTransactionKeys = {
  addressAdd: 'Add Address',
  addressDelete: 'Delete Address',
  addressUpdate: 'Update Address',
  addresses: 'Get Addresses',
};

export const profileQueryKeys = {
  emailInitiateChange: ['email-initiate-change'],
  emailResendChange: ['email-resend-change'],
  emailVerify: ['email-verify'],
  marketingSubscribe: ['marketing-subscribe'],
  mobileCancel: ['mobile-cancel'],
  mobileInitiateChange: ['mobile-initiate-change'],
  mobileResendChange: ['mobile-resend-change'],
  mobileVerify: ['mobile-verify'],
  profile: ['profile'],
  profileDelete: ['profile-delete'],
  profileUpdate: ['profile-update'],
};

export const addressQueryKeys = {
  addAddress: ['add-address'],
  addresses: ['addresses'],
  deleteAddress: ['delete-address'],
  updateAddress: ['update-address'],
};
