import { LoggedOut } from '@/components';
import { profileContent } from '@/features/profile';
import { usePageTitle } from '@/hooks/use-page-title';

export const TimedOut = () => {
  usePageTitle('Timed Out');
  return (
    <LoggedOut
      description={profileContent.TIMED_OUT_MESSAGE}
      title={profileContent.TIMED_OUT_TITLE}
      warning
    />
  );
};
