import { Styled } from './styles';

interface ITextFieldDummy {
  helpText?: React.ReactNode | string;
  label?: string;
  value: string;
}

export const TextFieldDummy = ({ helpText, label, value }: ITextFieldDummy) => {
  if (!value) {
    return null;
  }

  return (
    <Styled.InputWrapper>
      {label && <Styled.DummyLabel>{label}</Styled.DummyLabel>}
      <Styled.DummyInput>{value}</Styled.DummyInput>
      <Styled.HelpText>{helpText}</Styled.HelpText>
    </Styled.InputWrapper>
  );
};
