import type { TOption } from '@/components/dropdown';

export enum ViewOrderStatus {
  CANCELLED = 'Cancelled',
  PAID = 'Paid',
  PARTIALLY_REFUNDED = 'Partially Refunded',
  PENDING = 'Pending',
  REFUNDED = 'Refunded',
}

export enum ViewTransactionType {
  PAID = 'Paid',
  REFUNDED = 'Refunded',
  REVERSED = 'Reversed',
}
export enum FormattedSchemes {
  AMEX = 'American Express',
  EFTPOS = 'eftpos',
  MASTERCARD = 'Mastercard',
  PAYMENT = 'Payment',
  VISA = 'Visa',
}

export const SearchOptions: TOption[] = [
  { label: 'Merchant name', value: 'merchantName' },
  { label: 'Order ID', value: 'orderId' },
];
