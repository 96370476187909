import isEmpty from 'lodash.isempty';
import { get, useController, useFormContext } from 'react-hook-form';

import { Dropdown, ErrorMessage } from '@/components';

import { Styled } from './styles';

import type { TSelectFieldProps } from './types';
import type { ChangeEvent } from 'react';

export const SelectField = ({
  disabled,
  errorId,
  id,
  label,
  labelDirection,
  name,
  onChange,
  options,
  placeholder,
  required,
}: TSelectFieldProps) => {
  const { control } = useFormContext();

  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules: { required: true },
  });

  const error = !isEmpty(errors) && get(errors, name);
  const option = options.find((opt) => opt.value === field.value);
  const selected =
    option ?? (field.value ? { label: field.value, value: field.value } : null);

  return (
    <Styled.Wrapper>
      <Dropdown
        disabled={disabled}
        hasErrors={!!error}
        id={id}
        label={label}
        labelDirection={labelDirection}
        onChange={(selectedItem) => {
          field?.onChange(selectedItem?.value);
          onChange?.({
            target: { value: selectedItem?.value ?? '' },
          } as ChangeEvent<HTMLInputElement>);
        }}
        options={options}
        placeholder={placeholder}
        required={required}
        selected={selected}
      />
      {!!error && <ErrorMessage errors={errors} id={errorId} name={name} />}
    </Styled.Wrapper>
  );
};
