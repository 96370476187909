import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Container = styled.div``;

const HeaderDetailsWrapping = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  @media (min-width: ${BreakpointRems.sm}) {
    gap: 18px;
  }
`;
const LoadMoreMessage = styled.p`
  color: var(--colors-onSurfaceC);
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-none);
  margin: 1rem 0 0 0;
  text-align: center;

  a {
    color: var(--colors-onSurfaceC);
  }
`;

export const Styled = {
  Container,
  HeaderDetailsWrapping,
  LoadMoreMessage,
};
