import { format } from 'date-fns';

import { DateFormats } from '@/config/language';
import { MerchantLogo } from '@/features/activity';
import { formatCurrency } from '@/utils/format/currency';

import { getOrderInfo } from '../../utils';

import { Styled } from './styles';

import type { OrderServiceTypes } from '@/features/activity/services';

type TMobileOrderProps = {
  onClick?: (id: string) => void;
  order: OrderServiceTypes.TOrder;
};

const formatDate = (date: Date) => {
  return format(date, DateFormats.DAY_MONTH_YEAR);
};

export const MobileOrder = ({ onClick, order }: TMobileOrderProps) => {
  const onClickOrder = (id: string) => () => {
    onClick?.(id);
  };

  const {
    amount,
    hasRefund,
    merchantId,
    merchantName,
    orderId,
    orderReference,
    payments,
  } = getOrderInfo(order);

  return (
    <Styled.MobileOrderWrapper onClick={onClickOrder(orderId)}>
      <Styled.MobileOrderHeader>
        <MerchantLogo merchantId={merchantId} merchantName={merchantName} />
        <Styled.HeaderNameWrapper>
          <Styled.Text>{merchantName}</Styled.Text>
          <Styled.IdText>{orderReference ?? orderId}</Styled.IdText>
        </Styled.HeaderNameWrapper>

        <Styled.ValueText>{formatCurrency(amount)}</Styled.ValueText>
      </Styled.MobileOrderHeader>

      {hasRefund && (
        <>
          <Styled.MobileOrderDivider />
          <Styled.MobilePaymentInfoWrapper>
            {payments.map((payment) => {
              return (
                <Styled.MobilePaymentItem
                  key={`mobile-order-list-item-payment-${payment.id}`}
                >
                  <Styled.MobilePaymentInfoText>
                    {formatDate(payment.date)}
                  </Styled.MobilePaymentInfoText>
                  <Styled.MobilePaymentInfoText>
                    {payment.hasRefund ? 'Refunded' : 'Paid'}
                  </Styled.MobilePaymentInfoText>
                  <Styled.MobilePaymentValueText>
                    {formatCurrency(payment.amount)}
                  </Styled.MobilePaymentValueText>
                </Styled.MobilePaymentItem>
              );
            })}
          </Styled.MobilePaymentInfoWrapper>
        </>
      )}
    </Styled.MobileOrderWrapper>
  );
};
