import { format, isValid as isValidDate } from 'date-fns';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, DatePicker, SideSheet } from '@/components';
import { CalendarIcon } from '@/components/icons';
import { DateFormats } from '@/config/language';

import { defaultFilters } from './config';
import { FilterInput } from './filter-input';
import { Styled } from './styles';
import { formatDateValue, getDateRange, parseDate } from './utils';
import {
  validateAmount,
  validateAmountRange,
  validateDate,
  validateDateRange,
} from './validation';

import type { DateRange } from 'react-day-picker';

export enum FilterFields {
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  MAX_AMOUNT = 'maxAmount',
  MIN_AMOUNT = 'minAmount',
  TYPE = 'type',
}

export type TFilters = Record<`${FilterFields}`, string | undefined>;

type TFilterProps = {
  filterCount?: string;
  filters?: TFilters;
  isOpen: boolean;
  onClearFilters?: () => void;
  onClose: () => void;
  onFilter?: (filters: TFilters) => void;
};

export const Filters = ({
  filterCount,
  filters,
  isOpen,
  onClearFilters,
  onClose,
  onFilter,
}: TFilterProps) => {
  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<TFilters>({
    defaultValues: defaultFilters,
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [viewMonth, setViewMonth] = useState(new Date());
  const calendarRef = useRef<HTMLDivElement>(null);
  const dateRange = getDateRange(watch());

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const handleCalendarChange = (dateRange?: DateRange, selectedDay?: Date) => {
    const { from, to } = dateRange ?? {};
    clearErrors();

    // Reset dates to new day if range is already set
    if (!!watch(FilterFields.DATE_FROM) && !!watch(FilterFields.DATE_TO)) {
      setValue(
        FilterFields.DATE_FROM,
        selectedDay ? format(selectedDay, DateFormats.DAY_MONTH_YEAR) : ''
      );
      setValue(FilterFields.DATE_TO, '');
    } else {
      setValue(
        FilterFields.DATE_FROM,
        from ? format(from, DateFormats.DAY_MONTH_YEAR) : ''
      );
      setValue(
        FilterFields.DATE_TO,
        to ? format(to, DateFormats.DAY_MONTH_YEAR) : ''
      );
    }
  };

  const updateViewMonth = (value: string) => {
    const date = parseDate(value);

    if (isValidDate(date)) {
      !!date && setViewMonth(date);
    }
  };

  const handleClose = () => {
    reset(filters);
    onClose();
    setShowCalendar(false);
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClearFilters?.();
    onClose();
    reset();
    setShowCalendar(false);
  };

  const hasDateError = !!errors.dateFrom?.message || !!errors.dateTo?.message;

  return (
    <SideSheet
      onClose={handleClose}
      open={isOpen}
      title={`Filters ${!!filterCount ? filterCount : ''}`}
    >
      <Styled.FilterForm
        onSubmit={handleSubmit((data) => {
          setShowCalendar(false);
          onFilter?.(data);
        })}
      >
        <div>
          <Styled.FilterGroup ref={calendarRef}>
            <Styled.GroupLabel>Date</Styled.GroupLabel>
            <Styled.InputLabelWrapper>
              <Styled.InputLabel htmlFor={FilterFields.DATE_FROM}>
                From (DD/MM/YYYY)
              </Styled.InputLabel>
              <Styled.InputLabel htmlFor={FilterFields.DATE_TO}>
                To (DD/MM/YYYY)
              </Styled.InputLabel>
            </Styled.InputLabelWrapper>
            <Styled.InputWrapper $hasError={hasDateError}>
              <FilterInput
                {...register(FilterFields.DATE_FROM, {
                  onBlur: (e) => {
                    setValue(e.target.name, formatDateValue(e.target.value));
                    clearErrors(FilterFields.DATE_TO);
                    updateViewMonth(e.target.value);
                  },
                  validate: {
                    validDate: validateDate,
                    validRange: validateDateRange,
                  },
                })}
                afterIcon={<CalendarIcon />}
                aria-expanded={showCalendar ? 'true' : 'false'}
                error={errors.dateFrom?.message}
                iconLabel={
                  !!watch(FilterFields.DATE_FROM)
                    ? `Change from date, ${watch(FilterFields.DATE_FROM)}, calendar`
                    : 'Date from - calendar'
                }
                id={FilterFields.DATE_FROM}
                onClickAfterIcon={() => setShowCalendar(!showCalendar)}
              />
              <Styled.InputSeparator />
              <FilterInput
                {...register(FilterFields.DATE_TO, {
                  onBlur: (e) => {
                    setValue(e.target.name, formatDateValue(e.target.value));
                  },
                  validate: {
                    validDate: validateDate,
                    validRange: validateDateRange,
                  },
                })}
                afterIcon={<CalendarIcon />}
                aria-expanded={showCalendar ? 'true' : 'false'}
                error={errors.dateTo?.message}
                iconLabel={
                  !!watch(FilterFields.DATE_TO)
                    ? `Change to date, ${watch(FilterFields.DATE_TO)}, calendar`
                    : 'Date to - calendar'
                }
                id={FilterFields.DATE_TO}
                onClickAfterIcon={() => setShowCalendar(!showCalendar)}
              />
            </Styled.InputWrapper>
            <DatePicker
              anchor={calendarRef}
              onChange={handleCalendarChange}
              onClickAway={closeCalendar}
              onMonthChange={setViewMonth}
              showCalendar={showCalendar}
              value={dateRange}
              viewMonth={viewMonth}
            />
          </Styled.FilterGroup>

          <Styled.FilterGroup>
            <Styled.GroupLabel htmlFor="amountRange">Amount</Styled.GroupLabel>
            <Styled.InputLabelWrapper>
              <Styled.InputLabel htmlFor="minAmount">
                Minimum ($)
              </Styled.InputLabel>
              <Styled.InputLabel htmlFor="maxAmount">
                Maximum ($)
              </Styled.InputLabel>
            </Styled.InputLabelWrapper>
            <Styled.InputWrapper
              $hasError={
                !!errors.minAmount?.message || !!errors.maxAmount?.message
              }
            >
              <FilterInput
                {...register(FilterFields.MIN_AMOUNT, {
                  validate: {
                    validAmount: validateAmount,
                    validRange: (_, formValues) => {
                      clearErrors(FilterFields.MAX_AMOUNT);
                      return validateAmountRange(formValues);
                    },
                  },
                })}
                aria-label="minimum amount"
                beforeIcon="$"
                error={errors.minAmount?.message}
                inputMode="numeric"
              />
              <Styled.InputSeparator />
              <FilterInput
                {...register(FilterFields.MAX_AMOUNT, {
                  validate: {
                    validAmount: validateAmount,
                    validRange: (_, formValues) => {
                      clearErrors(FilterFields.MIN_AMOUNT);
                      return validateAmountRange(formValues);
                    },
                  },
                })}
                aria-label="maximum amount"
                beforeIcon="$"
                error={errors.maxAmount?.message}
                inputMode="numeric"
              />
            </Styled.InputWrapper>
          </Styled.FilterGroup>

          {/* 
            TODO: re-enable when coles launches instore
           <Styled.RadioGroup>
            <Styled.GroupLabel>Type</Styled.GroupLabel>
            <Radio
              {...register(FilterFields.TYPE)}
              defaultChecked
              id="type-all"
              value=""
            >
              All
            </Radio>
            <Radio
              {...register(FilterFields.TYPE)}
              id="type-online"
              value="online"
            >
              Online
            </Radio>
            <Radio
              {...register(FilterFields.TYPE)}
              id="type-instore"
              value="in_store"
            >
              In-store
            </Radio>
          </Styled.RadioGroup> */}
        </div>
        <Styled.FooterGroup>
          <Button level="primary" size="medium" type="submit" variant="neutral">
            Apply
          </Button>
          <Button
            level="secondary"
            onClick={handleClear}
            size="medium"
            type="button"
            variant="neutral"
          >
            Clear Filters
          </Button>
        </Styled.FooterGroup>
      </Styled.FilterForm>
    </SideSheet>
  );
};
