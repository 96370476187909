import { useEffect, useState } from 'react';

import { PaymentMethodEnums } from '../enums';

export const use3dsCardListener = () => {
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const checkPostMessage = (event: MessageEvent) => {
      const { data } = event;
      if (
        data === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS ||
        data === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL
      ) {
        setMessage(data);
      } else {
        setMessage(null);
      }
    };
    window.addEventListener('message', checkPostMessage);
    return () => {
      window.removeEventListener('message', checkPostMessage);
    };
  }, []);

  return { message, setMessage };
};
