import { genericContent } from '@/config/language';
import { useTheme } from '@/contexts/theme';
import { capitalizeFirstChar } from '@/utils';

import { Styled } from './styles';

export const CustomerService = ({ capitalise = false }) => {
  const { theme } = useTheme();

  const contactMessage = `${capitalise ? capitalizeFirstChar(genericContent.CONTACT_SUPPORT) : genericContent.CONTACT_SUPPORT}`;

  if (!theme.urls?.contact) {
    return contactMessage;
  }

  return (
    <Styled.Link
      href={theme.urls.contact}
      rel="noopener noreferrer"
      target="_blank"
    >
      {contactMessage}
    </Styled.Link>
  );
};
