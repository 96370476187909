import isEmpty from 'lodash.isempty';
import { useContext, useEffect } from 'react';
import { get } from 'react-hook-form';

import { ConnectForm, ErrorMessage } from '@/components/form';
import { ChevronUpIcon } from '@/components/icons';
import { Keys } from '@/utils';

import { ComboBoxContext } from './combobox';
import { Styled } from './styles';

import type { TComboBoxContext } from './typings';
import type { TConnectFormCallback } from '@/components/form';
import type { ChangeEvent, KeyboardEvent } from 'react';

export const ComboBoxInput = () => {
  const {
    cursorPosition,
    disabled,
    id,
    inputValue,
    isOpen,
    name,
    options,
    pattern,
    placeholder,
    selectOptions,
    selectedOption,
    setCursorPosition,
    setInputValue,
    setIsOpen,
    setSelectOptions,
    setSelectedOption,
    size,
    validate,
  } = useContext(ComboBoxContext) as TComboBoxContext;

  const onClickToggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const onFocusInput = () => {
    setIsOpen(true);
  };

  const onBlurInput = () => {
    setIsOpen(false);
  };

  const onKeyDownInput = (e: KeyboardEvent) => {
    if (e.key === Keys.ENTER) {
      e.preventDefault();

      const currentOption = selectOptions[cursorPosition];

      setSelectedOption(currentOption);
      setInputValue(currentOption?.value ?? '');
      setIsOpen(false);
    }

    if (e.key === Keys.BACKSPACE) {
      setIsOpen(true);
    }

    if (e.key === Keys.ENTER && !isOpen) {
      setIsOpen(true);
    }
  };

  const onKeyUpInput = (e: KeyboardEvent) => {
    if (e.key === Keys.ESCAPE && isOpen) {
      setIsOpen(false);
    }

    if (e.key === Keys.ARROW_DOWN) {
      const newCursorPosition =
        cursorPosition < selectOptions?.length - 1
          ? cursorPosition + 1
          : cursorPosition;

      const currentSelectedOption = isEmpty(selectedOption)
        ? selectOptions[0]
        : selectOptions[`${newCursorPosition}`];

      setCursorPosition(newCursorPosition);
      setSelectedOption(currentSelectedOption);
      setInputValue(currentSelectedOption.value);
    }

    if (e.key === Keys.ARROW_UP) {
      const newCursorPosition =
        cursorPosition > 0 && cursorPosition < selectOptions.length
          ? cursorPosition - 1
          : cursorPosition;

      const currentSelectedOption = selectOptions[`${newCursorPosition}`];

      setCursorPosition(newCursorPosition);
      setSelectedOption(currentSelectedOption);
      setInputValue(currentSelectedOption.value);
    }
  };

  useEffect(() => {
    if (isOpen && isEmpty(selectedOption)) {
      const defaultOption = selectOptions[0];

      setSelectedOption(defaultOption);
      setInputValue(defaultOption?.value ?? '');
    }
  }, [isOpen, selectOptions, selectedOption, setInputValue, setSelectedOption]);

  return (
    <ConnectForm>
      {({ formState: { errors }, register }: TConnectFormCallback) => {
        const {
          onBlur,
          onChange,
          ref: hookFormFieldRef,
          ...registration
        } = register(name, {
          disabled,
          pattern,
          validate,
        });

        const onChangeFilterOptions = (e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value.toLowerCase().trim();
          const filteredOptions = options.filter((option) =>
            option.value.toLowerCase().includes(value)
          );

          filteredOptions?.length
            ? setSelectOptions(filteredOptions)
            : setSelectOptions(options);

          setInputValue(e.target.value);
          onChange(e);
        };

        const onBlurInputField = (e: ChangeEvent<HTMLInputElement>) => {
          onBlurInput();
          onBlur(e);
        };

        const error = !isEmpty(errors) && get(errors, name);

        return (
          <Styled.ComboBoxInputWrapper id={id}>
            <Styled.ComboBoxTextInput
              {...registration}
              $hasError={!isEmpty(error?.message)}
              $size={size}
              aria-autocomplete="list"
              aria-expanded="false"
              aria-owns="autocomplete-options"
              autoCapitalize="none"
              autoComplete="off"
              disabled={disabled}
              id={name}
              onBlur={onBlurInputField}
              onChange={onChangeFilterOptions}
              onFocus={onFocusInput}
              onKeyDown={onKeyDownInput}
              onKeyUp={onKeyUpInput}
              placeholder={placeholder}
              ref={hookFormFieldRef}
              role="combobox"
              type="text"
              value={
                !isOpen && !!selectedOption?.value
                  ? selectedOption?.value
                  : inputValue
              }
            />

            <Styled.ComboBoxToggle
              $isOpen={isOpen}
              aria-label="autocomplete-options-toggle"
              disabled={disabled}
              key="autocomplete-options-toggle"
              onClick={!disabled ? onClickToggleSelect : () => {}}
              role="button"
              size={size}
            >
              <ChevronUpIcon color={disabled ? '#E5E5E5' : '#000000'} />
            </Styled.ComboBoxToggle>
            <ErrorMessage errors={errors} name={name} />
          </Styled.ComboBoxInputWrapper>
        );
      }}
    </ConnectForm>
  );
};
