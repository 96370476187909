import { CustomerService } from '@/components';

import { Styled } from './styles';

type TLoadMoreMessage = {
  hasNextPage?: boolean;
  isError?: boolean;
  isFetchingNextPage?: boolean;
  isLoading?: boolean;
  ordersListLength?: number;
};

export const LoadMoreMessage = ({
  hasNextPage,
  isError,
  isFetchingNextPage,
  isLoading,
  ordersListLength,
}: TLoadMoreMessage) => {
  if (isLoading || isFetchingNextPage || !ordersListLength) {
    return null;
  }
  if (isError) {
    return (
      <Styled.LoadMoreMessage>
        There was an error fetching more records, please try again or{' '}
        <CustomerService />.
      </Styled.LoadMoreMessage>
    );
  }
  if (!hasNextPage) {
    return (
      <Styled.LoadMoreMessage>No more items to show</Styled.LoadMoreMessage>
    );
  }
};
