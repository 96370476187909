import { AnalyticsBrowser } from '@segment/analytics-next';
import { useState } from 'react';

import { type ProfileTypes, useGetProfile } from '@/features/profile';

import type { LOYALTY_SCHEME_NAMES } from '@/pages/wallet/loyalty-card/constants';

type AdditionalProperties = {
  scheme?: LOYALTY_SCHEME_NAMES;
};

const writeKey = import.meta.env.VITE_REACT_APP_SEGMENT_WRITE_KEY;

export const analytics = writeKey && AnalyticsBrowser.load({ writeKey });

export const useAnalytics = () => {
  const { data: user } = useGetProfile();

  const [hasIdentified, setHasIdentified] = useState(false);

  if (!writeKey) {
    return {
      identify: () => null,
      track: () => null,
    };
  }

  const identify = (userProfile: ProfileTypes.TUser) => {
    if (userProfile && !hasIdentified) {
      analytics.identify(userProfile?.id, {
        email: userProfile.email,
        name: `${userProfile?.first_name} ${userProfile?.last_name}`.trim(),
      });
      setHasIdentified(true);
    }
  };

  const track = (event: string, properties?: AdditionalProperties) => {
    analytics.track(event, {
      channel: 'web_wallet',
      wallet_id: user?.current_wallet_id,
      ...properties,
    });
  };

  return {
    identify,
    track,
  };
};
