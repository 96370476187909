import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  AddressEndpointKeys,
  addressQueryKeys,
  addressTransactionKeys,
} from './profile-query-keys';

import type { MutationOptions } from '@tanstack/react-query';

export const useDeleteAddress = (
  addressId: string,
  options: MutationOptions
) => {
  const auth = useAuth();
  const deleteAddressRequest = async () =>
    await request(
      endpoints.addresses.ADDRESS_BY_ID(addressId),
      auth,
      {
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P3,
        requestKey: AddressEndpointKeys.ADDRESS_DELETE,
        transaction: addressTransactionKeys.addressDelete,
      }
    );

  return useMutation({
    mutationFn: deleteAddressRequest,
    mutationKey: addressQueryKeys.deleteAddress,
    ...options,
  });
};
