import { useEffect, useState } from 'react';

import { Spinner } from '@/components';

import { Styled } from './styles';

const THREEDS_TIMEOUT = 5000;
const THREEDS_READY = 2;

interface IProps {
  src: string;
}

let timer: number;

export const ThreeDsIframe = ({ src }: IProps) => {
  const [loadCount, setLoadCount] = useState(0);

  // After initial iframe load, it loads a new page for proper 3ds
  const isLoading = loadCount < THREEDS_READY;

  const iframeReady = () => {
    if (loadCount < THREEDS_READY) {
      setLoadCount((count) => count + 1);
    }
    if (loadCount === 0) {
      // Fallback in case the second load event doesnt happen in timely manner
      timer = window.setTimeout(() => {
        setLoadCount(THREEDS_READY);
      }, THREEDS_TIMEOUT);
    }
  };

  const iframeError = () => {
    setLoadCount(THREEDS_READY);
    clearTimeout(timer);
  };

  useEffect(() => {
    if (loadCount >= THREEDS_READY) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loadCount]);

  return (
    <>
      {isLoading && (
        <Styled.LoadingWrapper>
          <Spinner message="Verifying your card" />
        </Styled.LoadingWrapper>
      )}
      <Styled.Iframe
        aria-hidden={isLoading}
        onError={iframeError}
        onLoad={iframeReady}
        src={src}
        title="3D Secure"
      />
    </>
  );
};
