import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Title = styled.h1<{ $spacing: boolean }>`
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-24);
  line-height: var(--lineHeights-half);
  color: var(--colors-onSurfaceA);
  margin: ${({ $spacing }) => ($spacing ? '0 0 var(--space-m)' : '0')};

  @media (min-width: ${BreakpointRems.sm}) {
    font-size: var(--fontSizes-32);
    margin-bottom: ${({ $spacing }) => ($spacing ? 'var(--space-xl)' : '0')};
  }

  &:focus {
    outline: none;
  }
`;

export const Styled = {
  Title,
};
