import creditCardType from 'credit-card-type';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField, TextFieldDummy } from '@/components';
import { PaymentMethodEnums } from '@/features/card-management';
import {
  Keys,
  formatCardExpiryDate,
  formatCardNumber,
  getCardSecurityCodeLength,
  getCardSecurityCodeLengthBySchemes,
} from '@/utils';

import { CardInputFieldNames, CardInputLabels } from './enums';
import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';

interface ICardInput {
  isAddCardOpen?: boolean;
  isEditing?: boolean;
  isSubmitting?: boolean;
  lastFour?: string;
  schemes?: PaymentMethodTypes.TBankCard['data']['schemes'];
}

export const CardInput = ({
  isAddCardOpen,
  isEditing,
  isSubmitting,
  lastFour,
  schemes,
}: ICardInput) => {
  const [cardScheme, setCardScheme] =
    useState<PaymentMethodEnums.BankCardSchemes>();

  const formContext = useFormContext();

  const cardNumber = formContext.getValues(CardInputFieldNames.CARD_NUMBER);
  const cardSecurityCodeLength = isEditing
    ? getCardSecurityCodeLengthBySchemes(schemes)
    : getCardSecurityCodeLength(cardNumber);

  const onKeyUpCardNumber: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    const value = (e.target as HTMLInputElement).value;
    formContext.setValue(
      CardInputFieldNames.CARD_NUMBER,
      formatCardNumber(value)
    );
  };

  const onChangeCardNumber: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const value = (e.target as HTMLInputElement).value;
    const cardType =
      value &&
      (creditCardType(value)[0]?.type as PaymentMethodEnums.BankCardSchemes);
    setCardScheme(cardType || PaymentMethodEnums.BankCardSchemes.DEFAULT);
  };

  const onKeyUpExpiryDate: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (e.key === Keys.BACKSPACE) {
      return;
    }

    if (e.key === Keys.SLASH) {
      formContext.setValue(
        CardInputFieldNames.CARD_EXPIRY_DATE,
        value.replace('//', '/')
      );
      return;
    }

    if (value?.length === 2) {
      formContext.setValue(CardInputFieldNames.CARD_EXPIRY_DATE, value + '/');
    }
  };

  const onBlurExpiryDate: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const value = (e.target as HTMLInputElement).value;

    formContext.setValue(
      CardInputFieldNames.CARD_EXPIRY_DATE,
      formatCardExpiryDate(value)
    );
  };

  useEffect(() => {
    if (!isAddCardOpen) {
      setCardScheme(undefined);
    }
  }, [isAddCardOpen]);

  return (
    <Styled.Fieldset>
      {isEditing ? (
        <TextFieldDummy
          label="Card number*"
          value={`•••• •••• •••• ${lastFour || '••••'}`}
        />
      ) : (
        <TextField
          autocomplete="cc-number"
          cardScheme={cardScheme}
          disabled={!!isSubmitting}
          inputMode="numeric"
          label={CardInputLabels.CARD_NUMBER}
          maxLength={19}
          name={CardInputFieldNames.CARD_NUMBER}
          onChange={onChangeCardNumber}
          onKeyUp={onKeyUpCardNumber}
          placeholder="•••• •••• •••• ••••"
          required={true}
        />
      )}

      <Styled.Grid>
        <TextField
          autocomplete="cc-exp"
          disabled={!!isSubmitting}
          inputMode="numeric"
          label={CardInputLabels.CARD_EXPIRY_DATE}
          labelCSS={{ maxWidth: 110 }}
          maxLength={7}
          name={CardInputFieldNames.CARD_EXPIRY_DATE}
          onBlur={onBlurExpiryDate}
          onKeyUp={onKeyUpExpiryDate}
          placeholder="MM/YY"
          required={true}
        />
        <TextField
          autocomplete="cc-csc"
          disabled={!!isSubmitting}
          inputMode="numeric"
          label={CardInputLabels.CARD_SECURITY_CODE}
          labelCSS={{ maxWidth: 110 }}
          maxLength={cardSecurityCodeLength || 4}
          name={CardInputFieldNames.CARD_SECURITY_CODE}
          placeholder="CVV/CVC"
          required={true}
        />
      </Styled.Grid>
    </Styled.Fieldset>
  );
};
