import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.75rem;
`;

const NameFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-evenly;
  gap: 1.75rem;

  @media (min-width: ${BreakpointRems.xs}) {
    flex-direction: row;
    align-items: baseline;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
`;

export const Styled = { CheckboxWrapper, Fieldset, NameFieldWrapper };
