import { LogoCard } from '@b707/ponyta';

import { Styled } from '../../styles';

export const MycolesFetchError = () => {
  return (
    <Styled.LoyaltyCardItemContainer>
      <Styled.LoyaltyCardItemImage>
        <LogoCard size="md" type="mycoles" />
      </Styled.LoyaltyCardItemImage>
      <Styled.LoyaltyInfoWrapper>
        <h3>mycoles discount card</h3>
        <p>
          Unable to retrieve your mycoles discount card details. Refresh the
          page or try again later.
        </p>
      </Styled.LoyaltyInfoWrapper>
    </Styled.LoyaltyCardItemContainer>
  );
};
