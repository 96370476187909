import { profileQueryKeys } from '@/features/profile/services/profile-query-keys';
import { analytics } from '@/hooks';
import { type TBaasErrorResponse, cleanUrl } from '@/services';

import type { ProfileTypes } from '@/features/profile';
import type { QueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

const requestErrorEvent = 'wallet_error';

export function trackSegmentRequestError(
  queryClient: QueryClient,
  error?: AxiosError | AxiosError<TBaasErrorResponse>
) {
  const profile = queryClient.getQueryData<ProfileTypes.TUser>(
    profileQueryKeys.profile
  );

  const errorData = {
    api_path: cleanUrl(error?.config?.url),
    api_query: error?.config?.params,
    channel: 'web_wallet',
    error_status: error?.response?.status,
    wallet_id: profile?.current_wallet_id,
  };
  if (error?.response && isBaasError(error)) {
    analytics.track(requestErrorEvent, {
      ...errorData,
      error_message: error.response.data.message,
      error_number: error?.response?.data.details?.error_number,
    });
  } else {
    analytics.track(requestErrorEvent, {
      ...errorData,
      error_message: error?.message,
    });
  }
}

function isBaasError(
  error: AxiosError
): error is AxiosError<TBaasErrorResponse> {
  return error?.response?.data
    ? Object.hasOwn(error?.response?.data, 'details')
    : false;
}
