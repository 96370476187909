import styled from 'styled-components';

const Fieldset = styled.fieldset`
  border: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  margin: 0;
  padding: 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-m);
  margin-bottom: var(--space-m);
  width: 100%;
  place-items: start;
`;

const Required = styled.p`
  color: var(--colors-onSurfaceB);
  margin: 0 0 var(--space-xs);
`;

export const Styled = {
  Fieldset,
  Grid,
  Required,
};
