import type { AxiosInstance } from 'axios';

export const interceptRequest = (
  instance: AxiosInstance,
  accessToken: string | undefined
) => {
  instance.interceptors.request.use(async (config) => {
    if (!!accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });
};
