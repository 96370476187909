import { useContext } from 'react';

import { ComboBoxContext } from './combobox';
import { Styled } from './styles';

import type { TComboBoxContext } from './typings';

export const ComboBoxResultsStatus = () => {
  const { selectOptions } = useContext(ComboBoxContext) as TComboBoxContext;

  return (
    <Styled.VisuallyHiddenComboBoxResultsStatus>
      <div aria-live="polite" role="status">
        {selectOptions?.length || 0} results available.
      </div>
    </Styled.VisuallyHiddenComboBoxResultsStatus>
  );
};
