import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRef, useState } from 'react';

import { Keys } from '@/utils';

import { PowerIcon } from '../icons/power-icon';
import { ProfileDetails } from '../profile-details';

import { Styled } from './styles';

import type { IUserDetails } from './top-bar';

interface IWidescreenDetails extends IUserDetails {
  onLogout?: () => void;
}

export const WidescreenDetails = ({
  details,
  name,
  onLogout,
}: IWidescreenDetails) => {
  const [isOpen, setIsOpen] = useState(false);
  const logoutRef = useRef<HTMLButtonElement>(null);

  /**
   *  We're basically re-implementing default html focus functionality since
   *  radix dropdown disables tab to focus. yuck
   */
  const handleLogoutFocus = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Keys.TAB) {
      logoutRef?.current?.focus();
    }
  };

  return (
    <Styled.TopNavDetailsWrapperWidescreen>
      <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen}>
        <Styled.DropdownTrigger>
          <ProfileDetails
            active={isOpen}
            details={details}
            detailsColor={'onBrand1High'}
            headerColor={'onBrand1High'}
            name={name}
            size="sm"
          />
        </Styled.DropdownTrigger>

        <Styled.DropdownContent
          align="center"
          loop
          onKeyDown={handleLogoutFocus}
          role="none"
          sideOffset={10}
          tabIndex={0}
        >
          <DropdownMenu.Item
            asChild
            onSelect={onLogout}
            role={undefined}
            tabIndex={0}
          >
            <Styled.ItemButton ref={logoutRef}>
              <PowerIcon />
              Log out
            </Styled.ItemButton>
          </DropdownMenu.Item>
        </Styled.DropdownContent>
      </DropdownMenu.Root>
    </Styled.TopNavDetailsWrapperWidescreen>
  );
};
