import { SuccessDialogStates } from '../enums';

export const getCardSuccessMessage = (
  successDialogState: SuccessDialogStates | null
) => {
  const message = {
    description: '',
    title: '',
  };
  switch (successDialogState) {
    case SuccessDialogStates.SET_DEFAULT: {
      message.title = 'Default card set';
      message.description =
        'This card will be automatically selected during checkout.';
      return message;
    }
    case SuccessDialogStates.EDIT: {
      message.title = 'Card updated';
      message.description = 'You have successfully updated your card details.';
      return message;
    }
    case SuccessDialogStates.ADD: {
      message.title = 'New card added';
      message.description =
        'You have successfully added a new card to your flypay wallet.';
      return message;
    }
    case SuccessDialogStates.UPDATE_NICKNAME: {
      message.title = 'Card nickname updated';
      message.description =
        'You have successfully updated your card’s nickname.';
      return message;
    }
    default: {
      message.title = 'Card removed';
      message.description =
        'You have successfully removed this card from your flypay wallet.';
      return message;
    }
  }
};
