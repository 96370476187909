import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingScreen } from '@/components';
import { SessionStorage } from '@/config/storage/session';
import { FLYBUYS_ERROR_TYPES } from '@/features/loyalty';
import {
  PARAM_FLYBUYS_CODE,
  PARAM_FLYBUYS_STATE,
  Routes,
} from '@/routes/constants';
import { AppErrorTypes, useAppErrorSetErrorType } from '@/store';

export const AuthFlybuys = () => {
  const navigate = useNavigate();
  const { setAppErrorType } = useAppErrorSetErrorType();

  const [searchParams] = useSearchParams();

  const code = searchParams.get(PARAM_FLYBUYS_CODE);
  const state = searchParams.get(PARAM_FLYBUYS_STATE);
  const error_description = decodeURIComponent(
    searchParams.get('error_description') || ''
  );

  if (!!code && !state) {
    const codeData = JSON.stringify({ code });

    // code but no state may mean we're in an app environment
    if (
      window.webkit !== undefined &&
      window.webkit.messageHandlers.appInterface !== undefined
    ) {
      // postMessage to ios
      window.webkit.messageHandlers.appInterface.postMessage(codeData);
    } else if (window.appInterface !== undefined) {
      // postMessage to android
      window.appInterface.postMessage(codeData);
    } else {
      // otherwise this is not a valid request
      setAppErrorType(AppErrorTypes.FLYBUYS_AUTH);
      throw new Error('Error linking Flybuys.');
    }
  }

  useEffect(() => {
    const storedState =
      sessionStorage.getItem(SessionStorage.FLYBUYS_STATE) || '';

    if (!!code && !!state && state === storedState) {
      sessionStorage.setItem(SessionStorage.FLYBUYS_CODE, code);
      navigate(Routes.WALLET);
    } else if (error_description === FLYBUYS_ERROR_TYPES.BLOCKED) {
      sessionStorage.setItem(
        SessionStorage.FLYBUYS_ERROR_TYPE,
        FLYBUYS_ERROR_TYPES.BLOCKED
      );
      navigate(Routes.HOME);
    } else {
      sessionStorage.setItem(
        SessionStorage.FLYBUYS_ERROR_TYPE,
        FLYBUYS_ERROR_TYPES.DEFAULT
      );
      navigate(Routes.HOME);
    }
  }, [code, error_description, navigate, setAppErrorType, state]);

  return <LoadingScreen />;
};
