import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const AddressCardDetails = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
`;

const AddressPreviewLong = styled.div`
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-16);
  line-height: var(--lineHeights-half);
  color: var(--colors-onSurfaceA);
  @media (min-width: ${BreakpointRems.sm}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressPreviewShort = styled.div`
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 16px;
  color: var(--colors-onSurfaceB);
  @media (min-width: ${BreakpointRems.sm}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressPreviewAction = styled.div`
  display: flex;
  gap: var(--space-m);
  align-items: center;
`;

const Container = styled.button<{ $selected?: boolean }>`
  width: 100%;
  cursor: pointer;
  padding: var(--space-m) var(--space-l);
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  display: flex;
  gap: var(--space-m);
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background: var(--colors-surfaceLowest);
  border: 1px solid
    ${(props) =>
      props.$selected
        ? 'var(--colors-brand1High)'
        : 'var(--colors-surfaceLowest)'};

  &:hover,
  &:focus {
    border-color: var(--colors-brand1High);
  }
`;

export const Styled = {
  AddressCardDetails,
  AddressPreviewAction,
  AddressPreviewLong,
  AddressPreviewShort,
  Container,
};
