import { format } from 'date-fns';

import { DateFormats } from '@/config/language';
import { V2QrEnums } from '@/services';

import { getPayments } from './get-payments';

import type { TOrderInfo, TPaymentInfo } from '../../types';
import type { OrderServiceTypes } from '@/features/activity/services';
import type { V2QrTypes } from '@/services';

export const getOrderInfo = (order: OrderServiceTypes.TOrder): TOrderInfo => {
  const opapiOrder = order?.details.order;
  const payments = getPayments(opapiOrder.payments, order.status);
  const amount =
    order.status === V2QrEnums.OrderStatusV2.ABORTED
      ? 0
      : getOrderAmount(payments);
  const hasRefund = checkHasRefund(payments);

  return {
    amount,
    created_at: {
      dayMonthTime: format(
        opapiOrder.created_datetime,
        DateFormats.DAY_MONTH_TIME
      ),
      dayMonthYear: format(
        opapiOrder.created_datetime,
        DateFormats.DAY_MONTH_YEAR
      ),
      iso: opapiOrder.created_datetime,
    },
    hasMyColes: checkHasMyColes(opapiOrder),
    hasRefund,
    merchantId: opapiOrder.merchant.id || '',
    merchantLocation: getMerchantLocation(opapiOrder.merchant.location),
    merchantName: opapiOrder.merchant.name || '',
    orderId: order.id,
    orderReference: opapiOrder.order_reference,
    orderStatus: getOrderStatus(order.status, hasRefund, amount),
    paymentContext: opapiOrder.payment_context,
    payments,
  };
};

export const getOrderAmount = (payments: TPaymentInfo[]) => {
  return payments.reduce((total, payment) => {
    return total + payment.amount;
  }, 0);
};

export const getOrderStatus = (
  status: `${V2QrEnums.OrderStatusV2}`,
  hasRefund: boolean,
  amount: number
) => {
  if (status === V2QrEnums.OrderStatusV2.ABORTED) {
    return 'Cancelled';
  } else if (status !== V2QrEnums.OrderStatusV2.FINALIZED) {
    return 'Pending';
  } else if (status === V2QrEnums.OrderStatusV2.FINALIZED && !hasRefund) {
    return 'Paid';
  } else {
    return amount <= 0 ? 'Refunded' : 'Partially Refunded';
  }
};

export const checkHasRefund = (payments: TPaymentInfo[]) => {
  return payments.some((payment) => {
    return payment.transactions.some((transaction) => {
      return transaction.type !== 'purchase';
    });
  });
};

export const checkHasMyColes = (order: V2QrTypes.TOrderV2): boolean => {
  if (!order.non_payment_instruments) {
    return false;
  }
  return order.non_payment_instruments.some(
    (instrument) =>
      instrument.provider === V2QrEnums.NonPaymentInstrumentProvider.MYCOLES
  );
};

export const getMerchantLocation = (location?: V2QrTypes.TOpapiAddress) => {
  if (!location) {
    return;
  }
  const { address_line_1, address_line_2, city, postcode, state } = location;
  // remove undefined values and join the remainders
  const line3 = [city, state, postcode].filter(Boolean).join(' ');

  return {
    line1: address_line_1,
    line2: address_line_2,
    line3,
  };
};
