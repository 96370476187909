import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { paymentMethodQueryKeys } from '@/features/card-management';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';

import type { MutationOptions } from '@tanstack/react-query';

export function useDeleteCard(cardId: string, options: MutationOptions) {
  const auth = useAuth();
  const deleteCardRequest = async () =>
    await request(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_DELETE,
        transaction: paymentMethodTransactionKeys.bankCardDelete,
      }
    );

  return useMutation({
    mutationFn: deleteCardRequest,
    mutationKey: paymentMethodQueryKeys.bankCardDelete,
    ...options,
  });
}
