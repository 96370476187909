import styled, { css } from 'styled-components';

const CardItem = styled.button<{
  $hasPill?: boolean;
  $isUnavailable?: boolean;
  isExpired?: boolean;
  selected?: boolean;
}>`
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-lg);
  padding: var(--space-xs);
  width: 100%;

  &:hover {
    border: 1px solid var(--colors-brand1High);
    cursor: pointer;
  }
  border: 1px solid;
  border-color: ${({ selected }) =>
    selected ? 'var(--colors-brand1High)' : 'var(--colors-surfaceLowest)'};

  ${({ $isUnavailable }) =>
    $isUnavailable &&
    css`
      background: #dfdfdf;
    `}

  div:first-child {
    flex-shrink: 0;
  }

  .status-pill {
    font-weight: var(--fontWeights-normal);
    font-size: var(--fontSizes-14);
    line-height: 100%;
    text-align: center;
    color: var(--colors-surfaceInverse);
    background: var(--colors-brand1Lowest);
    border-radius: 120px;
    flex-shrink: 0;
    height: fit-content;
    padding: var(--space-2xs) var(--space-s);
    max-width: 70px;
  }

  .expiry-pill {
    font-weight: var(--fontWeights-normal);
    font-size: var(--fontSizes-14);
    line-height: 100%;
    text-align: center;
    color: var(--colors-warningHigh);
    background: var(--colors-warningLowest);
    border-radius: 120px;
    flex-shrink: 0;
    height: fit-content;
    padding: var(--space-2xs) var(--space-s);
    max-width: 70px;
  }
`;

const CardItemContent = styled.div`
  align-items: center;
  display: flex;
  gap: var(--space-m);
`;

const CardItemContentTextContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: var(--space-s);
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
`;

const CardItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const CardItemName = styled.p<{ isExpired?: boolean }>`
  color: ${({ isExpired }) =>
    isExpired ? 'var(--colors-onSurfaceD)' : 'var(--colors-onSurfaceB)'};
  display: block;
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-16);
  line-height: 125%;
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardItemNumber = styled.p<{ isExpired?: boolean }>`
  color: ${({ isExpired }) =>
    isExpired ? 'var(--colors-onSurfaceD)' : 'var(--colors-onSurfaceB)'};
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 125%;
  margin: 0;
  text-align: left;
`;

const DialogIntro = styled.p`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
`;

export const Styled = {
  CardItem,
  CardItemContent,
  CardItemContentTextContainer,
  CardItemDetails,
  CardItemName,
  CardItemNumber,
  DialogIntro,
};
