/**
 * Removes any unique identifiers from the URL
 */
export function cleanUrl(url?: string): string | undefined {
  if (!url) {
    return;
  }
  const urlNoParams = url.split('?')[0];
  return urlNoParams.replace(
    /\b([a-zA-Z]+[0-9]|[0-9]+[a-zA-Z])[a-zA-Z0-9-]+\b/,
    '{id}'
  );
}
