import { Dialog } from '@b707/ponyta';
import { useRef, useState } from 'react';

import { PadlockIcon } from '@/components/icons';
import { cardManagementContent } from '@/features/card-management';

import { DialogContent } from './dialog-content';
import { Styled } from './styles';

export const SecurityAssurance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const focusTrigger = () => {
    setTimeout(() => ref.current?.focus(), 50);
  };

  const onClickClose = () => {
    setIsOpen(false);
    focusTrigger();
  };

  const onClickOpen = () => setIsOpen(true);

  const onClickDialog = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      focusTrigger();
    }
  };

  return (
    <Styled.Container>
      <Styled.Icon>
        <PadlockIcon />
      </Styled.Icon>
      <Dialog
        body={<DialogContent onClick={onClickClose} />}
        onOpenChange={onClickDialog}
        open={isOpen}
      />
      <Styled.TriggerButton onClick={onClickOpen} ref={ref} type="button">
        <Styled.Paragraph>
          {cardManagementContent.SECURITY_ASSURANCE}
        </Styled.Paragraph>
      </Styled.TriggerButton>
    </Styled.Container>
  );
};
