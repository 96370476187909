import type { IIcon } from './typings';

export const ChevronDownIcon = ({
  color = 'currentColor',
  height = 24,
  width = 24,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.2636 7.31439C3.61508 6.96291 4.18492 6.96291 4.5364 7.31439L12 14.778L19.4636 7.31439C19.8151 6.96291 20.3849 6.96291 20.7364 7.31439C21.0879 7.66586 21.0879 8.23571 20.7364 8.58718L12.6364 16.6872C12.2849 17.0386 11.7151 17.0386 11.3636 16.6872L3.2636 8.58718C2.91213 8.23571 2.91213 7.66586 3.2636 7.31439Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
