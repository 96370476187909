import styled from 'styled-components';

const ProfileDetailsWrapper = styled.div<{ $avatarLeft?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 1rem;

  ${({ $avatarLeft }) => $avatarLeft && `flex-direction: row-reverse;`}
`;

const DetailsTextWrapper = styled.div<{ $avatarLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $avatarLeft }) =>
    $avatarLeft ? 'flex-start' : 'flex-end'};
`;

const DetailsTextWrapperHeading = styled.h1<{ $color?: string }>`
  font-size: var(--fontSizes-24);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-${({ $color }) => (!!$color ? $color : 'brand1Highest')});
`;

const DetailsTextWrapperText = styled.p<{ $color?: string }>`
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-normal);
  margin: 0;
  color: var(--colors-${({ $color }) => (!!$color ? $color : 'brand1Highest')});
`;

export const Styled = {
  DetailsTextWrapper,
  DetailsTextWrapperHeading,
  DetailsTextWrapperText,
  ProfileDetailsWrapper,
};
