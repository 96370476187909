import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FilterGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--space-xs);
  margin-bottom: var(--space-l);
  position: relative;
`;

const FilterInput = styled.input`
  border: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  font-size: var(--fontSizes-16);
  line-height: var(--lineHeights-half);

  &:focus {
    outline: 0;
  }
`;

const FooterGroup = styled.div`
  margin-top: var(--space-m);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  padding-top: var(--space-xl);
  border-top: 1px solid var(--colors-onSurfaceD);
`;

const GroupLabel = styled.label`
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
`;

const IconWrapper = styled.div`
  line-height: 0;
  margin: 0;
  padding: 0;
`;

const IconWrapperButton = styled.button`
  cursor: pointer;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  outline: none;
  border-radius: var(--radii-sm);

  &:focus-within {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }
`;

const InputBox = styled.div<{ $hasError: boolean }>`
  display: flex;
  padding: var(--space-s) var(--space-m);
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  background-color: var(--colors-surfaceLowest);
  border-radius: var(--radii-lg);
  border: 1px solid;

  border-color: ${(props) => props.$hasError && 'var(--colors-onErrorLow)'};

  &:focus-within {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }
`;

const InputError = styled.div`
  position: absolute;
  bottom: -2.25rem;
  display: flex;
  align-items: top;
  gap: var(--space-2xs);
  font-size: var(--fontSizes-12);
  color: var(--colors-onErrorLow);
  line-height: var(--lineHeights-half);

  @media (min-width: ${BreakpointRems.xs}) {
    font-size: var(--fontSizes-14);
    bottom: -2.5rem;
  }
`;

const InputErrorWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputLabel = styled.label`
  width: 100%;
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  line-height: var(--lineHeights-half);
  font-weight: var(--fontWeights-bold);
`;

const InputSeparator = styled.div`
  width: var(--space-s);
  height: 1px;
  align-self: center;
  background-color: var(--colors-onSurfaceC);
`;

const InputWrapper = styled.div<{ $hasError?: boolean }>`
  align-items: flex-start;
  display: flex;
  gap: var(--space-s);
  margin-bottom: ${(props) => props.$hasError && '2.25rem'};
`;

const InputLabelWrapper = styled(InputWrapper)`
  gap: var(--space-xl);
  margin-bottom: 0;
`;

const RadioGroup = styled(FilterGroup)`
  gap: var(--space-m);
`;

export const Styled = {
  FilterForm,
  FilterGroup,
  FilterInput,
  FooterGroup,
  GroupLabel,
  IconWrapper,
  IconWrapperButton,
  InputBox,
  InputError,
  InputErrorWrapper,
  InputLabel,
  InputLabelWrapper,
  InputSeparator,
  InputWrapper,
  RadioGroup,
};
