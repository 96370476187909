import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  AddressEndpointKeys,
  addressQueryKeys,
  addressTransactionKeys,
} from './profile-query-keys';

import type { AddressesTypes } from '../types/address';
import type { MutationOptions } from '@tanstack/react-query';

export function useAddAddress(
  options: MutationOptions<
    unknown,
    unknown,
    AddressesTypes.AddressRequest,
    unknown
  >
) {
  const auth = useAuth();

  const addAddressRequest = async (data: AddressesTypes.AddressRequest) => {
    return await request(
      endpoints.addresses.ADDRESSES,
      auth,
      {
        data,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: AddressEndpointKeys.ADDRESS_ADD,
        transaction: addressTransactionKeys.addressAdd,
      }
    );
  };

  return useMutation({
    mutationFn: addAddressRequest,
    mutationKey: addressQueryKeys.addAddress,
    ...options,
  });
}
