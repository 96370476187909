import React from 'react';

import { ConnectForm, ErrorMessage } from '@/components/form';

import { Styled } from './styles';

import type { TCheckboxProps } from './typings';
import type { TConnectFormCallback } from '@/components/form';

export const Checkbox = React.forwardRef<
  HTMLInputElement | null,
  TCheckboxProps
>(
  (
    {
      defaultChecked,
      disabled,
      errorId,
      id,
      label,
      name,
      onBlur,
      onChange,
      required,
      shouldUnregister,
      value,
    },
    ref
  ) => {
    if (!name) {
      return null;
    }

    return (
      <ConnectForm>
        {({ formState: { errors }, register }: TConnectFormCallback) => {
          const { ref: hookFormFieldRef } = register(name, {
            disabled,
            shouldUnregister,
            value,
          });
          return (
            <>
              <Styled.InputWrapper>
                <Styled.Checkbox
                  aria-describedby={errorId}
                  aria-invalid={!!errors?.name}
                  {...(defaultChecked && { defaultChecked: true })}
                  {...(disabled && { disabled: true })}
                  aria-required={!!required ? 'true' : 'false'}
                  id={id || name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={(e) => {
                    hookFormFieldRef(e);

                    if (!!ref) {
                      (
                        ref as React.MutableRefObject<HTMLInputElement | null>
                      ).current = e;
                    }
                  }}
                  type="checkbox"
                  value={value}
                />
                <Styled.Label
                  $disabled={disabled}
                  htmlFor={id || name}
                  {...(disabled && { tabIndex: -1 })}
                >
                  {!!label && label}
                </Styled.Label>
              </Styled.InputWrapper>
              <ErrorMessage errors={errors} id={errorId} name={name} />
            </>
          );
        }}
      </ConnectForm>
    );
  }
);

Checkbox.displayName = 'Checkbox';
