import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  ProfileEndpointKeys,
  profileQueryKeys,
  profileTransactionKeys,
} from './profile-query-keys';

import type { TBaasError } from '@/services';
import type { MutationOptions } from '@tanstack/react-query';

interface IDeletePayload {
  feedback: string;
  reason: string;
}

export const useDeleteProfile = (
  options?: MutationOptions<unknown, TBaasError, IDeletePayload, unknown>
) => {
  const auth = useAuth();
  const deleteProfileRequest = async (payload: IDeletePayload) =>
    await request(
      endpoints.profile.PROFILE,
      auth,
      {
        data: payload,
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P2,
        requestKey: ProfileEndpointKeys.PROFILE_DELETE,
        transaction: profileTransactionKeys.profileDelete,
      }
    );

  return useMutation<unknown, TBaasError, IDeletePayload, unknown>({
    mutationFn: deleteProfileRequest,
    mutationKey: profileQueryKeys.profileDelete,
    ...options,
  });
};
