import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { ButtonSpinner } from '@/components';
import { DownArrowIcon } from '@/components/icons';
import { useBreakpoints } from '@/hooks';
import {
  LoadMoreMessage,
  MobileOrderList,
  WidescreenOrdersTable,
} from '@/pages/activity/order-table';

import { Styled } from './styles';

import type { OrderServiceTypes } from '@/features/activity/services';
import type {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';

TimeAgo.addDefaultLocale(en);

interface IOrdersTableProps {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<OrderServiceTypes.TOrdersList, unknown>
    >
  >;
  hasNextPage?: boolean;
  isError: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onSelectOrder?: (id: string) => void;
  orders?: OrderServiceTypes.TOrder[];
}

export const OrdersTable = ({
  fetchNextPage,
  hasNextPage,
  isError,
  isFetchingNextPage,
  isLoading,
  onSelectOrder,
  orders,
}: IOrdersTableProps) => {
  const breakpoints = useBreakpoints();

  return (
    <Styled.Container aria-live="polite">
      {breakpoints.md ? (
        <WidescreenOrdersTable
          isError={isError}
          isLoading={isLoading}
          onClick={onSelectOrder}
          orders={orders}
        />
      ) : (
        <MobileOrderList
          isError={isError}
          isLoading={isLoading}
          onClick={onSelectOrder}
          orders={orders}
        />
      )}
      {hasNextPage && (
        <ButtonSpinner
          level="secondary"
          loading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
          size="small"
          style={{ margin: '1rem auto 0 auto' }}
          variant="neutral"
        >
          {!isFetchingNextPage && <DownArrowIcon />} Load More
        </ButtonSpinner>
      )}
      <LoadMoreMessage
        hasNextPage={hasNextPage}
        isError={isError}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        ordersListLength={orders?.length}
      />
    </Styled.Container>
  );
};
