export const getNameInitials = (name?: string) => {
  if (!name) {
    return '';
  }
  const names = name.split(' ');
  const initials = names.map((n) => n[0]).join('');

  return initials.length === 1
    ? initials.toUpperCase()
    : (initials[0] + initials.slice(-1)).toUpperCase();
};
