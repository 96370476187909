import { TopBarLoggedOut } from '@/components';
import { ErrorIcon } from '@/components/icons';
import { AppErrorTypes, useAppErrorType } from '@/store';

import { AppErrorMsg } from './error-mappings';
import { Styled } from './styles';

export const ErrorFallback = () => {
  const errorType = useAppErrorType();
  const ErrorMsg = !!errorType
    ? AppErrorMsg?.[errorType]
    : AppErrorMsg[AppErrorTypes.DEFAULT];

  return (
    <>
      <TopBarLoggedOut />
      <Styled.LayoutWrapper>
        <Styled.ErrorContainer>
          <ErrorIcon height={56} width={56} />

          <Styled.ErrorMessageTitle>
            <ErrorMsg.title />
          </Styled.ErrorMessageTitle>

          <Styled.ErrorMessage>
            <ErrorMsg.message />
          </Styled.ErrorMessage>

          {!!ErrorMsg.button && (
            <Styled.ButtonContainer>
              <ErrorMsg.button />
            </Styled.ButtonContainer>
          )}
        </Styled.ErrorContainer>
      </Styled.LayoutWrapper>
    </>
  );
};
