export const formatDate = (date: string) => {
  const DATE_REGEX = /^(0?\d|1\d)\/(\d{4}|\d{2})$/;

  if (DATE_REGEX.test(date)) {
    const splitDate = date?.split('/');
    if (splitDate.length < 2) {
      return [];
    }
    return [splitDate[0]?.padStart(2, '0'), `20${splitDate[1]}`];
  }

  return [];
};
