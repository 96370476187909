import { createGlobalStyle, css } from 'styled-components';

import { normalize } from './normalize';

const FONT_URL = 'https://static.beemit.com.au/fonts/fabric';
const FONT_FAMILY_SANS = 'Fabric Sans Web';
const FONT_FAMILY_SERIF = 'Fabric Serif Web';

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  
  @font-face {
    font-family: ${FONT_FAMILY_SANS};
    src: url('${FONT_URL}/FabricSansWeb-Medium.woff2') format('woff2'),
        url('${FONT_URL}/FabricSansWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: ${FONT_FAMILY_SANS};
    src: url('${FONT_URL}/FabricSansWeb-Regular.woff2') format('woff2'),
        url('${FONT_URL}/FabricSansWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY_SANS};
    src: url('${FONT_URL}/FabricSansWeb-Bold.woff2') format('woff2'),
        url('${FONT_URL}/FabricSansWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY_SERIF};
    src: url('${FONT_URL}/FabricSerifWeb-Regular.woff2') format('woff2'),
        url('${FONT_URL}/FabricSerifWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY_SERIF};
    src: url('${FONT_URL}/FabricSerifWeb-Medium.woff2') format('woff2'),
        url('${FONT_URL}/FabricSerifWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *, 
  *::before, 
  *::after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    ${({ theme }) => css`
      background-color: ${theme?.styling?.colors?.surfaceLow?.value ||
      '#F3F3F1'};
      color: ${theme?.styling?.colors?.onSurfaceA?.value || '#000'};
      font-family: ${theme?.styling?.fonts?.sans?.value || FONT_FAMILY_SANS};
    `}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    margin: 0 auto;
  }
`;
