import { useMatch } from 'react-router-dom';

import { useAnalytics } from '@/hooks';
import { Routes } from '@/routes/constants';

import { Styled } from './styles';

import type { To } from 'react-router-dom';

export interface INavItemProps {
  icon?: string;
  isHomePage: boolean;
  name?: string;
  newTab?: boolean;
  path?: To;
  url?: string;
}

export const NavItem = ({
  icon,
  isHomePage = false,
  name,
  newTab,
  path,
  url,
}: INavItemProps) => {
  const { track } = useAnalytics();

  const isHome = Boolean(useMatch(Routes.HOME));
  return (
    <Styled.SideNavItem>
      <Styled.SideNavLink
        className={isHomePage && isHome ? 'active' : ''}
        onClick={() => track(`Navigation ${name} Clicked`)}
        to={url ?? path ?? Routes.HOME}
        {...(newTab && { rel: 'noopener noreferrer', target: '_blank' })}
      >
        {!!icon ? (
          <Styled.SideNavIcon alt="" src={icon} />
        ) : (
          <Styled.SideNavIconSpacer />
        )}
        {name}
      </Styled.SideNavLink>
    </Styled.SideNavItem>
  );
};
