import type { IIcon } from './typings';

export const AddIcon = ({
  color = 'currentColor',
  height = 12,
  width = 12,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.875H10.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        d="M5.875 1V10.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </svg>
  );
};
