import type { IIcon } from './typings';

const CloseIcon = ({ color = 'black', height = 32, width = 32 }: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33594 22.6693L22.6693 9.33594"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
    />
    <path
      d="M9.33594 9.33594L22.6693 22.6693"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
    />
  </svg>
);

export { CloseIcon };
