import { Divider } from '@b707/ponyta';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  ErrorDialog,
  Form,
  SideSheet,
  SuccessDialog,
  TextField,
} from '@/components';
import { genericContent } from '@/config/language';
import { ERROR_UPDATE_PROFILE_GENERIC } from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  MAX_CHARS_PROFILE_NAME,
  useGetProfile,
  useUpdateProfile,
} from '@/features/profile';
import { DetailsFormFieldNames } from '@/features/user/confirm-details';
import { validate } from '@/utils';

import { Styled } from './styles';

import type { ProfileTypes } from '@/features/profile';
import type { FieldValues } from 'react-hook-form';

type TUpdateMobileSheet = {
  onClose: () => void;
  open: boolean;
  reload: () => void;
  user?: ProfileTypes.TUser;
};

const validationSchema = Joi.object({
  [DetailsFormFieldNames.FIRST_NAME]: validate.firstName(),
  [DetailsFormFieldNames.LAST_NAME]: validate.lastName(),
}).unknown(true);

export const UpdateProfileSheet = ({
  onClose,
  open,
  reload,
}: TUpdateMobileSheet) => {
  const { data: user } = useGetProfile();

  const DEFAULT_FORM_VALUES = {
    [DetailsFormFieldNames.FIRST_NAME]: user?.first_name,
    [DetailsFormFieldNames.LAST_NAME]: user?.last_name,
  } as FieldValues;

  const { theme } = useTheme();
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: joiResolver(validationSchema),
  });

  const profileUpdate = useUpdateProfile({
    onError: () => {
      setIsOpenError(true);
    },
    onSuccess: () => {
      reload();
      setIsOpenSuccess(true);
    },
  });

  const onSubmitForm = async ({ firstName, lastName }: FieldValues) => {
    profileUpdate.mutate({
      first_name: firstName.trim(),
      last_name: lastName.trim(),
    });
  };

  useEffect(() => {
    if (user) {
      formMethods.setValue(
        DetailsFormFieldNames.FIRST_NAME,
        user.first_name || ''
      );
      formMethods.setValue(
        DetailsFormFieldNames.LAST_NAME,
        user.last_name || ''
      );
    }
  }, [formMethods, user]);

  const onCloseSuccessDialog = () => {
    setIsOpenSuccess(false);
  };

  const onCloseErrorDialog = () => {
    setIsOpenError(false);
  };

  const onCloseSideSheet = () => {
    formMethods.reset(DEFAULT_FORM_VALUES);
    onClose();
  };

  const isSubmitting = profileUpdate.isPending;

  return (
    <>
      <SideSheet
        onClose={onCloseSideSheet}
        open={open}
        subtitle="Change information associated with your flypay profile."
        title="Update your profile"
      >
        <Styled.UpdateContentWrapper>
          <Form config={formMethods} onSubmitForm={onSubmitForm}>
            <Styled.ProfileFormRow>
              <TextField
                disabled={!!isSubmitting}
                label="First name"
                maxLength={MAX_CHARS_PROFILE_NAME}
                name={DetailsFormFieldNames.FIRST_NAME}
                required
              />
              <TextField
                disabled={!!isSubmitting}
                label="Last name"
                maxLength={MAX_CHARS_PROFILE_NAME}
                name={DetailsFormFieldNames.LAST_NAME}
                required
              />
            </Styled.ProfileFormRow>
            <Styled.Footer>
              <Divider />
              <ButtonSpinner
                disabled={isSubmitting}
                level="primary"
                loading={isSubmitting}
                size="medium"
                spinnerColor={theme.styling.colors.onSurfaceC.value}
                variant="branded"
              >
                {isSubmitting ? 'Loading' : 'Save'}
              </ButtonSpinner>
            </Styled.Footer>
          </Form>
        </Styled.UpdateContentWrapper>
      </SideSheet>

      <ErrorDialog
        isOpen={isOpenError}
        message={ERROR_UPDATE_PROFILE_GENERIC}
        onOpenChange={onCloseErrorDialog}
        title={genericContent.ERROR_GENERIC_TITLE}
      />
      <SuccessDialog
        buttonText="Back to my account"
        description={`Nice. You've successfully updated your profile.`}
        isOpen={isOpenSuccess}
        onOpenChange={onCloseSuccessDialog}
        title={'Profile updated'}
      />
    </>
  );
};
