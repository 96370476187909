export enum LoyaltyEndpointKeys {
  BANK_CARDS = 'BANK_CARDS',
  FLYBUYS_CARDS = 'FLYBUYS_CARDS',
  LOYALTY_CARDS = 'LOYALTY_CARDS',
  LOYALTY_CARD_ADD = 'LOYALTY_CARD_ADD',
  LOYALTY_CARD_DELETE = 'LOYALTY_CARD_DELETE',
  MYCOLES_CARDS = 'MYCOLES_CARDS',
}

export const loyaltyQueryKeys = {
  flybuysCards: ['flybuys-cards'],
  flybuysCompleteLink: ['flybuys-complete-link'],
  flybuysCredentials: ['flybuys-credentials'],
  flybuysDelete: ['flybuys-delete'],
  flybuysGetTokens: ['flybuys-get-tokens'],
  flybuysInitiate: ['flybuys-initiate'],
  loyaltyCardAdd: ['loyalty-card-add'],
  loyaltyCards: ['loyalty-cards'],
  loyaltyCardsDelete: ['loyalty-cards-delete'],
  mycolesCards: ['mycoles-cards'],
};

export const loyaltyTransactionKeys = {
  flybuysCards: 'Get Flybuys Cards',
  loyaltyCardAdd: 'Add Loyalty Card',
  loyaltyCardDelete: 'Delete Loyalty Card',
  loyaltyCards: 'Get Loyalty Cards',
  mycolesCards: 'Get mycoles Cards',
  publicKey: 'Public Key',
};
