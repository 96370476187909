import styled from 'styled-components';

const Container = styled.div`
  [aria-haspopup='dialog'] {
    display: none;
  }
`;

const DialogDescription = styled.p`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  margin: 0;
  padding: 0;
  text-align: center;
`;

const DialogForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
`;

const DialogHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 8px;
  line-height: 1.2;
`;

const DialogLink = styled.a`
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
`;

const DialogTitle = styled.p`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-24);
  font-weight: var(--fontWeights-bold);
  margin: 0;
  padding: 0;
  text-align: center;
`;

const DialogWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);
`;

export const Styled = {
  Container,
  DialogDescription,
  DialogForm,
  DialogHeader,
  DialogLink,
  DialogTitle,
  DialogWrapper,
};
