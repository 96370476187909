import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodQueryKeys,
  paymentMethodTransactionKeys,
} from './query-keys';

import type { PaymentMethodTypes, TBaasAddPaymentMethodErrors } from '../types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useAddCard3dsFinalize(
  options?: UseMutationOptions<
    unknown,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    PaymentMethodTypes.TAddCard3dsFinalizeRequest,
    unknown
  >
) {
  const auth = useAuth();

  const addCard3dsFinalizeRequest = async (
    payload: PaymentMethodTypes.TAddCard3dsFinalizeRequest
  ) =>
    await request(
      endpoints.BANK_CARDS_FINALIZE,
      auth,
      {
        data: {
          authorization_id: payload.authorization_id,
          ...(payload.isDefault && {
            is_default_pay: true,
          }),
          payer: {
            card: {
              id: payload.card_id,
            },
          },
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_ADD_3DS_FINALIZE,
        transaction: paymentMethodTransactionKeys.bankCardAdd3DSFinalize,
      }
    );

  return useMutation({
    mutationFn: addCard3dsFinalizeRequest,
    mutationKey: paymentMethodQueryKeys.bankCardAddFinalize,
    ...options,
  });
}
