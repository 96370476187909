import { create } from 'zustand';

export enum AppErrorTypes {
  DEFAULT = 'DEFAULT',
  FLYBUYS_AUTH = 'FLYBUYS_AUTH',
  FLYBUYS_INVALID_ACCOUNT = 'FLYBUYS_INVALID_ACCOUNT',
}

export type TAppErrorKey = `${AppErrorTypes}`;

type TAppErrorActions = {
  setAppErrorType: (error: TAppErrorKey) => void;
};

interface IAppErrorStore {
  actions: TAppErrorActions;
  appErrorType: TAppErrorKey | null;
}

const useAppErrorStore = create<IAppErrorStore>((set) => ({
  actions: {
    setAppErrorType: (appErrorType) => set({ appErrorType }),
  },
  appErrorType: null,
}));

export const useAppErrorType = () =>
  useAppErrorStore((state) => state.appErrorType);
export const useAppErrorSetErrorType = () =>
  useAppErrorStore((state) => state.actions);
