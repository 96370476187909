import type { TTheme } from '@/contexts/theme';

export enum DetailsFormFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MARKETING_CONSENT = 'marketingConsent',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

export enum DetailsFormLabels {
  FIRST_NAME = 'First name*',
  LAST_NAME = 'Last name*',
  MARKETING_CONSENT = 'I wish to receive flypay marketing communications (optional).',
}

const colesPrivacyPolicyUrl =
  'https://www.coles.com.au/important-information/privacy-policy';

const privacyCollectionNotice = 'https://flypay.com.au/privacy-collection';

export const TERMS_AND_CONDITIONS_LABEL = (theme: TTheme) => (
  <p>
    I have read and agree to the flypay{' '}
    <a href={theme.urls?.terms} rel="noopener noreferrer" target="_blank">
      Terms and Conditions,
    </a>{' '}
    <a href={colesPrivacyPolicyUrl} rel="noopener noreferrer" target="_blank">
      Privacy Statement
    </a>{' '}
    and{' '}
    <a href={privacyCollectionNotice} rel="noopener noreferrer" target="_blank">
      Privacy Collection Notice
    </a>
  </p>
);

export enum DetailsFormPlaceholders {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
}
