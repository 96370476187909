import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  AddressEndpointKeys,
  addressQueryKeys,
  addressTransactionKeys,
} from './profile-query-keys';

import type { AddressesTypes } from '../types/address';

export const useGetAddresses = () => {
  const auth = useAuth();

  const getAddressesRequest = async () =>
    await request<AddressesTypes.ListAddresses>(
      endpoints.addresses.ADDRESSES,
      auth,
      { method: RequestMethod.GET },
      {
        priority: EventPriority.P2,
        requestKey: AddressEndpointKeys.ADDRESSES,
        transaction: addressTransactionKeys.addresses,
      }
    );

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: getAddressesRequest,
    queryKey: addressQueryKeys.addresses,
  });
};
