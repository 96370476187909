import {
  MobileOrder,
  OrdersEmptyPage,
  OrdersErrorPage,
  OrdersLoadingPage,
} from '@/pages/activity/order-table';

import { getGroupSortedOrders } from '../../utils';

import { Styled } from './styles';

import type { OrderServiceTypes } from '@/features/activity/services';

type TMobileOrderList = {
  isError: boolean;
  isLoading: boolean;
  onClick?: (id: string) => void;
  orders?: OrderServiceTypes.TOrder[];
};

export const MobileOrderList = ({
  isError,
  isLoading,
  onClick,
  orders,
}: TMobileOrderList) => {
  const groupedOrders = getGroupSortedOrders(orders);

  const hasorders = !!orders?.length;

  if (!isLoading && !hasorders && isError) {
    return <OrdersErrorPage />;
  }
  if (!isLoading && !hasorders) {
    return <OrdersEmptyPage />;
  }
  if (isLoading) {
    return <OrdersLoadingPage />;
  }
  return (
    <Styled.MobileOrderListWrapper>
      {!!groupedOrders &&
        groupedOrders.map((orderGroup) => {
          return (
            <Styled.MobileOrderListItemsWrapper key={orderGroup.create_at}>
              <Styled.DateTitle>{orderGroup.create_at}</Styled.DateTitle>
              {orderGroup.orders.map((order) => {
                return (
                  <MobileOrder key={order.id} onClick={onClick} order={order} />
                );
              })}
            </Styled.MobileOrderListItemsWrapper>
          );
        })}
    </Styled.MobileOrderListWrapper>
  );
};
