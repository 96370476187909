import { type ReactNode, useId } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';

import { CloseIcon } from '@/components/icons';
import { useSetA11yActions } from '@/store';

import { Styled } from './styles';

export interface IBottomSheetProps {
  ariaLabel?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
}

export const BottomSheet = ({
  ariaLabel,
  children,
  isOpen,
  onClose,
  title,
}: IBottomSheetProps) => {
  const id = useId();
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleClose = () => {
    onClose?.();
    setA11yAnnouncement('Dialog closed');
  };

  return createPortal(
    <FocusLock disabled={!isOpen} returnFocus>
      <Styled.BottomSheetWrapper
        $isOpen={isOpen}
        aria-label={ariaLabel}
        aria-labelledby={id}
        role="dialog"
        tabIndex={-1}
      >
        <Styled.BottomSheet $isOpen={isOpen}>
          <Styled.BottomSheetHeader>
            {!!title && (
              <Styled.BottomSheetTitle id={id}>{title}</Styled.BottomSheetTitle>
            )}
            {onClose && (
              <button onClick={handleClose}>
                <CloseIcon />
              </button>
            )}
          </Styled.BottomSheetHeader>

          {children}
        </Styled.BottomSheet>
      </Styled.BottomSheetWrapper>
    </FocusLock>,
    document.body
  );
};
