import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './query-keys';

import type { MutationOptions } from '@tanstack/react-query';

export function useDeleteLoyaltyCard(cardId: string, options: MutationOptions) {
  const auth = useAuth();
  const deleteCardRequest = async () =>
    await request(
      endpoints.LOYALTY_CARDS_BY_ID(cardId),
      auth,
      {
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P3,
        requestKey: LoyaltyEndpointKeys.LOYALTY_CARD_DELETE,
        transaction: loyaltyTransactionKeys.loyaltyCardDelete,
      }
    );

  return useMutation({
    mutationFn: deleteCardRequest,
    mutationKey: loyaltyQueryKeys.loyaltyCardsDelete,
    ...options,
  });
}
