import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${BreakpointRems.xs}) {
    width: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-24);
  line-height: var(--lineHeights-half);
  color: var(--colors-onSurfaceA);
  margin: 0 0 var(--space-m);

  @media (min-width: ${BreakpointRems.sm}) {
    font-size: var(--fontSizes-32);
    margin-bottom: var(--space-xl);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Styled = {
  Container,
  ContentWrapper,
  Title,
  TitleWrapper,
};
