import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  padding: 0 var(--space-l);

  @media (min-width: ${BreakpointRems.sm}) {
    padding: 0;
  }
`;

const PanelCardWrapper = styled.div`
  @media (min-width: ${BreakpointRems.sm}) {
    max-width: 520px;
  }
`;

const PanelCardContentContainer = styled.div`
  align-content: start;
  display: flex;
  gap: var(--space-m);
  width: 100%;
`;

const PanelCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-xs);
`;

const PanelCardTitle = styled.h3`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-normal);
  margin: 0;
`;

const PanelCardDescription = styled.p`
  color: var(--colors-onSurfaceA);
  font-weight: var(--fontWeights-normal);
  font-size: var(--fontSizes-14);
  line-height: 1.25;
  margin: 0;
`;

const ErrorIcon = styled.div`
  flex-basis: 80px;
  text-align: center;
`;

export const Styled = {
  Container,
  ErrorIcon,
  PanelCardContent,
  PanelCardContentContainer,
  PanelCardDescription,
  PanelCardTitle,
  PanelCardWrapper,
};
