import styled from 'styled-components';

const RangeWrapper = styled.fieldset`
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xs);
`;

const InputWrapper = styled.div`
  align-self: stretch;
  align-items: center;
  display: flex;
  gap: var(--space-s);
  margin-bottom: var(--space-xs);
`;

const RangeBox = styled.div`
  display: flex;
  padding: var(--space-s) var(--space-m);
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  background-color: var(--colors-surfaceLowest);
  border-radius: var(--radii-lg);
  border: 1px solid var(--colors-onSurfaceA);

  &:focus-within {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }
`;

const RangeInput = styled.input`
  border: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  font-size: var(--fontSizes-16);
  line-height: var(--lineHeights-half);

  &:focus {
    outline: 0;
  }
`;

const InputLabel = styled.label`
  width: 100%;
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
  line-height: var(--lineHeights-half);
  font-weight: var(--fontWeights-bold);
`;

const InputSeparator = styled.div`
  width: var(--space-s);
  height: 1px;
  background-color: var(--colors-onSurfaceC);
`;

const LabelWrapper = styled(InputWrapper)`
  gap: var(--space-xl);
  margin-bottom: 0;
`;

export const Styled = {
  InputLabel,
  InputSeparator,
  InputWrapper,
  LabelWrapper,
  RangeBox,
  RangeInput,
  RangeWrapper,
};
