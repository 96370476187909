import type { IIcon } from './typings';
export const EditIcon = ({
  color = 'currentColor',
  height = 25,
  width = 24,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 25"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.29421 17.8576L6.20044 18.3002L6.64217 15.2056L14.5983 7.24945C15.3306 6.51711 16.518 6.51711 17.2503 7.24945C17.9827 7.98179 17.9827 9.16916 17.2503 9.9015L9.29421 17.8576Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
);
