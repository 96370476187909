import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';

import type { LoaderOptions } from '@googlemaps/js-api-loader';

export enum GoogleMapsLoadingStatus {
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}

type TLoadStatus = `${GoogleMapsLoadingStatus}`;

export function useLoadGoogleMaps(options?: LoaderOptions) {
  const [status, setStatus] = useState<TLoadStatus>(
    GoogleMapsLoadingStatus.LOADING
  );

  useEffect(() => {
    const loaderOptions = options || {
      apiKey: import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API,
      libraries: ['places'],
    };

    const loader = new Loader(loaderOptions);

    const setLoadStatus = (status: TLoadStatus) => {
      setStatus(status);
    };

    setLoadStatus(GoogleMapsLoadingStatus.LOADING);

    loader
      .importLibrary('places')
      .then(() => {
        setLoadStatus(GoogleMapsLoadingStatus.SUCCESS);
      })
      .catch(() => {
        setLoadStatus(GoogleMapsLoadingStatus.FAILURE);
      });
  }, [options]);

  return {
    status,
  };
}
