import {
  OrdersEmptyPage,
  OrdersErrorPage,
  OrdersLoadingPage,
} from '@/pages/activity/order-table';

import { Styled } from './styles';
import { WidescreenOrder } from './widescreen-order';

import type { OrderServiceTypes } from '@/features/activity/services';

type TWidescreenOrdersTableProps = {
  isError: boolean;
  isLoading: boolean;
  onClick?: (id: string) => void;
  orders?: OrderServiceTypes.TOrder[];
};

export const WidescreenOrdersTable = ({
  isError,
  isLoading,
  onClick,
  orders,
}: TWidescreenOrdersTableProps) => {
  const hasOrders = !!orders?.length;

  if (!isLoading && !hasOrders && isError) {
    return <OrdersErrorPage />;
  }
  if (!isLoading && !hasOrders) {
    return <OrdersEmptyPage />;
  }
  if (isLoading) {
    return <OrdersLoadingPage />;
  }
  return (
    <Styled.OrderTable>
      <Styled.OrderTableHeader>
        <tr>
          <Styled.OrderTableHeaderCell>Date</Styled.OrderTableHeaderCell>
          <Styled.OrderTableHeaderCell>Merchant</Styled.OrderTableHeaderCell>
          <Styled.OrderTableHeaderCell>Order ID</Styled.OrderTableHeaderCell>
          <Styled.OrderTableHeaderCell>Amount</Styled.OrderTableHeaderCell>
        </tr>
      </Styled.OrderTableHeader>
      <tbody>
        {!!orders &&
          orders.map((order) => {
            return (
              <WidescreenOrder key={order.id} onClick={onClick} order={order} />
            );
          })}
      </tbody>
      <Styled.OrderTableFoot />
    </Styled.OrderTable>
  );
};
