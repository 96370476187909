import { useEffect, useId } from 'react';

import { Dialog } from '@/components';
import { ThemeWrapper } from '@/contexts/theme';
import { useAnalytics } from '@/hooks';
import { usePageTitle } from '@/hooks/use-page-title';
import { getDefaultTheme } from '@/theme';

import { DetailsForm } from './details-form';
import { Styled } from './styles';

export const ConfirmDetails = () => {
  const initialTheme = getDefaultTheme();
  const { track } = useAnalytics();

  usePageTitle('Welcome');
  const id = useId();

  useEffect(() => {
    track('Conditions Viewed');
  }, [track]);

  return (
    <Dialog
      ariaLabel="Welcome to flypay"
      body={
        <ThemeWrapper initialTheme={initialTheme}>
          <Styled.Title id={id}>Welcome to flypay</Styled.Title>
          <Styled.Subheader>A more rewarding way to pay</Styled.Subheader>
          <Styled.Panel>
            <Styled.PanelHeader>
              Lastly, what should we call you?
            </Styled.PanelHeader>
            <DetailsForm />
          </Styled.Panel>
        </ThemeWrapper>
      }
      isOpen
    />
  );
};
