import styled, { css } from 'styled-components';

const Suggestions = styled.ul`
  border-radius: var(--radii-lg);
  border: 1px solid var(--light-border-inactive, rgb(51 51 51 / 50%));
  background: var(--light-surface-default, #fff);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 4%);
  list-style: none;
  margin: 0;
  max-height: 180px;
  height: 100%;
  overflow-y: auto;
  padding: var(--space-m) 0 0 0;
  transition:
    max-height 300ms ease-out,
    border 200ms ease-out;
`;

const SuggestionListItem = styled.li<{ $selected?: boolean }>`
  color: var(--colors-onSurfaceB);
  cursor: pointer;
  font-size: var(--fontSizes-14);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 1.25;
  margin: 0;
  min-height: 36px;
  padding: var(--space-xs) var(--space-m);

  ${({ $selected }) =>
    $selected &&
    css`
      background: var(--colors-surfaceInverse);
      color: var(--colors-onSurfaceInverse);
    `}

  &:hover {
    background: var(--colors-surfaceInverse);
    color: var(--colors-onSurfaceInverse);
  }
`;

const Suggestion = styled(SuggestionListItem)`
  color: var(
    ${({ $selected }) =>
      $selected ? '--colors-onSurfaceInverse' : '--colors-onSurfaceC'}
  );
`;

export const Styled = {
  Suggestion,
  SuggestionListItem,
  Suggestions,
};
