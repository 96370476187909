import { theme } from '@/config/themes/flypay';

import type { IIcon } from './typings';

export const PendingIcon = ({
  color = theme.styling.colors.onSurfaceB.value,
  height = 16,
  width = 16,
}: IIcon) => (
  <svg
    fill="none"
    height={`${height}`}
    viewBox="0 0 16 17"
    width={`${width}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8 3.41929C5.194 3.41929 2.91929 5.694 2.91929 8.5C2.91929 11.306 5.194 13.5807 8 13.5807C10.806 13.5807 13.0807 11.306 13.0807 8.5C13.0807 5.694 10.806 3.41929 8 3.41929ZM2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8 4.34679C8.25385 4.34679 8.45964 4.55257 8.45964 4.80643V8.04036H11.6936C11.9474 8.04036 12.1532 8.24615 12.1532 8.5C12.1532 8.75385 11.9474 8.95964 11.6936 8.95964H8C7.74615 8.95964 7.54036 8.75385 7.54036 8.5V4.80643C7.54036 4.55257 7.74615 4.34679 8 4.34679Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
