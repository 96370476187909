import activityIcon from '@/assets/images/activity.svg';
import addressIcon from '@/assets/images/address.svg';
import helpIcon from '@/assets/images/help.svg';
import profileIcon from '@/assets/images/profile.svg';
import walletIcon from '@/assets/images/wallet.svg';
import { SideNav } from '@/components';
import { Feature } from '@/config/feature-flags';
import { useTheme } from '@/contexts/theme';
import { Routes } from '@/routes/constants';

type TWalletSideNav = {
  onLogout?: () => void;
};

export const WalletSideNav = ({ onLogout }: TWalletSideNav) => {
  const { theme } = useTheme();

  const getHomePage = (): string => {
    switch (theme.homePage) {
      case Feature.ACTIVITY: {
        return Routes.ACTIVITY;
      }
      case Feature.WALLET: {
        return Routes.WALLET;
      }
      case Feature.ACCOUNT: {
        return Routes.ACCOUNT;
      }
      case Feature.ADDRESSES: {
        return Routes.ADDRESSES;
      }
      default: {
        return Routes.ACTIVITY;
      }
    }
  };

  const sideNavItems = [
    {
      icon: walletIcon,
      isHomePage: Routes.WALLET === getHomePage(),
      name: 'Wallet',
      path: Routes.WALLET,
    },
    {
      icon: activityIcon,
      isHomePage: Routes.ACTIVITY === getHomePage(),
      name: 'Activity',
      path: Routes.ACTIVITY,
    },
    {
      icon: profileIcon,
      isHomePage: Routes.ACCOUNT === getHomePage(),
      name: 'Account',
      path: Routes.ACCOUNT,
    },
    {
      icon: addressIcon,
      isHomePage: Routes.ADDRESSES === getHomePage(),
      name: 'Addresses',
      path: Routes.ADDRESSES,
    },
  ];
  const sideNavFooterItems = [
    ...(!!theme.urls?.contact
      ? [
          {
            icon: helpIcon,
            isHomePage: false,
            name: 'Need help?',
            newTab: true,
            url: theme.urls?.contact,
          },
        ]
      : []),
  ];
  return (
    <SideNav
      footerItems={sideNavFooterItems}
      items={sideNavItems}
      onLogout={onLogout}
    />
  );
};
