export function formatMycolesBarcode(input: string) {
  const value = input.replaceAll(/\s+/g, '').replaceAll(/\D/gi, '');
  let formattedValue = '';
  for (let i = 0; i < value.length; i++) {
    if (i === 1 || i === 7) {
      formattedValue += ' ';
    }
    formattedValue += value.charAt(i);
  }
  return formattedValue;
}
