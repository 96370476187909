import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
`;

const Nickname = styled.div`
  margin-bottom: var(--space-l);
`;

const FormContainer = styled.div<{ $isHidden?: boolean }>`
  display: ${(props) => (props.$isHidden ? 'none' : 'initial')};
`;

export const Styled = {
  Container,
  Footer,
  FormContainer,
  Nickname,
};
