import styled, { css } from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  position: relative;
  width: 100%;
  flex: 1;
`;

export const labelCSS = css`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
  margin: 0;
  text-align: left;
`;

export const Label = styled.label`
  ${labelCSS}
`;

export const baseInputCSS = css`
  box-sizing: border-box;
  font-family: inherit;
  height: 2.5rem;
  width: 100%;
  padding: var(--space-s) var(--space-m);
  background-color: var(--colors-surfaceLowest);
  border: 1px solid var(--colors-onSurfaceC);
  border-radius: var(--radii-md);
  font-size: var(--fontSizes-16);
  color: var(--colors-onSurfaceA);
  margin-top: var(--space-xs);

  @media (min-width: ${BreakpointRems.sm}) {
    margin-bottom: var(--space-xs);
  }
`;

export const baseInputDisabledCSS = css`
  background-color: var(--colors-surfaceLow);
  border: 1px solid var(--colors-onSurfaceD);
  color: var(--colors-onSurfaceC);
  cursor: not-allowed;
`;

export const InputRoot = styled.div<{
  $disabled?: boolean;
  $hasError: boolean;
}>`
  ${baseInputCSS}
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border: ${({ $hasError }) =>
      $hasError
        ? '1px solid var(--colors-onErrorLow)'
        : '1px solid var(--colors-onSurfaceB)'};
  }

  &:focus-within {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }

  ${(props) =>
    props.$disabled &&
    css`
      ${baseInputDisabledCSS}

      input {
        cursor: not-allowed;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--colors-onErrorLow);
    `}
`;

const CardImageContainer = styled.div`
  width: 3rem;
`;

export const Prefix = styled.p`
  color: var(--colors-onSurfaceA);
`;

export const TextInput = styled.input<{ $hasError: boolean }>`
  border-color: transparent;
  background-color: transparent;
  font-family: inherit;
  outline: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const DummyLabel = styled.div`
  ${labelCSS}
`;

const DummyInput = styled.div`
  ${baseInputCSS}
  ${baseInputDisabledCSS}
`;

const HelpText = styled.div`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-14);
  padding-top: var(--space-2xs);
`;

export const Styled = {
  CardImageContainer,
  DummyInput,
  DummyLabel,
  HelpText,
  InputRoot,
  InputWrapper,
  Label,
  Prefix,
  TextInput,
};
