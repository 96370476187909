import { useEffect, useState } from 'react';

import { Breakpoints } from '@/config/breakpoints';

import { useWindowSize } from './use-window-size';

import type { TBreakpointKeys } from '@/config/breakpoints';

export type TBreakpoints = Record<TBreakpointKeys, boolean>;

export const useBreakpoints = () => {
  const windowSize = useWindowSize();
  const [breakpoints, setBreakpoints] = useState<TBreakpoints>({
    '2xs': false,
    lg: false,
    md: false,
    sm: false,
    xl: false,
    xs: false,
  });

  useEffect(() => {
    const currentBreakpoints = Object.keys(Breakpoints).reduce((acc, key) => {
      const breakpointValue = Breakpoints[key as TBreakpointKeys];
      acc[key as TBreakpointKeys] =
        !!windowSize.width && windowSize?.width >= breakpointValue;
      return acc;
    }, {} as TBreakpoints);
    setBreakpoints(currentBreakpoints);
  }, [windowSize]);

  return breakpoints;
};
