import { useFormContext } from 'react-hook-form';

import type React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

type FormReturn = UseFormReturn<FieldValues, object>;

export type TConnectFormProps = {
  children(props: FormReturn): React.ReactElement;
};

export type TConnectFormCallback = FormReturn;

// * ConnectForm - utitlity component using useFormContext to access react-hook-form's
// * form context properties, usually for form elements nested within a form.
// *
// * This has to be used within react-hook-form's FormProvider to function
// * or used in lieu with the Form component eg.
// * <Form><ConnectForm>{({ formState }) => ...}</ConnectForm></Form>
export function ConnectForm({ children }: TConnectFormProps) {
  const formContext = useFormContext();
  return children({ ...formContext });
}
