import axios from 'axios';
import merge from 'deepmerge';
import isEmpty from 'lodash.isempty';
import qs from 'qs';

import { sentry } from '@/features/monitoring';
import { EventPriority } from '@/features/monitoring/enums';
import { Routes } from '@/routes/constants';

import { interceptRequest } from './intercept-request';

import type { TAxiosResponseWithHeaders } from './typings';
import type { AxiosRequestConfig } from 'axios';
import type { AuthContextProps } from 'react-oidc-context';

const REQUEST_CONFIG_DEFAULTS: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_REACT_APP_BAAS_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
  timeout: 30_000,
};

export enum RequestMethod {
  DELETE = 'delete',
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PURGE = 'purge',
  PUT = 'put',
}

type TRequestContext = {
  priority?: `${EventPriority}`;
  requestKey: string;
  skipLogErrorNumber?: string;
  transaction: string;
};

// Overloads
export async function request<Data>(
  endpoint: string,
  auth: AuthContextProps,
  config?: AxiosRequestConfig,
  context?: TRequestContext
): Promise<Data>;
export async function request<Data>(
  endpoint: string,
  auth: AuthContextProps,
  config?: AxiosRequestConfig & { withResponseHeaders: boolean },
  context?: TRequestContext
): Promise<TAxiosResponseWithHeaders<Data>>;
export async function request<Data>(
  endpoint: string,
  auth: AuthContextProps,
  config: AxiosRequestConfig & { withResponseHeaders?: boolean } = {},
  context?: TRequestContext
): Promise<Data> {
  config = merge(REQUEST_CONFIG_DEFAULTS, config);

  interceptRequest(axios, auth.user?.access_token);

  return axios(endpoint, config)
    .then((response) => {
      if (config.withResponseHeaders) {
        return { data: response.data, headers: response.headers };
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (!auth.user?.access_token || error?.response?.status === 401) {
        window.location.replace(Routes.LOGIN_REDIRECT);
        return;
      }

      const transactionEvent = context?.transaction
        ? `${context?.transaction} Failure`
        : 'A Request Failure Has Occurred';

      // If the error number is the same as the one we want to skip, don't log it
      if (
        !context?.skipLogErrorNumber ||
        error?.response?.data?.details?.error_number?.toString() !==
          context?.skipLogErrorNumber
      ) {
        sentry.captureError(transactionEvent, error?.message, {
          tags: {
            correlation_id: error?.response?.data?.details?.correlation_id,
            error_message: error?.response?.data?.details?.message,
            error_number: error?.response?.data?.details?.error_number,
            priority: context?.priority || EventPriority.P2,
          },
        });
      }

      return Promise.reject({
        ...error,
        ...context,
        ...(!isEmpty(error?.response?.data) && error.response.data),
      });
    });
}
