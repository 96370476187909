import { Styled } from './styles';

import type { ISpinnerProps } from './typings';

export const Spinner = ({
  $secondaryColor,
  $thickness = 2,
  color,
  message,
  size = 60,
}: ISpinnerProps) => (
  <Styled.SpinnerContainer
    aria-busy="true"
    aria-valuetext={message ?? 'Loading...'}
    role="progressbar"
  >
    <Styled.Spinner
      $color={color}
      $secondaryColor={$secondaryColor}
      $size={size}
      $thickness={$thickness}
    />
    {message && <Styled.SpinnerMessage>{message}</Styled.SpinnerMessage>}
  </Styled.SpinnerContainer>
);
