import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import omit from 'lodash.omit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SideSheet, SuccessDialog } from '@/components';
import { useGetAddresses } from '@/features/profile/services';
import { useAnalytics, useLoadGoogleMaps } from '@/hooks';
import { AddAddress } from '@/pages/addresses/add-address';
import { validate } from '@/utils';

import { EditAddress } from '../edit-address';
import { SettingsStates, SuccessDialogStates } from '../enums';
import { AddressDetails, AddressList } from '../index';
import { Styled } from '../styles';

import { getAddressSuccessMessage, sortAddresses } from './utils';

import type { AddressesTypes } from '@/features/profile/';
import type { FieldValues, FormState } from 'react-hook-form';

enum FormFields {
  COUNTRY = 'country',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  POSTCODE = 'postcode',
  STATE = 'state',
  STREET_ADDRESS = 'streetAddress',
  STREET_ADDRESS_2 = 'streetAddress2',
  SUBURB = 'suburb',
}

type TFormFields = FormFields;

type TFormValues = {
  [key in FormFields]: string;
};

type TFormFieldName = FormState<FieldValues> & { name?: TFormFields };

const DEFAULT_FORM_VALUES = {
  [FormFields.FIRST_NAME]: '',
  [FormFields.LAST_NAME]: '',
  [FormFields.POSTCODE]: '',
  [FormFields.COUNTRY]: '',
  [FormFields.STATE]: '',
  [FormFields.STREET_ADDRESS_2]: '',
  [FormFields.STREET_ADDRESS]: '',
  [FormFields.SUBURB]: '',
} as FieldValues;

export const AddressSettings = () => {
  useLoadGoogleMaps();
  const { track } = useAnalytics();

  const { data, isError, refetch } = useGetAddresses();
  const sortedAddresses = sortAddresses(data);

  const [settingsState, setSettingsState] = useState<SettingsStates | null>(
    SettingsStates.VIEW
  );

  const validationSchema = Joi.object({
    [FormFields.COUNTRY]: validate.requiredText('Country'),
    [FormFields.FIRST_NAME]: validate.requiredText('First Name'),
    [FormFields.LAST_NAME]: validate.requiredText('Last Name'),
    [FormFields.POSTCODE]: validate.requiredText('Postcode'),
    [FormFields.STATE]: validate.requiredText('State'),
    [FormFields.STREET_ADDRESS]: validate.requiredText('Street Address'),
    [FormFields.SUBURB]: validate.requiredText('Suburb'),
  }).unknown(true);

  const form = useForm<TFormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'onSubmit',
    resolver: joiResolver(validationSchema),
  });

  const formFieldName = (form.formState as TFormFieldName).name;

  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false);
  const [isAddressSuccessOpen, setIsAddressSuccessOpen] = useState(false);
  const [successDialogState, setSuccessDialogState] =
    useState<SuccessDialogStates | null>(null);

  const [selectedAddressId, setSelectedAddressId] = useState<string>('');
  const [selectedAddress, setSelectedAddress] =
    useState<AddressesTypes.AddressResponse | null>(null);

  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    // * NOTE: focus set manually as state updates is causing rerenders
    // * so that the targeted input does not lose focus
    if (!!formFieldName) {
      form.setFocus(formFieldName as TFormFields, { shouldSelect: true });
    }
  }, [form, formFieldName]);

  const onClickAddBtn = () => {
    setSettingsState(SettingsStates.ADD);
    setIsDefault(false);
    setSelectedAddressId('');
    setIsAddAddressOpen(true);
  };

  const onClickAddress = (
    address: AddressesTypes.AddressResponse,
    addressId: string,
    isDefaultAddress: boolean
  ) => {
    form.reset({
      ...omit(address, [
        'address_line_1',
        'address_line_2',
        'country_code',
        'recipient_name',
        'timestamps',
      ]),
      [FormFields.COUNTRY]: address?.country_code,
      [FormFields.FIRST_NAME]: address.recipient_name?.split(' ')[0],
      [FormFields.LAST_NAME]: address.recipient_name?.split(' ')[1],
      [FormFields.STREET_ADDRESS]: address.address_line_1 || '',
      [FormFields.STREET_ADDRESS_2]: address.address_line_2 || '',
    });
    setSelectedAddressId(addressId);
    setSelectedAddress(address);
    setSettingsState(SettingsStates.VIEW);
    setIsDefault(isDefaultAddress);
    setIsAddAddressOpen(true);
  };

  const onClickCancel = () => {
    setIsAddAddressOpen(false);
  };

  const getTitle = () => {
    if (settingsState === SettingsStates.VIEW) {
      return 'Address details';
    }

    if (settingsState === SettingsStates.ADD) {
      return 'Add new address';
    }

    return 'Edit address';
  };

  const reloadAddresses = async () => {
    await refetch();
    setIsAddAddressOpen(false);
  };

  const onCloseAddSuccessDialog = () => {
    setIsAddressSuccessOpen(false);
  };

  const onSuccessAddAddress = () => {
    reloadAddresses();
    setSuccessDialogState(SuccessDialogStates.ADD);
    setIsAddressSuccessOpen(true);
  };

  const onSuccessDeleteAddress = (isDelete?: boolean) => {
    reloadAddresses();
    setIsAddressSuccessOpen(true);
    setSuccessDialogState(
      isDelete ? SuccessDialogStates.DELETE : SuccessDialogStates.SET_DEFAULT
    );
  };

  const handleEditCardOpen = () => {
    track('Update Address Started');
    setSettingsState(SettingsStates.EDIT);
    setIsEditAddressOpen(true);
  };

  const onEditSuccess = () => {
    setIsEditAddressOpen(false);
    setSettingsState(null);
    setIsAddressSuccessOpen(true);
    setSuccessDialogState(SuccessDialogStates.EDIT);
    reloadAddresses();
  };

  const { description, title } = getAddressSuccessMessage(successDialogState);

  return (
    <Styled.Container>
      <SideSheet
        onClose={onClickCancel}
        open={isAddAddressOpen}
        title={getTitle()}
      >
        {settingsState === SettingsStates.ADD && (
          <AddAddress
            isAddAddressOpen={isAddAddressOpen}
            reloadAddresses={onSuccessAddAddress}
          />
        )}

        {settingsState === SettingsStates.VIEW && (
          <AddressDetails
            address={selectedAddress}
            isDefault={isDefault}
            onClickEdit={handleEditCardOpen}
            reloadAddresses={(isDelete?: boolean) =>
              onSuccessDeleteAddress(isDelete)
            }
            selectedAddressId={selectedAddressId}
          />
        )}

        {settingsState === SettingsStates.EDIT && !!selectedAddress && (
          <EditAddress
            address={selectedAddress}
            addressId={selectedAddressId}
            isDefault={isDefault}
            isEditAddressOpen={isEditAddressOpen}
            onSuccess={onEditSuccess}
          />
        )}
      </SideSheet>

      <AddressList
        addresses={sortedAddresses}
        isError={isError}
        onClickAddBtn={onClickAddBtn}
        onClickAddress={onClickAddress}
        selectedAddressId={selectedAddressId}
      />

      {isAddressSuccessOpen && (
        <SuccessDialog
          buttonText="Continue"
          description={description}
          isOpen={isAddressSuccessOpen}
          onOpenChange={onCloseAddSuccessDialog}
          title={title}
        />
      )}
    </Styled.Container>
  );
};
