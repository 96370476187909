import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const DatePickerWrapper = styled.div<{ $showCalendar: boolean }>`
  background-color: var(--colors-surfaceLowest);
  border: none;
  border-radius: var(--radii-lg);
  box-shadow: var(--shadows-l4);
  height: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(100% + 4px);
  visibility: hidden;
  width: 100%;
  z-index: 99;

  ${(props) => {
    if (props.$showCalendar) {
      return `
      height: 22.25rem;
      border: 1px solid var(--colors-onSurfaceA);
      padding: 24px;
      overflow: visible;
      margin-bottom: var(--space-l);
      visibility: visible;

      @media (min-width: ${BreakpointRems.xs}) {
        height: 26.25rem;
      }
      `;
    }
  }}

  transition: all 0.2s ease-in-out;

  /* Styles for the react-date-picker library */
  .rdp {
    height: 0;
    margin: 0;
    overflow: hidden;

    --rdp-cell-size: 36px;
    --rdp-caption-font-size: 14px;
    --rdp-accent-color: #000;
    --rdp-background-color: #ddd;

    @media (min-width: ${BreakpointRems.xs}) {
      --rdp-cell-size: 42px;
    }

    ${(props) => {
      if (props.$showCalendar) {
        return `
      height: 22.75rem;
      overflow: visible;
      `;
      }
    }}
    transition: all 0.2s ease-in-out;
  }
  /* stylelint-disable selector-class-pattern */
  .rdp-head_cell {
    color: #707070;
    font-size: 10px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .rdp-day {
    border-radius: 8px;
    font-size: 12px;
    line-height: 140%;
    max-width: 42px;
  }

  .rdp-day_range_middle {
    border-radius: 0;
  }

  .rdp-day_today {
    text-decoration: underline;
  }

  .rdp-table {
    border-collapse: separate;
    border-spacing: 0 4px;
  }

  .rdp-months {
    justify-content: center;
  }

  .rdp-tbody > tr > :first-child button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rdp-tbody > tr > :last-child button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .rdp-day_selected {
    background-color: #707070;
    color: #f1f1f1;
  }

  .custom-range-end {
    background-color: #000;
    border-radius: 0 8px 8px 0;
  }

  .custom-range-start {
    background-color: #000;
    border-radius: 8px 0 0 8px;
  }

  .custom-range-end.custom-range-start {
    border-radius: 8px;
  }
`;

export const Styled = {
  DatePickerWrapper,
};
