import { useState } from 'react';

import { Dropdown } from '../dropdown';

import { SearchInput } from './search-input';
import { Styled } from './styles';

import type { TSearchProps } from './typings';
import type { TOption } from '../dropdown';

export const Search = ({
  $size,
  children,
  disabled,
  error,
  handleClear,
  handleSearch,
  initialQuery,
  options,
  placeholder,
}: TSearchProps) => {
  const [searchValue, setSearchValue] = useState(initialQuery?.query);
  const [categoryValue, setCategoryValue] = useState(initialQuery?.category);

  const onClickClear = () => {
    setSearchValue('');
    handleClear && handleClear();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch?.({ category: categoryValue, query: searchValue });
  };

  const onDropdownChange = (value: TOption | null) => {
    setCategoryValue(value?.value ?? '');
  };

  return (
    <Styled.SearchContainer $size={$size} onSubmit={onSubmit}>
      {!!options && (
        <Styled.SearchInfo>
          <Dropdown
            disabled={disabled}
            id={'searchBy'}
            label="Search by"
            onChange={onDropdownChange}
            options={options}
            placeholder={placeholder || 'Select...'}
            required={false}
            size={$size}
          />
        </Styled.SearchInfo>
      )}
      <Styled.SearchInfo>
        <Styled.SearchLabel htmlFor="searchFor">Search for</Styled.SearchLabel>
        <SearchInput
          $size={$size}
          disabled={disabled}
          error={error}
          id="searchFor"
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onClickClear={onClickClear}
          placeholder={placeholder || 'Search...'}
          showClear={!!searchValue}
          value={searchValue}
        />
      </Styled.SearchInfo>
      {children}
    </Styled.SearchContainer>
  );
};
