import { Dialog as DialogBase } from '@b707/ponyta';

import { BottomSheet } from '@/components';
import { BREAKPOINT_BOTTOM_SHEET_TO_DIALOG } from '@/config/breakpoints';
import { useWindowSize } from '@/hooks';

interface IDialog {
  ariaLabel?: string;
  body?: React.ReactNode;
  description?: string;
  hasCloseIcon?: boolean;
  intro?: React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: () => void;
  title?: string;
}

export const Dialog = ({
  ariaLabel,
  body,
  description,
  hasCloseIcon,
  intro,
  isOpen,
  onOpenChange,
  title,
}: IDialog) => {
  const windowSize = useWindowSize();

  return (windowSize?.width || 0) > BREAKPOINT_BOTTOM_SHEET_TO_DIALOG ? (
    <DialogBase
      ariaLabel={ariaLabel}
      body={body}
      description={description}
      hasCloseIcon={hasCloseIcon}
      intro={intro}
      onOpenChange={onOpenChange}
      open={isOpen}
      title={title}
    />
  ) : (
    <>
      {isOpen && (
        <BottomSheet ariaLabel={ariaLabel} isOpen={isOpen} title={title}>
          {intro}
          {body}
        </BottomSheet>
      )}
    </>
  );
};
