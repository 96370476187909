import { genericContent } from '@/config/language';

import { CustomerService } from './customer-service';

export const TryAgainMessage = ({ what = '' }) => {
  return (
    <span>
      {genericContent.TRY_AGAIN(what)} <CustomerService />.
    </span>
  );
};
