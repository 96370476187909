import styled from 'styled-components';

const VisuallyHidden = styled.div`
  height: 1px;
  left: -10000px;
  position: absolute;
  overflow: hidden;
  top: auto;
  width: 1px;
`;

export const Styled = { VisuallyHidden };
