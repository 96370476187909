import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-32);
  line-height: 1.2;
  margin-bottom: var(--space-l);
  letter-spacing: -0.02em;
  color: var(--colors-onSurfaceA);
`;

const CardsWrapper = styled.ul`
  display: flex;
  gap: var(--space-l);
  flex-direction: column;
  width: 100%;
  padding: 0;

  > div {
    cursor: pointer;
    transition: border 0.1s ease-in-out;

    &:hover {
      border: 1px solid #007dc1;
      border-radius: var(--radii-lg);
    }
  }
`;

const PaymentCardItemWrapper = styled.li`
  width: 100%;
  list-style: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  width: 100%;

  @media (min-width: ${BreakpointRems.lg}) {
    align-items: flex-start;
    flex-direction: row;
    gap: 4rem;
  }
`;

const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-xl);
  max-width: 480px;

  @media (min-width: ${BreakpointRems.lg}) {
    max-width: 480px;
    width: calc(50% - var(--space-xl) / 2);
  }
`;

export const Styled = {
  CardsWrapper,
  Column,
  Container,
  PaymentCardItemWrapper,
  SubTitle,
};
