import { Button as PonytaButton } from '@b707/ponyta';
import styled, { css } from 'styled-components';

import type { ButtonLevel, ButtonVariant } from '@b707/ponyta';

type ButtonStyle = {
  bg: string;
  border?: boolean;
  color: string;
  fill?: string;
};

const primaryStyles = (variant: ButtonVariant): ButtonStyle => {
  switch (variant) {
    case 'branded': {
      return {
        bg: 'var(--colors-brand1Highest)',
        color: 'var(--colors-onBrand1High)',
      };
    }
    case 'destructive': {
      return {
        bg: 'var(--colors-errorHigh)',
        color: 'var(--colors-onErrorHigh)',
      };
    }
    default: {
      return {
        bg: 'var(--colors-surfaceInverse)',
        color: 'var(--colors-onSurfaceInverse)',
      };
    }
  }
};

const secondaryStyles = (variant: ButtonVariant): ButtonStyle => {
  switch (variant) {
    case 'branded': {
      return {
        bg: 'var(--colors-brand1Lower)',
        color: 'var(--colors-onBrand1Lower)',
      };
    }
    case 'destructive': {
      return {
        bg: 'transparent',
        border: true,
        color: 'var(--colors-onErrorLow)',
      };
    }
    default: {
      return {
        bg: 'var(--colors-surfaceHighest)',
        color: 'var(--colors-onSurfaceHighest)',
      };
    }
  }
};

const tertiaryStyles = (variant: ButtonVariant): ButtonStyle => {
  switch (variant) {
    case 'branded': {
      return {
        bg: 'transparent',
        border: true,
        color: 'var(--colors-onBrand1Low)',
      };
    }
    case 'destructive': {
      return {
        bg: 'transparent',
        border: true,
        color: 'var(--colors-onErrorLow)',
      };
    }
    default: {
      return {
        bg: 'transparent',
        border: true,
        color: 'var(--colors-onSurfaceA)',
      };
    }
  }
};

const ButtonStyle = styled(PonytaButton)<{
  $level?: ButtonLevel;
  $variant: ButtonVariant;
}>`
  ${({ $level, $variant }) => {
    let bg = '';
    let color = '';
    let fill: string | undefined = '';
    let border: boolean | undefined = false;
    switch ($level) {
      case 'primary': {
        ({ bg, border, color, fill } = primaryStyles($variant));
        break;
      }
      case 'secondary': {
        ({ bg, border, color, fill } = secondaryStyles($variant));
        break;
      }
      default: {
        ({ bg, border, color, fill } = tertiaryStyles($variant));
        break;
      }
    }
    return css`
      background-color: ${bg};
      color: ${color};
      fill: ${fill ?? color};
      border-radius: var(--radii-md);
      border: ${border ? `1px solid ${color}` : 'none'};
    `;
  }}
`;

export const Styled = { ButtonStyle };
