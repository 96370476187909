// Use this hook to disable scroll on the body element while retaining the scroll position. This is useful for modals, side sheets, and other components that appear on top of the page content.

import { useEffect } from 'react';

export const useDisableScroll = (isScrollDisabled?: boolean) => {
  useEffect(() => {
    const scrollY = window.scrollY;
    if (!!isScrollDisabled) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.right = '0';
      document.body.style.left = '0';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.right = '';
      window.scrollTo(0, Number.parseInt(scrollY || '0') * -1);
    }
  }, [isScrollDisabled]);
};
