import type { IIcon } from './typings';

const AlertIcon = ({
  color = 'currentColor',
  height = 24,
  width = 24,
}: IIcon) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.93 17.3909L14.19 4.19086C13.27 2.61086 10.97 2.61086 10.05 4.19086L2.33004 17.3909C1.40004 18.9909 2.55004 21.0009 4.40004 21.0009H19.85C21.7 21.0009 22.86 18.9809 21.92 17.3909H21.93Z"
      fill="white"
    />
    <path
      d="M21.93 17.3909L14.19 4.19086C13.27 2.61086 10.97 2.61086 10.05 4.19086L2.33004 17.3909C1.40004 18.9909 2.55004 21.0009 4.40004 21.0009H19.85C21.7 21.0009 22.86 18.9809 21.92 17.3909H21.93ZM11.17 8.29086C11.17 7.81086 11.57 7.42086 12.04 7.42086C12.51 7.42086 12.91 7.82086 12.91 8.29086V13.5309C12.91 14.0109 12.51 14.4009 12.04 14.4009C11.57 14.4009 11.17 14.0009 11.17 13.5309V8.29086ZM12.04 17.9009C11.43 17.9009 10.94 17.4109 10.94 16.8009C10.94 16.1909 11.43 15.7009 12.04 15.7009C12.65 15.7009 13.14 16.1909 13.14 16.8009C13.14 17.4109 12.65 17.9009 12.04 17.9009Z"
      fill={color}
    />
  </svg>
);

export { AlertIcon };
