import { compareDesc, isSameDay } from 'date-fns';

import type { TPaymentInfo, TTransactionInfo } from '../types';

export const sortPayments = (payments: TPaymentInfo[]): TPaymentInfo[] => {
  return sortPaymentsByDate(payments);
};
export const sortTransactionsByAmount = (transactions: TTransactionInfo[]) => {
  if (transactions.length < 2) {
    return transactions;
  }
  const sortedTransactions = transactions.sort((a, b) => {
    const first = a.amount || 0;
    const second = b.amount || 0;
    return second - first;
  });
  return !!sortedTransactions ? sortedTransactions : transactions;
};

export const sortPaymentsByDate = (
  payments: TPaymentInfo[]
): TPaymentInfo[] => {
  return payments.sort((a, b) => {
    const dateComparison = compareDesc(b.date, a.date);
    if (!isSameDay(a.date, b.date)) {
      return dateComparison;
    } else {
      const aHasPending = a.transactions.some(
        (transaction) => transaction.status === 'pending'
      );
      const bHasPending = b.transactions.some(
        (transaction) => transaction.status === 'pending'
      );
      if (aHasPending && !bHasPending) {
        return 1;
      } else if (!aHasPending && bHasPending) {
        return -1;
      } else {
        return 0;
      }
    }
  });
};
