import { LoyaltyCard } from '@b707/ponyta';

import { ButtonSpinner } from '@/components';
import { useTheme } from '@/contexts/theme';
import { splitNumberWithCommas, toTitleCase } from '@/utils';
import { formatFlybuysNumber } from '@/utils/format';

import { Styled } from '../styles';

import type { PaymentMethodTypes } from '@/features/card-management';

interface ILoyaltyDetailsProps {
  card?: PaymentMethodTypes.TLoyaltyCard;
  onClickUnlink: () => void;
}
export const FlybuysDetails = ({
  card,
  onClickUnlink,
}: ILoyaltyDetailsProps) => {
  const { theme } = useTheme();

  const formattedBalance = splitNumberWithCommas(
    card?.live_data?.points_balance || 0
  );

  const cardNumber = formatFlybuysNumber(card?.live_data?.card_number || '');

  const accountName = toTitleCase(
    `${card?.live_data?.first_name || ''} ${card?.live_data?.last_name || ''}`
  ).trim();

  return (
    <Styled.LoyaltyDetailsWrapper>
      <Styled.LoyaltyDetailsBodyContainer>
        <LoyaltyCard
          balanceText={formattedBalance}
          scheme="flybuys"
          size="lg"
        />
        <Styled.LoyaltyDetailsBody>
          <Styled.LoyaltyDetailsTitle>Flybuys</Styled.LoyaltyDetailsTitle>
          <Styled.LoyaltyDetails>
            <Styled.LoyaltyDetailsRow>
              <Styled.LoyaltyDetailsText>Points</Styled.LoyaltyDetailsText>
              <Styled.LoyaltyDetailsValue>
                {formattedBalance}
              </Styled.LoyaltyDetailsValue>
            </Styled.LoyaltyDetailsRow>
            {!!cardNumber && (
              <Styled.LoyaltyDetailsRow>
                <Styled.LoyaltyDetailsText>
                  Flybuys Number
                </Styled.LoyaltyDetailsText>
                <Styled.LoyaltyDetailsValue>
                  {cardNumber}
                </Styled.LoyaltyDetailsValue>
              </Styled.LoyaltyDetailsRow>
            )}
            {!!accountName && (
              <Styled.LoyaltyDetailsRow>
                <Styled.LoyaltyDetailsText>
                  Linked account
                </Styled.LoyaltyDetailsText>
                <Styled.LoyaltyDetailsValue>
                  {accountName}
                </Styled.LoyaltyDetailsValue>
              </Styled.LoyaltyDetailsRow>
            )}
          </Styled.LoyaltyDetails>
        </Styled.LoyaltyDetailsBody>
      </Styled.LoyaltyDetailsBodyContainer>

      <Styled.LoyaltyDetailsActions>
        <ButtonSpinner
          level="tertiary"
          onClick={onClickUnlink}
          size="medium"
          spinnerColor={theme.styling.colors.errorLowest.value}
          variant="destructive"
        >
          Unlink account
        </ButtonSpinner>
      </Styled.LoyaltyDetailsActions>
    </Styled.LoyaltyDetailsWrapper>
  );
};
