/* eslint-disable @typescript-eslint/no-explicit-any */

import { PaymentMethodEnums } from '@/features/card-management';

import { validateCardExpiryDate } from '../../../utils/validation/card-expiry-date';

import type { PaymentMethodTypes } from '@/features/card-management';

const chunkCards = (cards: PaymentMethodTypes.TBankCard[]) => {
  const notDefault = cards.filter((card) => !card.is_default_pay);
  return {
    default: cards.filter((card) => card.is_default_pay),
    expired: notDefault.filter(
      (card) => card.status === PaymentMethodEnums.BankCardStatus.EXPIRED
    ),
    invalid: notDefault.filter(
      (card) => card.status == PaymentMethodEnums.BankCardStatus.INVALID
    ),
    validated: notDefault.filter(
      (card) => card.status === PaymentMethodEnums.BankCardStatus.VALIDATED
    ),
    verified: notDefault.filter(
      (card) => card.status === PaymentMethodEnums.BankCardStatus.VERIFIED
    ),
  };
};

const sortExpiredLast = (cards: PaymentMethodTypes.TBankCard[]) =>
  [...cards].sort((a, b) => {
    const aExpiry = `${a.data.expiry.month}/${a.data.expiry.year}`;
    const bExpiry = `${b.data.expiry.month}/${b.data.expiry.year}`;
    return (
      (!validateCardExpiryDate(aExpiry) as any) -
      (!validateCardExpiryDate(bExpiry) as any)
    );
  });

const sortVerifiedCards = (cards: PaymentMethodTypes.TBankCard[]) => {
  const expirySorted = sortExpiredLast(cards);
  return [...expirySorted].sort((a, b) => {
    return (a.is_default_pay as any) - (b.is_default_pay as any);
  });
};

export const sortPaymentCards = (cards: PaymentMethodTypes.TBankCard[]) => {
  const paymentCards = chunkCards(cards);
  const sortedVerifiedCards = sortVerifiedCards(paymentCards.verified);

  return [
    ...paymentCards.default,
    ...sortedVerifiedCards,
    ...paymentCards.expired,
    ...paymentCards.validated,
    ...paymentCards.invalid,
  ];
};
