export const profileContent = {
  DELETED_ACCOUNT_TITLE: 'Done! Your account has been deleted',
  LOGGED_OUT_TITLE: "Done! You're logged out.",
  TIMED_OUT_MESSAGE: 'Sign in again to continue using your flypay wallet.',
  TIMED_OUT_TITLE: 'Your session has expired',
  UPDATE_EMAIL_MESSAGE: (wallet = 'flypay') =>
    `Add a new email address to use with your ${wallet} account. We'll send you a verification code to confirm the update.`,
  UPDATE_EMAIL_TITLE: 'Update your email address',
  VERIFY_EMAIL_MESSAGE: (email = '') =>
    `Enter the code in the email we sent to ${email}`,
  VERIFY_EMAIL_TITLE: 'Verify your email address',
};

export const profileDialog = {
  UPDATE_EMAIL_SUCCESS_BUTTON: 'Back to my account',
  UPDATE_EMAIL_SUCCESS_MESSAGE:
    "Done! You've successfully updated your email address.",
  UPDATE_EMAIL_SUCCESS_TITLE: 'Email address updated',
};
