import { useState } from 'react';

import {
  ErrorDialog,
  Layout,
  Panel,
  PanelCard,
  PaymentCardListItem,
  SideSheet,
  SuccessDialog,
  Title,
} from '@/components';
import { ExclamationMarkIcon } from '@/components/icons';
import { useGetBankCards } from '@/features/card-management/services';
import { useHasUserConsentedTerms } from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { usePageTitle } from '@/hooks/use-page-title';
import { sortPaymentCards } from '@/pages/wallet/utils/sort-payment-cards';
import { BaasErrors } from '@/services';

import { AddCard } from './add-card';
import { CardEditor } from './card-editor';
import { SuccessDialogStates } from './enums';
import { LoyaltyCardPanel } from './loyalty-card';
import { Styled } from './styles';
import { getCardSuccessMessage } from './utils/get-card-success-message';

import type { PaymentMethodTypes } from '@/features/card-management';

export const Wallet = () => {
  usePageTitle('Wallet');
  const { track } = useAnalytics();

  const getBankCards = useGetBankCards();
  const consentedTerms = useHasUserConsentedTerms();
  const maxAllowedBankCards =
    getBankCards?.data?.metadata.user_bank_cards_limit.max_allowed || 5;

  const sortedBankCards = sortPaymentCards(getBankCards.data?.cards || []);

  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [is3dsAddCardIframeOpen, setIs3dsAddCardIframeOpen] =
    useState<boolean>(false);
  const [is3dsUpdateCardIframeOpen, setIs3dsUpdateCardIframeOpen] =
    useState<boolean>(false);
  const [isViewCardOpen, setIsViewCardOpen] = useState(false);
  const [isEditingCard, setIsEditingCard] = useState(false);
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [isCardLimitDialogOpen, setIsCardLimitDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] =
    useState<PaymentMethodTypes.TBankCard>();
  const [successDialogState, setSuccessDialogState] =
    useState<SuccessDialogStates | null>(null);
  const [isCardSuccessOpen, setIsCardSuccessOpen] = useState(false);

  const showSuccessDialog = (successDialogState: SuccessDialogStates) => {
    setSuccessDialogState(successDialogState);
    setIsCardSuccessOpen(true);
    getBankCards.refetch();
  };

  const handleAddCardOpen = () => {
    if (!isAddCardOpen) {
      if (sortedBankCards.length >= maxAllowedBankCards) {
        setIsCardLimitDialogOpen(true);
        return;
      }
      track('Add Card Started');
      setIsAddCardOpen(true);
    }
  };

  const handleAddCardClose = () => {
    getBankCards.refetch();
    setIsAddCardOpen(false);
    setIs3dsAddCardIframeOpen(false);
  };

  const handleAddCardSuccess = () => {
    showSuccessDialog(SuccessDialogStates.ADD);
    setIsCardSuccessOpen(true);
  };

  const handleViewCardOpen = () => {
    if (!isViewCardOpen) {
      track('View Card Detail');
      setIsViewCardOpen(true);
    }
  };

  const handleViewCardClose = () => {
    setIs3dsUpdateCardIframeOpen(false);
    setIsViewCardOpen(false);
    setIsEditingNickname(false);
    setIsEditingCard(false);
  };

  const handleSetSelectedCard = (card: PaymentMethodTypes.TBankCard) => () => {
    setSelectedCard(card);
    handleViewCardOpen();
  };

  const viewCardSideSheetTitle = (): string => {
    if (isEditingNickname) {
      return 'Edit nickname';
    }
    if (isEditingCard) {
      return 'Edit payment card';
    }
    return 'Card details';
  };

  const isEditing = isEditingCard || isEditingNickname;

  const is3DSOpen = is3dsAddCardIframeOpen || is3dsUpdateCardIframeOpen;

  const errorCode =
    getBankCards?.error?.details?.error_number?.toString() ??
    getBankCards?.error?.details?.toString();
  const isGetBankCardsError =
    getBankCards.isError && errorCode !== BaasErrors.FORBIDDEN;
  const termsNotAcceptedError =
    errorCode === BaasErrors.TERMS_AND_CONDITIONS_NOT_ACCEPTED;
  const isGetBankCardsLoading =
    !consentedTerms ||
    termsNotAcceptedError ||
    (getBankCards.isLoading && !isGetBankCardsError);

  return (
    <Layout>
      <Title title="Your wallet" />
      <Styled.Container>
        <Styled.Column>
          <PanelCard
            hasEmptyList={!sortedBankCards?.length}
            isError={getBankCards.isError}
            isLoading={isGetBankCardsLoading}
            items={
              <Styled.CardsWrapper>
                {sortedBankCards?.map((card) => (
                  <Styled.PaymentCardItemWrapper key={card.id}>
                    <PaymentCardListItem
                      card={card}
                      onClick={handleSetSelectedCard(card)}
                      selected={isViewCardOpen && card.id === selectedCard?.id}
                    />
                  </Styled.PaymentCardItemWrapper>
                ))}
              </Styled.CardsWrapper>
            }
            onClickAdd={handleAddCardOpen}
          />
        </Styled.Column>
        <Styled.Column>
          <Panel title="Memberships">
            <LoyaltyCardPanel />
          </Panel>
        </Styled.Column>
      </Styled.Container>

      {/* ADD BANK CARD */}
      <SideSheet
        onClose={handleAddCardClose}
        open={isAddCardOpen}
        title="Add a debit or credit card"
      >
        <AddCard
          handleAddCardClose={handleAddCardClose}
          is3dsIframeOpen={is3dsAddCardIframeOpen}
          isOpen={isAddCardOpen}
          onSuccess={handleAddCardSuccess}
          setIs3dsIframeOpen={setIs3dsAddCardIframeOpen}
          setIsCardLimitDialogOpen={setIsCardLimitDialogOpen}
        />
      </SideSheet>

      {/* VIEW BANK CARD */}
      <SideSheet
        onClose={handleViewCardClose}
        open={isViewCardOpen}
        refocusState={is3DSOpen ? 'is3DS' : isEditing ? 'isEditing' : ''}
        title={viewCardSideSheetTitle()}
      >
        {!!selectedCard && (
          <CardEditor
            card={selectedCard}
            cards={sortedBankCards}
            handleViewCardClose={handleViewCardClose}
            is3dsIframeOpen={is3dsUpdateCardIframeOpen}
            isEditing={isEditingCard}
            isEditingNickname={isEditingNickname}
            setIs3dsIframeOpen={setIs3dsUpdateCardIframeOpen}
            setIsEditing={setIsEditingCard}
            setIsEditingNickname={setIsEditingNickname}
            showSuccessDialog={showSuccessDialog}
          />
        )}
      </SideSheet>

      {/* GENERIC SUCCESS DIALOG */}
      <SuccessDialog
        buttonText="Continue"
        description={getCardSuccessMessage(successDialogState).description}
        isOpen={isCardSuccessOpen}
        onOpenChange={() => setIsCardSuccessOpen(false)}
        title={getCardSuccessMessage(successDialogState).title}
      />

      {/* CARD LIMIT REACHED */}
      <ErrorDialog
        buttonText="OK"
        icon={<ExclamationMarkIcon />}
        isOpen={isCardLimitDialogOpen}
        message="You can store a maximum of 5 cards in your wallet. Delete a card first if you want to add a new one."
        onOpenChange={() => setIsCardLimitDialogOpen(false)}
        title="Card limit reached"
        tryAgain={false}
      />
    </Layout>
  );
};
