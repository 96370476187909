import { Buffer } from 'buffer/';

import type { PaymentMethodTypes } from '../types';

export const generateCardCipherText = async ({
  data,
  publicKeyDetails,
}: PaymentMethodTypes.TAddCardRequest): Promise<string> => {
  const cipherData = {
    cvv: data.cvv,
    expiry_month: data.expiry_month,
    expiry_year: data.expiry_year,
    number: data.number,
  };

  const importedKey = await window.crypto.subtle.importKey(
    'spki',
    Buffer.from(publicKeyDetails.public_key, 'base64'),
    {
      hash: 'SHA-256',
      name: 'RSA-OAEP',
    },
    true,
    ['encrypt']
  );

  const cipher = await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    importedKey,
    Buffer.from(JSON.stringify(cipherData), 'utf8')
  );

  const cipherText = Buffer.from(cipher).toString('base64');
  return `${cipherText}.${publicKeyDetails.key_id}`;
};
