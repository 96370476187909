import type { TResponseFilters } from '../types';

export function getOrderSubhead(
  filters: TResponseFilters,
  searchQuery?: string
) {
  const filterText = Object.values(filters).every((filter) => !filter)
    ? 'latest'
    : 'filtered';
  const searchQueryText = searchQuery ? `matching "${searchQuery}"` : '';
  return `Displaying ${filterText} transactions ${searchQueryText}`;
}
