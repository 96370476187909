import { useEffect } from 'react';

import { useA11yAnnouncement, useSetA11yActions } from '@/store';

import { Styled } from './styles';

export const A11yAnnouncement = () => {
  const announcement = useA11yAnnouncement();
  const { clearA11yAnnouncement } = useSetA11yActions();

  useEffect(() => {
    if (!!announcement) {
      setTimeout(() => {
        // Clear the announcement to allow for additional same messages to be read outloud
        clearA11yAnnouncement();
      }, 1000);
    }
  }, [announcement, clearA11yAnnouncement]);

  return (
    <Styled.VisuallyHidden aria-live="polite" role="status">
      {announcement}
    </Styled.VisuallyHidden>
  );
};
