import type { IIcon } from './typings';

export const PaidIcon = ({ color = '#1C533A' }: IIcon) => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M13.1004 5.31617C13.294 5.50983 13.294 5.82377 13.1003 6.01738L7.33245 11.7838C7.1388 11.9774 6.82489 11.9774 6.63128 11.7837L3.89913 9.05161C3.7055 8.85797 3.7055 8.54403 3.89913 8.35039C4.09277 8.15676 4.40671 8.15676 4.60035 8.35039L6.98193 10.732L12.3992 5.31608C12.5928 5.12247 12.9068 5.12251 13.1004 5.31617Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
