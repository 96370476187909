import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const LoyaltyCardItemContainer = styled.div`
  background: var(--colors-surfaceLowest);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  padding: var(--space-xs);

  @media (min-width: ${BreakpointRems.xs}) {
    flex-direction: row;
  }
`;

const LoyaltyCardItemContainerButton = styled.button<{ $selected?: boolean }>`
  align-items: center;
  background: var(--colors-surfaceLowest);
  border: 1px solid
    ${(props) =>
      props.$selected
        ? 'var(--colors-brand1Low)'
        : 'var(--colors-surfaceLowest)'};
  border-radius: var(--radii-lg);
  display: flex;
  gap: var(--space-m);
  padding: var(--space-xs);
  width: 100%;

  &:hover {
    border-color: var(--colors-brand1Low);
  }

  &:focus {
    border-color: var(--colors-brand1Low);
  }
  cursor: pointer;
`;

const LoyaltyCardItemImage = styled.div`
  > div {
    width: 160px;
  }
`;

const LoyaltyCardItemDetails = styled.div`
  align-items: flex-start;
  color: var(--colors-onSurfaceA);
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 2px;
  line-height: 120%;
`;

const LoyaltyCardItemTitle = styled.div`
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-bold);
`;

const LoyaltyCardItemAmount = styled.p`
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  margin: 0;
`;

const LoyaltyCardItemPoints = styled.p`
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-onSurfaceB);
  margin: 0;
`;

const LoyaltyCardPanel = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  list-style: none;
  margin: 0;
  padding: 0;

  [aria-haspopup='dialog'] {
    display: none;
  }
`;

const LoyaltyInfoWrapper = styled.div`
  h3 {
    font-size: var(--fontSizes-16);
    margin: 0 0 var(--space-xs);
  }

  p {
    font-size: var(--fontSizes-14);
    margin: 0 0 var(--space-m);
  }
`;

const LinkButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--colors-onSurfaceA);
  border-radius: var(--radii-md);
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--fontSizes-14);
  gap: var(--space-xs);
  padding: var(--space-s) var(--space-m);
  text-decoration: none;
`;

const SpinnerWrapper = styled.div`
  > div {
    align-items: flex-start;
  }
`;

const LoyaltyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--space-xl);
  width: 100%;
`;

const LoyaltyDetails = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  list-style: none;
  padding: 0;
  width: 100%;
`;

const LoyaltyDetailsRow = styled.li`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 1.25;
`;

const LoyaltyDetailsText = styled.div`
  color: var(--colors-onSurfaceC);
  font-size: var(--fontSizes-14);
`;

const LoyaltyDetailsValue = styled.div`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-16);
`;

const LoyaltyDetailsBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 36px;
  width: 100%;
`;

const LoyaltyDetailsTitle = styled.h3`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-24);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: 1.2;
  margin: 0;
`;

const LoyaltyDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  width: 100%;
`;

const LoyaltyCardWrapper = styled.div`
  align-self: flex-start;
  display: flex;
`;

const LoyaltyDetailsActions = styled.div`
  border-top: 1px solid #807f80;
  padding-top: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

export const Styled = {
  LinkButton,
  LoyaltyCardItemAmount,
  LoyaltyCardItemContainer,
  LoyaltyCardItemContainerButton,
  LoyaltyCardItemDetails,
  LoyaltyCardItemImage,
  LoyaltyCardItemPoints,
  LoyaltyCardItemTitle,
  LoyaltyCardPanel,
  LoyaltyCardWrapper,
  LoyaltyDetails,
  LoyaltyDetailsActions,
  LoyaltyDetailsBody,
  LoyaltyDetailsBodyContainer,
  LoyaltyDetailsRow,
  LoyaltyDetailsText,
  LoyaltyDetailsTitle,
  LoyaltyDetailsValue,
  LoyaltyDetailsWrapper,
  LoyaltyInfoWrapper,
  SpinnerWrapper,
};
