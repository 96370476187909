import { Styled } from './styles';

export const Title = ({
  spacing = true,
  title,
}: {
  spacing?: boolean;
  title: string;
}) => {
  return (
    <Styled.Title $spacing={spacing} tabIndex={-1}>
      {title}
    </Styled.Title>
  );
};
