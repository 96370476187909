import styled, { css } from 'styled-components';

import checkIcon from '@/assets/images/check-white.svg';
import { visuallyHidden } from '@/styles/mixins';

const Checkbox = styled.input`
  ${visuallyHidden}
`;

const InputWrapper = styled.div`
  display: flex;
  gap: var(--space-xs);
  align-items: center;
`;

const Label = styled.label<{
  $disabled?: boolean;
}>`
  color: var(--colors-onSurfaceA);
  line-height: var(--lineHeights-normal);
  padding-left: 1.75rem;
  position: relative;
  user-select: none;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: var(--fontWeights-bold);
  }

  &::before {
    border: 1px solid var(--colors-onSurfaceA);
    border-radius: var(--radii-md);
    content: '';
    cursor: pointer;
    display: inline-block;
    height: 1.25rem;
    position: absolute;
    left: 0;
    top: 0.05rem;
    width: 1.25rem;
  }

  input:focus + &::before {
    outline: 2px solid var(--colors-focusLight);
    outline-offset: 2px;
  }

  input:checked + &::before {
    /* prettier-ignore */
    background: url("${checkIcon}") center center no-repeat;
    background-color: var(--colors-brand1Highest);
    background-size: 0.85rem;
    border-color: var(--colors-brand1Highest);
  }

  ${(props) =>
    props.$disabled &&
    css`
      &::before {
        background-color: var(--colors-surfaceLow);
        border: 1px solid var(--colors-onSurfaceD);
        cursor: not-allowed;
      }
    `}
`;

export const Styled = {
  Checkbox,
  InputWrapper,
  Label,
};
