import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import {
  type PaymentMethodTypes,
  paymentMethodQueryKeys,
} from '@/features/card-management';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';

import type { UseMutationOptions } from '@tanstack/react-query';

interface IUseUpdateCardRequestPayload
  extends Pick<PaymentMethodTypes.PaymentMethod, 'alias'> {}

export function useUpdateNickname(
  cardId: string,
  options?: UseMutationOptions<
    PaymentMethodTypes.PaymentMethod,
    unknown,
    IUseUpdateCardRequestPayload,
    unknown
  >
) {
  const auth = useAuth();

  const updateRequest = async (payload: IUseUpdateCardRequestPayload) =>
    await request<PaymentMethodTypes.PaymentMethod>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: payload,
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.CARD_UPDATE_NICKNAME,
        transaction: paymentMethodTransactionKeys.cardUpdateNickname,
      }
    );

  return useMutation<
    PaymentMethodTypes.PaymentMethod,
    unknown,
    IUseUpdateCardRequestPayload,
    unknown
  >({
    mutationFn: updateRequest,
    mutationKey: paymentMethodQueryKeys.updateCardNickname,
    ...options,
  });
}
