const formatter = new Intl.NumberFormat('en-AU', {
  currency: 'AUD',
  style: 'currency',
});

export const formatCurrency = (value?: number) => {
  if (!value && value !== 0) {
    return;
  }
  return formatter.format(value);
};
