export enum AddressInputFieldNames {
  ADDRESS_CITY = 'addressCity',
  ADDRESS_IS_DEFAULT = 'addressIsDefault',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  ADDRESS_NAME = 'addressName',
  ADDRESS_NICKNAME = 'addressNickname',
  ADDRESS_POSTCODE = 'addressPostcode',
  ADDRESS_STATE = 'addressState',
}

export enum AddressInputLabels {
  ADDRESS_CITY = 'City/Suburb*',
  ADDRESS_LINE_1 = 'Address Line 1*',
  ADDRESS_LINE_2 = 'Address Line 2',
  ADDRESS_NAME = 'Name*',
  ADDRESS_NICKNAME = 'Address nickname',
  ADDRESS_POSTCODE = 'Postcode*',
  ADDRESS_STATE = 'State*',
}

export enum AddressInputPlaceholders {
  ADDRESS_LINE_2 = 'Apt / Building / Floor etc. (optional)',
  ADDRESS_NICKNAME = 'Give this address a nickname (optional)',
}
