import styled from 'styled-components';

const EmptyOrdersList = styled.div`
  align-items: center;
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  height: 500px;
  justify-content: center;
`;

const EmptyOrdersListDetails = styled.div`
  color: var(--colors-onSurfaceA);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin: 0;
  text-align: center;

  h1 {
    color: var(--colors-brand1Highest);
    font-size: var(--fontSizes-20);
    font-weight: (--fontWeights-bold);
    line-height: var(--lineHeights-half);
    margin: 0;
  }

  p {
    color: var(--colors-onSurfaceA);
    font-size: var(--fontSizes-16);
    font-weight: var(--fontWeights-normal);
  }
`;

const NoHistory = styled.div`
  align-items: center;
  background: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  color: var(--colors-onSurfaceB);
  display: flex;
  flex-direction: column;
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  gap: var(--space-l);
  height: 500px;
  justify-content: center;
  line-height: var(--lineHeights-half);
  margin: 0;
  text-align: center;
  padding: 0 var(--space-2xl);
`;

export const Styled = {
  EmptyOrdersList,
  EmptyOrdersListDetails,
  NoHistory,
};
