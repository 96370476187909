export enum SettingsStates {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum SuccessDialogStates {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  SET_DEFAULT = 'set default',
}
