import type { ProfileTypes } from '@/features/profile';

export const getUserFullName = (
  user: ProfileTypes.TUser | undefined,
  fallback: string = ''
) => {
  const firstName = user?.first_name;
  const lastName = user?.last_name;

  return !!firstName && !!lastName
    ? `${firstName?.trim()} ${lastName?.trim()}`.trim()
    : fallback;
};
