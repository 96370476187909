import styled from 'styled-components';

import { Button, ButtonSpinner } from '@/components';

const Actions = styled.div`
  display: flex;
  gap: var(--space-m);
  padding-top: var(--space-l);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);
`;

const ButtonCancel = styled(Button)`
  background-color: var(--colors-surfaceHighest);
  border-radius: var(--radii-sm);
  color: var(--colors-onSurfaceA);
`;

const ButtonConfirm = styled(ButtonSpinner)`
  border-radius: var(--radii-sm);
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const FormHint = styled.div`
  p {
    margin: 0;
    text-align: center;
  }
`;

const Icon = styled.div`
  text-align: center;
`;

const Message = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  font-size: var(--fontSizes-24);
  margin: 0 0 var(--space-s);
  text-align: center;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

export const Styled = {
  Actions,
  Body,
  ButtonCancel,
  ButtonConfirm,
  FormFields,
  FormHint,
  Icon,
  Message,
  Title,
  TitleContainer,
};
