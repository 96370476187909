import { AlertIcon, CloseFilledIcon, SearchIcon } from '@/components/icons';
import { useTheme } from '@/contexts/theme';

import { Styled } from './styles';

import type { TSearchInputProps } from './typings';

export const SearchInput = ({
  $size,
  disabled = false,
  error,
  id,
  name,
  onClickClear,
  showClear = false,
  ...props
}: TSearchInputProps) => {
  const { theme } = useTheme();
  return (
    <>
      <Styled.SearchInputContainer
        $disabled={disabled}
        $error={!!error}
        $size={$size}
      >
        <SearchIcon />
        <Styled.SearchInput
          id={id || name}
          {...props}
          $size={$size}
          disabled={disabled}
        />
        {showClear && (
          <Styled.ClearButton
            aria-label="Clear search"
            disabled={disabled}
            onClick={onClickClear}
            type="reset"
          >
            <CloseFilledIcon
              color={
                disabled
                  ? 'var(--colors-onSurfaceD)'
                  : 'var(--colors-onSurfaceA)'
              }
              height={$size === 'small' ? 16 : undefined}
              width={$size === 'small' ? 16 : undefined}
            />
          </Styled.ClearButton>
        )}
      </Styled.SearchInputContainer>
      {!!error && (
        <Styled.SearchError $size={$size}>
          <AlertIcon
            color={theme.styling.colors.onErrorLow.value}
            height={$size === 'small' ? 14 : 20}
            width={$size === 'small' ? 14 : 20}
          />
          {error}
        </Styled.SearchError>
      )}
    </>
  );
};
