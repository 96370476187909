import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Layout, LoadingScreen, Title } from '@/components';
import { usePageTitle } from '@/hooks/use-page-title';

import { AccountSettings } from './account-settings';
import { ProfileSettings } from './profile-settings';
import { Styled } from './styles';

export const Account = () => {
  usePageTitle('Account');
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth.isLoading) {
      setLoading(false);
    }
  }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading]);

  return (
    <Layout>
      <Styled.Container>
        <Styled.TitleWrapper>
          <Title title="Account" />
        </Styled.TitleWrapper>
        <Styled.ContentWrapper>
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              <ProfileSettings />
              <AccountSettings />
            </>
          )}
        </Styled.ContentWrapper>
      </Styled.Container>
    </Layout>
  );
};
