import { joiResolver } from '@hookform/resolvers/joi';
import { useQueryClient } from '@tanstack/react-query';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ButtonSpinner, Dialog, ErrorDialog } from '@/components';
import { Checkbox } from '@/components/checkbox-no-rhf';
import { ExclamationMarkIcon } from '@/components/icons';
import { genericContent } from '@/config/language';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_UPDATE_PROFILE_GENERIC,
} from '@/config/language/errors';
import { ThemeWrapper, useTheme } from '@/contexts/theme';
import {
  getTermsConsent,
  useGetProfile,
  useUpdateProfile,
} from '@/features/profile';
import { getDefaultTheme } from '@/theme';
import { validate } from '@/utils';

import { DetailsFormFieldNames } from '../confirm-details/details-form/copy';

import { Styled } from './styles';

import type { FieldValues, SubmitHandler } from 'react-hook-form';

type TTermsForm = {
  [DetailsFormFieldNames.TERMS_AND_CONDITIONS]: boolean;
};

const DEFAULT_FORM_VALUES = {
  [DetailsFormFieldNames.TERMS_AND_CONDITIONS]: false,
} as FieldValues;

export const AcceptTerms = () => {
  const queryClient = useQueryClient();
  const initialTheme = getDefaultTheme();

  const { theme } = useTheme();
  const [isError, setIsError] = useState<boolean>(false);
  const validationSchema = Joi.object({
    [DetailsFormFieldNames.TERMS_AND_CONDITIONS]: validate.terms(),
  }).unknown(true);

  const getProfile = useGetProfile();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TTermsForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const updateProfile = useUpdateProfile({
    onError: () => {
      setIsError(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const onSubmit: SubmitHandler<TTermsForm> = (data) => {
    const termsAndConditions = data[DetailsFormFieldNames.TERMS_AND_CONDITIONS];
    const terms = getTermsConsent(getProfile.data, !!termsAndConditions);

    if (!!terms && !!termsAndConditions) {
      updateProfile.mutate({
        consents: [terms],
      });
    } else {
      setIsError(true);
    }
  };

  const isSubmitting = updateProfile.isPending;

  return (
    <>
      <Dialog
        ariaLabel="Updated Terms & Conditions and Privacy Policy"
        body={
          <ThemeWrapper initialTheme={initialTheme}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Styled.DialogWrapper>
                <ExclamationMarkIcon />
                <Styled.DialogHeader>
                  <Styled.DialogTitle>
                    Updated Terms & Conditions and Privacy Policy
                  </Styled.DialogTitle>
                  <Styled.DialogDescription>
                    We recently updated our{' '}
                    <Styled.DialogLink
                      href={theme.urls?.terms}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms & Conditions
                    </Styled.DialogLink>{' '}
                    and{' '}
                    <Styled.DialogLink
                      href={theme.urls?.privacy}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </Styled.DialogLink>
                    . Please accept them to continue using flypay.
                  </Styled.DialogDescription>
                </Styled.DialogHeader>

                <Styled.DialogForm>
                  <Checkbox
                    {...register('termsAndConditions', {
                      disabled: isSubmitting,
                      required: true,
                    })}
                    error={
                      errors[DetailsFormFieldNames.TERMS_AND_CONDITIONS]
                        ?.message
                    }
                  >
                    I have read and agreed to the updated flypay Terms &
                    Conditions and Privacy Policy.
                  </Checkbox>
                  <ButtonSpinner
                    data-testid="update-terms-dialog-continue-button"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    size="large"
                    spinnerColor={theme.styling.colors.onSurfaceInverse.value}
                    spinnerSize={16}
                    type="submit"
                  >
                    Continue
                  </ButtonSpinner>
                </Styled.DialogForm>
              </Styled.DialogWrapper>
            </form>
          </ThemeWrapper>
        }
        isOpen
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isError}
        message={ERROR_UPDATE_PROFILE_GENERIC}
        onOpenChange={() => setIsError(false)}
        title={genericContent.ERROR_GENERIC_TITLE}
      />
    </>
  );
};
