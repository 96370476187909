import type { IIcon } from './typings';

export const SearchIcon = ({
  color = 'currentColor',
  height = 24,
  width = 24,
}: IIcon) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 25"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.24163 5.97381C6.67552 4.53992 8.62029 3.73438 10.6481 3.73438C12.6759 3.73438 14.6207 4.53992 16.0546 5.97381C17.4885 7.4077 18.294 9.35247 18.294 11.3803C18.294 13.4081 17.4885 15.3529 16.0546 16.7868C14.6207 18.2207 12.6759 19.0262 10.6481 19.0262C8.62029 19.0262 6.67552 18.2207 5.24163 16.7868C3.80775 15.3529 3.0022 13.4081 3.0022 11.3803C3.0022 9.35247 3.80775 7.4077 5.24163 5.97381ZM10.6481 5.5088C9.09089 5.5088 7.59746 6.1274 6.49634 7.22852C5.39522 8.32964 4.77662 9.82307 4.77662 11.3803C4.77662 12.9375 5.39522 14.4309 6.49634 15.5321C7.59746 16.6332 9.09089 17.2518 10.6481 17.2518C12.2053 17.2518 13.6988 16.6332 14.7999 15.5321C15.901 14.4309 16.5196 12.9375 16.5196 11.3803C16.5196 9.82307 15.901 8.32964 14.7999 7.22852C13.6988 6.1274 12.2053 5.5088 10.6481 5.5088Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.8009 15.5374C15.1473 15.1909 15.7091 15.1909 16.0556 15.5374L20.738 20.2198C21.0845 20.5663 21.0845 21.128 20.738 21.4745C20.3915 21.821 19.8298 21.821 19.4833 21.4745L14.8009 16.7921C14.4544 16.4456 14.4544 15.8839 14.8009 15.5374Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
