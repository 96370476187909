import { format } from 'date-fns';

import { ChevronRightIcon } from '@/components/icons';
import { DateFormats } from '@/config/language';
import { MerchantLogo } from '@/features/activity';
import { formatCurrency } from '@/utils/format/currency';

import { getOrderInfo } from '../../utils';

import { Styled } from './styles';

import type { OrderServiceTypes } from '@/features/activity/services';

type TWidescreenOrder = {
  onClick?: (id: string) => void;
  order: OrderServiceTypes.TOrder;
};

const formatDate = (date: Date) => format(date, DateFormats.DAY_MONTH_YEAR);

export const WidescreenOrder = ({ onClick, order }: TWidescreenOrder) => {
  const onClickOrder = (id: string) => () => {
    onClick?.(id);
  };

  const {
    amount,
    created_at,
    hasRefund,
    merchantId,
    merchantName,
    orderId,
    orderReference,
    payments,
  } = getOrderInfo(order);

  const createdAtDate = !!created_at?.dayMonthYear
    ? created_at.dayMonthYear
    : '-';

  return (
    <>
      <Styled.OrderTableRow $hasRefund={hasRefund} key={orderId}>
        <Styled.OrderTableCell>{createdAtDate}</Styled.OrderTableCell>
        <Styled.OrderTableCell>
          <Styled.MerchantDetails>
            <MerchantLogo merchantId={merchantId} merchantName={merchantName} />
            {merchantName}
          </Styled.MerchantDetails>
        </Styled.OrderTableCell>
        <Styled.OrderTableCell $color="onSurfaceB" $fontSize="14">
          {orderReference ?? orderId}
        </Styled.OrderTableCell>
        <Styled.OrderTableCell>{formatCurrency(amount)}</Styled.OrderTableCell>
        <Styled.OrderTableCell>
          <Styled.GhostButton onClick={onClickOrder(orderId)}>
            View detail <ChevronRightIcon />
          </Styled.GhostButton>
        </Styled.OrderTableCell>
      </Styled.OrderTableRow>
      {hasRefund &&
        payments.map((payment, i) => {
          const isLast = i === payments.length - 1;
          return (
            <Styled.OrderTableSubRow
              $isLast={isLast}
              key={`order-table-item-payment-${payment.id}`}
            >
              <Styled.OrderTableSubCell />
              <Styled.OrderTableSubCell>
                {formatDate(payment.date)}
              </Styled.OrderTableSubCell>
              <Styled.OrderTableSubCell>
                {payment.hasRefund ? 'Refunded' : 'Paid'}
              </Styled.OrderTableSubCell>
              <Styled.OrderTableSubCell>
                {formatCurrency(payment.amount)}
              </Styled.OrderTableSubCell>
            </Styled.OrderTableSubRow>
          );
        })}
    </>
  );
};
