import { Divider } from '@b707/ponyta';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Checkbox,
  ErrorDialog,
  Form,
  FormHint,
  SecurityAssurance,
  TextField,
} from '@/components';
import {
  CardInput,
  CardInputFieldNames,
  CardInputLabels,
} from '@/components/card-input';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_SAVE_CARD_GENERIC,
  ERROR_TITLE_SAVE_CARD,
} from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  PaymentMethodEnums,
  ThreeDsIframe,
  endpoints,
  formatDate,
  use3dsCardListener,
  useAddCard3dsAuthorize,
  useAddCard3dsFinalize,
  useGetPublicKey,
} from '@/features/card-management';
import { sentry } from '@/features/monitoring';
import { MAX_CHARS_CARD_NICKNAME } from '@/features/profile';
import { BaasErrors } from '@/services';
import { validate } from '@/utils';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management/';
import type { TBaasAddPaymentMethodErrors } from '@/features/card-management/types';
import type { TBaasErrorResponse } from '@/services';
import type { FieldValues } from 'react-hook-form';

interface IProps {
  handleAddCardClose: () => void;
  is3dsIframeOpen: boolean;
  isOpen: boolean;
  onSuccess: () => void;
  setIs3dsIframeOpen: (isOpen: boolean) => void;
  setIsCardLimitDialogOpen: (isOpen: boolean) => void;
}

const DEFAULT_FORM_VALUES = {
  [CardInputFieldNames.CARD_ALIAS]: '',
  [CardInputFieldNames.CARD_NUMBER]: '',
  [CardInputFieldNames.CARD_EXPIRY_DATE]: '',
  [CardInputFieldNames.CARD_SECURITY_CODE]: '',
  [CardInputFieldNames.CARD_IS_DEFAULT]: false,
} as FieldValues;

const validationSchema = Joi.object({
  [CardInputFieldNames.CARD_ALIAS]: validate.cardAlias(),
  [CardInputFieldNames.CARD_NUMBER]: validate.cardNumber(),
  [CardInputFieldNames.CARD_EXPIRY_DATE]: validate.cardExpiryDate(),
  [CardInputFieldNames.CARD_SECURITY_CODE]: validate.cardSecurityCode(),
  [CardInputFieldNames.CARD_IS_DEFAULT]: validate.cardIsDefault(),
});

export const AddCard = ({
  handleAddCardClose,
  is3dsIframeOpen,
  isOpen,
  onSuccess,
  setIs3dsIframeOpen,
  setIsCardLimitDialogOpen,
}: IProps) => {
  const { theme } = useTheme();
  const { message, setMessage } = use3dsCardListener();

  const [isError, setIsError] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState(false);
  const [threeDSecureId, setThreeDSecureId] = useState('');
  const [finalizePayload, setFinalizePayload] =
    useState<PaymentMethodTypes.TAddCard3dsFinalizeRequest>();

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const addCardError = (
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) => {
    if (
      error?.details?.error_number?.toString() === BaasErrors.MAX_CARDS_LIMIT
    ) {
      setIsCardLimitDialogOpen(true);
    } else {
      setIsError(true);
    }
  };

  const addCard = useAddCard3dsAuthorize({
    onError: addCardError,
    onSuccess: (data) => {
      setThreeDSecureId(data?.three_d_secure?.id);
      setFinalizePayload({
        authorization_id: data?.three_d_secure?.id,
        card_id: data?.card_id,
        isDefault,
      });
      setIs3dsIframeOpen(true);
    },
  });

  const getPublicKey = useGetPublicKey({ enabled: isOpen });

  const onSubmitForm = async ({
    cardAlias,
    cardExpiryDate,
    cardIsDefault,
    cardNumber,
    cardSecurityCode,
  }: FieldValues) => {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    setIsDefault(cardIsDefault);

    const publicKeyDetails = getPublicKey.data;

    if (!publicKeyDetails?.key_id || !publicKeyDetails?.public_key) {
      setIsError(true);
      return;
    }

    addCard.mutate({
      data: {
        alias: cardAlias,
        cvv: cardSecurityCode,
        expiry_month: expiryMonth,
        expiry_year: expiryYear,
        number: cardNumber.replaceAll(' ', ''),
      },
      publicKeyDetails,
    });
  };

  useEffect(() => {
    formMethods.reset(DEFAULT_FORM_VALUES);
    if (!isOpen) {
      setFinalizePayload(undefined);
    }
  }, [formMethods, isOpen]);

  const { isPending: isFinalizeLoading, mutate: finalize } =
    useAddCard3dsFinalize({
      onError: addCardError,
      onSuccess: () => {
        onSuccess();
        handleAddCardClose();
        formMethods.reset();
      },
    });

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS) {
        if (finalizePayload) {
          finalize(finalizePayload);
        } else {
          sentry.captureLog('Card Add Failed 3DS Authorize', message);
          setIsError(true);
        }
        setMessage(null);
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        setMessage(null);
        setIsError(true);
      }
    }
  }, [
    finalize,
    finalizePayload,
    is3dsIframeOpen,
    message,
    setIs3dsIframeOpen,
    setMessage,
  ]);

  const isProcessing =
    addCard.isPending || isFinalizeLoading || is3dsIframeOpen;

  const display3ds = is3dsIframeOpen && threeDSecureId;

  return (
    <Styled.Container>
      {is3dsIframeOpen && threeDSecureId ? (
        <ThreeDsIframe
          src={`${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${endpoints.BANK_CARDS_3DS_AUTHENTICATE_INIT(threeDSecureId)}`}
        />
      ) : (
        <Form config={formMethods} onSubmitForm={onSubmitForm}>
          <Styled.FormContainer $isHidden={!!display3ds}>
            <FormHint />
            <Styled.Nickname>
              <TextField
                disabled={isProcessing}
                helpText="Give this card a nickname (optional)"
                label={CardInputLabels.CARD_ALIAS}
                maxLength={MAX_CHARS_CARD_NICKNAME}
                name={CardInputFieldNames.CARD_ALIAS}
              />
            </Styled.Nickname>
            <CardInput isAddCardOpen={isOpen} isSubmitting={isProcessing} />
            <Checkbox
              disabled={isProcessing}
              id="cardIsDefault"
              label="Set as default payment card"
              {...formMethods.register(CardInputFieldNames.CARD_IS_DEFAULT)}
            />
          </Styled.FormContainer>
          <Styled.Footer>
            {!is3dsIframeOpen && <SecurityAssurance />}
            <Divider />
            <ButtonSpinner
              disabled={isProcessing}
              level="primary"
              loading={isProcessing}
              size="medium"
              spinnerColor={theme.styling.colors.onSurfaceC.value}
              variant="branded"
            >
              Save
            </ButtonSpinner>
          </Styled.Footer>
        </Form>
      )}
      {isError && (
        <ErrorDialog
          buttonText={BUTTON_TEXT_TRY_AGAIN}
          isOpen={isError}
          message={ERROR_SAVE_CARD_GENERIC}
          onOpenChange={() => setIsError(false)}
          title={ERROR_TITLE_SAVE_CARD}
        />
      )}
    </Styled.Container>
  );
};
