import { Divider } from '@b707/ponyta';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ButtonSpinner, ErrorDialog, Form, TextField } from '@/components';
import { CardInputFieldNames, CardInputLabels } from '@/components/card-input';
import { genericContent } from '@/config/language';
import { ERROR_UPDATE_CARD_NICKNAME } from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import { useUpdateNickname } from '@/features/card-management/services';
import { MAX_CHARS_CARD_NICKNAME, MIN_CHARS_DEFAULT } from '@/features/profile';
import { validate } from '@/utils';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';
import type { FieldValues } from 'react-hook-form';

interface INicknameEditor {
  card: PaymentMethodTypes.TBankCard;
  onSaveSuccess: () => void;
}
const validationSchema = Joi.object({
  [CardInputFieldNames.CARD_ALIAS]: validate.cardAlias(),
});

export const NicknameEditor = ({ card, onSaveSuccess }: INicknameEditor) => {
  const { theme } = useTheme();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const DEFAULT_FORM_VALUES = {
    [CardInputFieldNames.CARD_ALIAS]: card.alias,
  } as FieldValues;

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const updateNickname = useUpdateNickname(card.id, {
    onError: () => {
      setIsErrorDialogOpen(true);
    },
    onSuccess: () => {
      onSaveSuccess();
    },
  });

  const onSubmitForm = async ({ cardAlias }: FieldValues) => {
    const alias = cardAlias === '' ? null : cardAlias;
    updateNickname.mutate({
      alias,
    });
  };

  const onCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  return (
    <div>
      <Form config={formMethods} onSubmitForm={onSubmitForm}>
        <div>
          <TextField
            helpText={`Nickname must be between ${MIN_CHARS_DEFAULT} and ${MAX_CHARS_CARD_NICKNAME} characters, or leave blank to remove.`}
            label={CardInputLabels.CARD_ALIAS}
            maxLength={MAX_CHARS_CARD_NICKNAME}
            name={CardInputFieldNames.CARD_ALIAS}
          />
        </div>
        <Styled.Footer>
          <Divider />
          <ButtonSpinner
            disabled={updateNickname.isPending}
            level="primary"
            loading={updateNickname.isPending}
            size="medium"
            spinnerColor={theme.styling.colors.onSurfaceC.value}
            variant="branded"
          >
            Save
          </ButtonSpinner>
        </Styled.Footer>
      </Form>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        message={ERROR_UPDATE_CARD_NICKNAME}
        onOpenChange={onCloseErrorDialog}
        title={genericContent.ERROR_GENERIC_TITLE}
        tryWhat=""
      />
    </div>
  );
};
