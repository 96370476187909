import { createRef, useContext, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { Keys } from '@/utils';

import { ComboBoxContext } from './combobox';
import { Styled } from './styles';

import type { IComboBoxOption, TComboBoxContext } from './typings';

export const ComboBoxOptions = () => {
  const {
    cursorPosition,
    isOpen,
    name,
    onClickOption,
    options,
    selectOptions,
    selectedOption,
    setInputValue,
    setIsOpen,
    setSelectedOption,
  } = useContext(ComboBoxContext) as TComboBoxContext;

  const prevCursorPosition = usePrevious(cursorPosition);
  const optionRefs = useRef(options.map(() => createRef<HTMLLIElement>()));

  const onClickSelectOption = (currentOption: IComboBoxOption) => () => {
    onClickOption?.({
      fieldName: name,
      label: currentOption.label,
      value: currentOption.value,
    });

    setSelectedOption(currentOption);
    setInputValue(currentOption.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const option = optionRefs.current[cursorPosition]?.current;
    const optionParent = option?.parentNode;

    if (cursorPosition !== prevCursorPosition && !!option && !!optionParent) {
      (optionParent as HTMLElement)?.scrollBy({
        behavior: 'smooth',
        top:
          cursorPosition > (prevCursorPosition as number)
            ? option?.clientHeight
            : -option?.clientHeight,
      });
    }
  }, [cursorPosition, prevCursorPosition]);

  const onBlurListItem = (i: number) => () => {
    if (i === selectOptions.length - 1) {
      setIsOpen(false);
    }
  };

  const onKeyDownListItem =
    (currentOption: IComboBoxOption) => (e: React.KeyboardEvent) => {
      if (e.shiftKey && e.key === Keys.TAB) {
        setSelectedOption(currentOption);
        setInputValue(currentOption.value);
      }

      if (e.key === Keys.ENTER && isOpen) {
        setSelectedOption(currentOption);
        setInputValue(currentOption.value);
        setIsOpen(false);
      }
    };

  const onKeyUpListItem =
    (currentOption: IComboBoxOption) => (e: React.KeyboardEvent) => {
      if (e.key === Keys.TAB && isOpen) {
        setSelectedOption(currentOption);
        setInputValue(currentOption.value);
      }

      if (e.key === Keys.ENTER && isOpen) {
        setSelectedOption(currentOption);
        setInputValue(currentOption.value);
        setIsOpen(false);
      }

      if (e.key === Keys.ESCAPE && isOpen) {
        setIsOpen(false);
      }
    };

  if (!isOpen) {
    return null;
  }

  return (
    <Styled.AutoCompleteOptionsList
      $isOpen={isOpen}
      aria-label="autocomplete-options"
      id="autocomplete-options"
      role="listbox"
    >
      {selectOptions.map((option, i) => {
        const listItemRef = optionRefs?.current[i];
        const id = `autocomplete-option-${option.label}-${i}`;
        const selectedOptionValue = selectedOption?.value.toLowerCase().trim();
        const optionValue = option.value.trim();
        const currentValue = option.value.toLowerCase().trim();
        const isSelected =
          selectedOptionValue === currentValue ? 'true' : 'false';

        return (
          <Styled.AutoCompleteOptionsListItem
            $isFocused={cursorPosition === i}
            aria-selected={isSelected}
            id={id}
            key={id}
            onBlur={onBlurListItem(i)}
            onClick={onClickSelectOption(option)}
            onKeyDown={onKeyDownListItem(option)}
            onKeyUp={onKeyUpListItem(option)}
            ref={listItemRef}
            role="option"
          >
            {optionValue}
          </Styled.AutoCompleteOptionsListItem>
        );
      })}
    </Styled.AutoCompleteOptionsList>
  );
};

ComboBoxOptions.displayName = 'ComboBoxOptions';
