import { LogoCard } from '@b707/ponyta';

import { useBreakpoints } from '@/hooks';

import { Styled } from '../../styles';

import type { LegacyRef } from 'react';
interface IMycolesProps {
  buttonRef: LegacyRef<HTMLButtonElement>;
  handleOpenViewCard: () => void;
}

export const MycolesLinked = ({
  buttonRef,
  handleOpenViewCard,
}: IMycolesProps) => {
  const breakpoints = useBreakpoints();
  return (
    <Styled.LoyaltyCardItemContainerButton
      onClick={handleOpenViewCard}
      ref={buttonRef}
      tabIndex={0}
    >
      <LogoCard size={breakpoints.xs ? 'md' : 'sm'} type="mycoles" />
      <Styled.LoyaltyCardItemDetails>
        <Styled.LoyaltyCardItemTitle>
          mycoles discount card
        </Styled.LoyaltyCardItemTitle>
        Membership
      </Styled.LoyaltyCardItemDetails>
    </Styled.LoyaltyCardItemContainerButton>
  );
};
