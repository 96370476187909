import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Subheader = styled.p`
  font-size: var(--fontSizes-16);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
  color: var(--colors-onSurfaceB);
  margin: 0 0 var(--space-xl) 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--space-xl);
  gap: var(--space-l);
`;

export const Styled = {
  Container,
  Footer,
  Subheader,
};
