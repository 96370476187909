import styled from 'styled-components';

const LayoutWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-surfaceLow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90dvh;
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
  width: 100%;
`;

const ErrorContainer = styled.div`
  justify-self: center;
  max-width: 460px;
  text-align: center;
`;

const ErrorMessageTitle = styled.h1`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-20);
  font-weight: var(--fontWeights-bold);
  margin: var(--space-m) 0;
`;

const ErrorMessage = styled.p`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-14);
  line-height: var(--lineHeights-half);
  margin: 0;

  a {
    color: inherit;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;

  a,
  button {
    background-color: var(--colors-brand1Highest);
    border: 0;
    border-radius: var(--radii-md);
    color: var(--colors-onBrand1High);
    display: inline-block;
    font-size: var(--fontSizes-14);
    padding: var(--space-s);
    text-decoration: none;
  }
`;

export const Styled = {
  ButtonContainer,
  ErrorContainer,
  ErrorMessage,
  ErrorMessageTitle,
  LayoutWrapper,
};
