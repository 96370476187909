import styled from 'styled-components';

const EditButton = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: var(--fontSizes-14);
  font-weight: var(--fontWeights-bold);
  text-decoration: underline;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
`;

const Hint = styled.p`
  color: var(--colors-onSurfaceB);
  margin: 0 0 var(--space-xl);
`;

export const Styled = {
  EditButton,
  Footer,
  Hint,
};
