import * as RadixSlider from '@radix-ui/react-slider';
import styled from 'styled-components';

const SliderRoot = styled(RadixSlider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 1.125rem;

  &[data-orientation='vertical'] {
    flex-direction: column;
    height: 100%;
    width: 1.125rem;
  }
`;

const SliderTrack = styled(RadixSlider.Track)`
  background-color: var(--colors-surfaceHighest);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;

  &[data-orientation='horizontal'] {
    height: 0.375rem;
  }

  &[data-orientation='vertical'] {
    width: 0.375rem;
  }
`;

const SliderRange = styled(RadixSlider.Range)`
  position: absolute;
  background-color: var(--colors-brand1Highest);
  border-radius: 9999px;

  &[data-orientation='horizontal'] {
    height: 100%;
  }

  &[data-orientation='vertical'] {
    width: 100%;
  }
`;

const SliderThumb = styled(RadixSlider.Thumb)`
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--colors-surfaceHigh);
  border-radius: 50px;
  border: 2px solid var(--colors-onSurfaceD);
`;

export const Styled = {
  SliderRange,
  SliderRoot,
  SliderThumb,
  SliderTrack,
};
